import {
	Id,
	PagedMultiSelectField,
	PagedMultiSelectFieldProps,
	PagedSingleSelectField,
	PagedSingleSelectFieldProps,
} from "@dgs/core";
import React, { FC } from "react";
import { EmployeeOptionDto } from "~root/api_gen";
import { employeeKeys } from "~shared/api/employee/employeeKeys";
import { webserviceApi } from "~shared/api/webserviceApi";

const fetcher = (idsToRemove: Id[]) => (search: string, page: number) =>
	webserviceApi.listEmployeeOptions(search, page).then((x) => ({
		...x.data,
		data: x.data.data.filter((x) => !idsToRemove.includes(x.id)),
	}));

interface Props {
	name: string;
	label: string;
	required?: boolean;
	idsToRemove?: Id[];
	onChange?: PagedSingleSelectFieldProps<EmployeeOptionDto>["onChange"];
}

export const EmployeeSingleSelectField: FC<Props> = (props) => {
	return (
		<PagedSingleSelectField
			{...props}
			fetcher={fetcher(props.idsToRemove ?? [])}
			queryKey={[...employeeKeys.lists(), ...(props.idsToRemove ?? [])]}
			getLabel={(x) => `${x.firstName} ${x.lastName}`}
		/>
	);
};

type MultiSelectProps = Omit<Props, "onChange"> & {
	onChange?: PagedMultiSelectFieldProps<EmployeeOptionDto>["onChange"];
};
export const EmployeeMultiSelectField: FC<MultiSelectProps> = (props) => {
	return (
		<PagedMultiSelectField
			{...props}
			fetcher={fetcher([])}
			queryKey={[...employeeKeys.lists()]}
			getLabel={(x) => `${x.firstName} ${x.lastName}`}
		/>
	);
};
