import { CrudDrawer, EntityDrawerProps, TextField } from "@dgs/core";
import React, { FC, useCallback } from "react";
import { useTranslation } from "react-i18next";
import { UserShowDto } from "~root/api_gen";
import { RoleMultiSelect } from "./role_multi_select";

export type IUserFormState = Omit<UserShowDto, "id" | "password">;

export const UserDrawer: FC<EntityDrawerProps<IUserFormState>> = (props) => {
	const { t } = useTranslation();

	const validate = useCallback(
		(values: IUserFormState) => {
			const errors: Partial<IUserFormState> = {};

			if (!values.firstName) {
				errors.firstName = t("Field is required");
			}
			if (!values.lastName) {
				errors.lastName = t("Field is required");
			}
			if (!values.email) {
				errors.email = t("Field is required");
			}

			return errors;
		},
		[t],
	);

	return (
		<CrudDrawer
			{...props}
			validate={validate}
			body={
				<>
					<TextField label={t("First name")} name="firstName" />
					<TextField label={t("Last name")} name="lastName" />
					<TextField label={t("Email")} name="email" />
					<TextField label={t("Company")} name="company" />
					<TextField label={t("Position")} name="position" />
					<RoleMultiSelect label={t("Roles")} name="roles" />
				</>
			}
		/>
	);
};
