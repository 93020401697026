import { CrudDrawer, EntityDrawerProps, TextField } from "@dgs/core";
import React, { FC, useCallback } from "react";
import { useTranslation } from "react-i18next";
import { ActivityShowDto } from "~root/api_gen";

export interface IActivityFormState {
	name: ActivityShowDto["name"];
	description?: ActivityShowDto["description"];
}

export const ActivityDrawer: FC<EntityDrawerProps<IActivityFormState>> = (props) => {
	const { t } = useTranslation();

	const validate = useCallback(
		(values: IActivityFormState) => {
			const errors: { [K in keyof IActivityFormState]?: any } = {};

			if (!values.name) {
				errors.name = t("Field is required");
			}
			return errors;
		},
		[t],
	);

	return (
		<CrudDrawer
			{...props}
			validate={validate}
			body={
				<>
					<TextField name="name" label={t("Name")} required />
					<TextField name="description" label={t("Description")} />
				</>
			}
		/>
	);
};
