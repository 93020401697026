import { useQueryClient } from "@tanstack/react-query";

type TernaryGeneric<Left, Right, T = undefined> = T extends undefined ? Left : Right;
type InvalidateFn = (isApiOk: boolean) => Promise<boolean>;
type InvalidateFnWithId<T> = (isApiOk: boolean, id: T) => Promise<boolean>;
type OptionalReturnFn<T = undefined> = TernaryGeneric<InvalidateFn, InvalidateFnWithId<T>, T>;

export const useInvalidation = <T = undefined,>(
	input: TernaryGeneric<string[][], (arg: T) => string[][], T>,
): OptionalReturnFn<T> => {
	const queryClient = useQueryClient();

	return (async (isApiOk: boolean, t: T) => {
		if (isApiOk) {
			const keys = typeof input === "function" ? input(t as T) : (input as string[][]);
			await Promise.all(
				keys.map((k) => {
					return queryClient.invalidateQueries({
						queryKey: k,
					});
				}),
			);
			return isApiOk;
		}
		return isApiOk;
	}) as OptionalReturnFn<T>;
};
export const useUpdateReset = () => {
	const queryClient = useQueryClient();

	return async (isApiOk: boolean, queryKey: string[]) => {
		if (isApiOk) {
			await queryClient.resetQueries({
				queryKey,
			});
			return isApiOk;
		}
		return isApiOk;
	};
};
