import { Card, CardBody, CardFooter, CardHeader, CardHeading, FormattedDate, Loading } from "@dgs/core";
import { useQuery } from "@tanstack/react-query";
import { parseISO } from "date-fns";
import React, { FC } from "react";
import { useTranslation } from "react-i18next";
import styled from "styled-components";
import { EmployeeShowDto } from "~root/api_gen";
import { employeeKeys } from "~shared/api/employee/employeeKeys";
import { webserviceApi } from "~shared/api/webserviceApi";

type OverviewDayData = {
	date: string;
	hoursOfTimes: number;
	hoursOfVacations: number;
};

interface OverviewData {
	hoursTotal: number;
	hoursPerDay: number;
	days: OverviewDayData[];
}

const mockData: OverviewData = {
	hoursTotal: 5.2,
	hoursPerDay: 8,
	days: [
		{
			date: "2024-03-11",
			hoursOfTimes: 8,
			hoursOfVacations: 0,
		},
		{
			date: "2024-03-12",
			hoursOfTimes: 4,
			hoursOfVacations: 2,
		},
		{
			date: "2024-03-13",
			hoursOfTimes: 2,
			hoursOfVacations: 4,
		},
		{
			date: "2024-03-14",
			hoursOfTimes: 1,
			hoursOfVacations: 6,
		},
		{
			date: "2024-03-15",
			hoursOfTimes: 4,
			hoursOfVacations: 0,
		},
	],
};

const OverviewDays = styled.div`
	display: grid;
	grid-auto-flow: column;
	justify-content: space-around;
	height: 300px;
	grid-auto-columns: 60px;
`;
const OverviewDayColumn = styled.div`
	display: grid;
	grid-template-rows: min-content 1fr min-content;
`;

const OverviewDayHeading = styled.div`
	padding-bottom: ${({ theme }) => theme.spacing(1)};
	font-size: small;
	text-align: center;
`;

const OverviewDayBody = styled.div`
	position: relative;
	border-radius: 14px;
	overflow: hidden;

	& *:last-child {
		border-top-left-radius: 12px;
		border-top-right-radius: 12px;
	}
`;

const OverviewDayBar = styled.div<{ $sizeRatio: number; $color?: string; $bottom?: number }>`
	height: ${({ $sizeRatio }) => `${$sizeRatio * 100}%`};
	width: 100%;
	background-color: ${({ $color = "#cef1ff" }) => $color};
	position: absolute;
	bottom: ${({ $bottom = 0 }) => $bottom * 100}%;

	& > * {
		transition: all 200ms linear;
		display: flex;
		justify-content: center;
		align-items: center;
		height: 100%;
		opacity: 0;
		color: white;
	}

	&:hover * {
		opacity: 1;
	}
`;

const OverviewDayFooter = styled.div`
	padding-top: ${({ theme }) => theme.spacing(3)};
	font-size: small;
	text-align: center;
`;
export const EmployeeOverview: FC<{ employee: EmployeeShowDto }> = ({ employee }) => {
	const { data, isLoading } = useQuery({
		queryKey: employeeKeys.overview(employee.id),
		queryFn: () => webserviceApi.showEmployeeOverview(employee.id, "2024-03-07", "2024-03-11").then((x) => x.data.data),
	});
	const { t } = useTranslation();

	if (!data || isLoading) {
		return <Loading />;
	}
	return (
		<Card>
			<CardHeader>
				<CardHeading>{t("This week")}</CardHeading>
			</CardHeader>
			<CardBody>
				<OverviewDays>
					{data.dayData.map((dayData) => (
						<OverviewDayColumn key={dayData.date}>
							<OverviewDayHeading>{dayData.hoursOfVacations + dayData.hoursOfTimes}</OverviewDayHeading>
							<OverviewDayBody>
								<OverviewDayBar $sizeRatio={1} />
								<OverviewDayBar $color={"#0c92ff"} $sizeRatio={dayData.hoursOfTimes / mockData.hoursPerDay}>
									<span>{dayData.hoursOfTimes}</span>
								</OverviewDayBar>
								{dayData.hoursOfVacations / mockData.hoursPerDay > 0 && (
									<OverviewDayBar
										$color={"#faa636"}
										$sizeRatio={dayData.hoursOfVacations / mockData.hoursPerDay}
										$bottom={dayData.hoursOfTimes / mockData.hoursPerDay}
									>
										<span>{dayData.hoursOfVacations}</span>
									</OverviewDayBar>
								)}
							</OverviewDayBody>
							<OverviewDayFooter>
								<FormattedDate
									date={parseISO(dayData.date)}
									format={{
										day: "2-digit",
										month: "2-digit",
									}}
								/>
							</OverviewDayFooter>
						</OverviewDayColumn>
					))}
				</OverviewDays>
			</CardBody>
			<CardFooter>Legend</CardFooter>
		</Card>
	);
};
