import { NotAuthorizedPage, Protected } from "@dgs/core";
import React, { FC } from "react";
import { Navigate, Route, Routes } from "react-router-dom";
import { AppProviders } from "./app_providers";
import { PersonalManagerAuthPages } from "./auth";
import { Content } from "./content";

export const App: FC = () => {
	return (
		<AppProviders>
			<Routes>
				<Route path="/auth/*" element={<PersonalManagerAuthPages />} />
				<Route
					path="*"
					element={
						<Protected fallback={<Navigate to="/auth/login" />}>
							<Content />
						</Protected>
					}
				/>
				<Route path="not-authorized" element={<NotAuthorizedPage title="PerVerS" redirect={-1} />} />
			</Routes>
		</AppProviders>
	);
};
