/* tslint:disable */
/* eslint-disable */
/**
 * My Api
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import type { Configuration } from './configuration';
import type { AxiosPromise, AxiosInstance, RawAxiosRequestConfig } from 'axios';
import globalAxios from 'axios';
// Some imports not used depending on template conditions
// @ts-ignore
import { DUMMY_BASE_URL, assertParamExists, setApiKeyToObject, setBasicAuthToObject, setBearerAuthToObject, setOAuthToObject, setSearchParams, serializeDataIfNeeded, toPathString, createRequestFunction } from './common';
import type { RequestArgs } from './base';
// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS, BaseAPI, RequiredError, operationServerMap } from './base';

/**
 * 
 * @export
 * @interface AccountOptionDto
 */
export interface AccountOptionDto {
    /**
     * 
     * @type {number}
     * @memberof AccountOptionDto
     */
    'id': number;
    /**
     * 
     * @type {string}
     * @memberof AccountOptionDto
     */
    'name': string;
    /**
     * 
     * @type {AccountType}
     * @memberof AccountOptionDto
     */
    'type': AccountType;
}


/**
 * 
 * @export
 * @interface AccountTransactionDto
 */
export interface AccountTransactionDto {
    /**
     * 
     * @type {number}
     * @memberof AccountTransactionDto
     */
    'id': number;
    /**
     * 
     * @type {TransactionType}
     * @memberof AccountTransactionDto
     */
    'type': TransactionType;
    /**
     * 
     * @type {number}
     * @memberof AccountTransactionDto
     */
    'value': number;
    /**
     * 
     * @type {number}
     * @memberof AccountTransactionDto
     */
    'balance': number;
    /**
     * 
     * @type {string}
     * @memberof AccountTransactionDto
     */
    'cause'?: string | null;
}


/**
 * 
 * @export
 * @interface AccountTransactionDtoListResponse
 */
export interface AccountTransactionDtoListResponse {
    /**
     * 
     * @type {Array<AccountTransactionDto>}
     * @memberof AccountTransactionDtoListResponse
     */
    'data': Array<AccountTransactionDto>;
    /**
     * 
     * @type {Meta}
     * @memberof AccountTransactionDtoListResponse
     */
    'meta': Meta;
}
/**
 * 
 * @export
 * @enum {string}
 */

export const AccountType = {
    Vacation: 'vacation',
    Work: 'work'
} as const;

export type AccountType = typeof AccountType[keyof typeof AccountType];


/**
 * 
 * @export
 * @interface ActivityCreateDto
 */
export interface ActivityCreateDto {
    /**
     * 
     * @type {string}
     * @memberof ActivityCreateDto
     */
    'name': string;
    /**
     * 
     * @type {string}
     * @memberof ActivityCreateDto
     */
    'description'?: string | null;
}
/**
 * 
 * @export
 * @interface ActivityListDto
 */
export interface ActivityListDto {
    /**
     * 
     * @type {number}
     * @memberof ActivityListDto
     */
    'id': number;
    /**
     * 
     * @type {string}
     * @memberof ActivityListDto
     */
    'name': string;
    /**
     * 
     * @type {string}
     * @memberof ActivityListDto
     */
    'description': string;
}
/**
 * 
 * @export
 * @interface ActivityListDtoListResponse
 */
export interface ActivityListDtoListResponse {
    /**
     * 
     * @type {Array<ActivityListDto>}
     * @memberof ActivityListDtoListResponse
     */
    'data': Array<ActivityListDto>;
    /**
     * 
     * @type {Meta}
     * @memberof ActivityListDtoListResponse
     */
    'meta': Meta;
}
/**
 * 
 * @export
 * @interface ActivityOptionDto
 */
export interface ActivityOptionDto {
    /**
     * 
     * @type {number}
     * @memberof ActivityOptionDto
     */
    'id': number;
    /**
     * 
     * @type {string}
     * @memberof ActivityOptionDto
     */
    'name': string;
}
/**
 * 
 * @export
 * @interface ActivityOptionDtoListResponse
 */
export interface ActivityOptionDtoListResponse {
    /**
     * 
     * @type {Array<ActivityOptionDto>}
     * @memberof ActivityOptionDtoListResponse
     */
    'data': Array<ActivityOptionDto>;
    /**
     * 
     * @type {Meta}
     * @memberof ActivityOptionDtoListResponse
     */
    'meta': Meta;
}
/**
 * 
 * @export
 * @interface ActivityShowDto
 */
export interface ActivityShowDto {
    /**
     * 
     * @type {number}
     * @memberof ActivityShowDto
     */
    'id': number;
    /**
     * 
     * @type {string}
     * @memberof ActivityShowDto
     */
    'name': string;
    /**
     * 
     * @type {string}
     * @memberof ActivityShowDto
     */
    'description'?: string | null;
}
/**
 * 
 * @export
 * @interface ActivityShowDtoDataResponse
 */
export interface ActivityShowDtoDataResponse {
    /**
     * 
     * @type {ActivityShowDto}
     * @memberof ActivityShowDtoDataResponse
     */
    'data': ActivityShowDto;
    /**
     * 
     * @type {Meta}
     * @memberof ActivityShowDtoDataResponse
     */
    'meta': Meta;
}
/**
 * 
 * @export
 * @interface ActivityUpdateDto
 */
export interface ActivityUpdateDto {
    /**
     * 
     * @type {string}
     * @memberof ActivityUpdateDto
     */
    'name': string;
    /**
     * 
     * @type {string}
     * @memberof ActivityUpdateDto
     */
    'description'?: string | null;
}
/**
 * 
 * @export
 * @interface AddTransactionDto
 */
export interface AddTransactionDto {
    /**
     * 
     * @type {number}
     * @memberof AddTransactionDto
     */
    'value': number;
    /**
     * 
     * @type {TransactionType}
     * @memberof AddTransactionDto
     */
    'type': TransactionType;
}


/**
 * 
 * @export
 * @interface BranchCreateDto
 */
export interface BranchCreateDto {
    /**
     * 
     * @type {string}
     * @memberof BranchCreateDto
     */
    'name': string;
    /**
     * 
     * @type {string}
     * @memberof BranchCreateDto
     */
    'street'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof BranchCreateDto
     */
    'houseNumber'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof BranchCreateDto
     */
    'zip'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof BranchCreateDto
     */
    'city'?: string | null;
    /**
     * 
     * @type {States}
     * @memberof BranchCreateDto
     */
    'state'?: States | null;
    /**
     * 
     * @type {number}
     * @memberof BranchCreateDto
     */
    'countryId': number;
}


/**
 * 
 * @export
 * @interface BranchListDto
 */
export interface BranchListDto {
    /**
     * 
     * @type {number}
     * @memberof BranchListDto
     */
    'id': number;
    /**
     * 
     * @type {string}
     * @memberof BranchListDto
     */
    'name': string;
    /**
     * 
     * @type {string}
     * @memberof BranchListDto
     */
    'street'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof BranchListDto
     */
    'houseNumber'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof BranchListDto
     */
    'zip'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof BranchListDto
     */
    'city'?: string | null;
    /**
     * 
     * @type {States}
     * @memberof BranchListDto
     */
    'state'?: States | null;
    /**
     * 
     * @type {CountryOptionDto}
     * @memberof BranchListDto
     */
    'country': CountryOptionDto;
}


/**
 * 
 * @export
 * @interface BranchListDtoListResponse
 */
export interface BranchListDtoListResponse {
    /**
     * 
     * @type {Array<BranchListDto>}
     * @memberof BranchListDtoListResponse
     */
    'data': Array<BranchListDto>;
    /**
     * 
     * @type {Meta}
     * @memberof BranchListDtoListResponse
     */
    'meta': Meta;
}
/**
 * 
 * @export
 * @interface BranchOptionDto
 */
export interface BranchOptionDto {
    /**
     * 
     * @type {number}
     * @memberof BranchOptionDto
     */
    'id': number;
    /**
     * 
     * @type {string}
     * @memberof BranchOptionDto
     */
    'name': string;
}
/**
 * 
 * @export
 * @interface BranchOptionDtoListResponse
 */
export interface BranchOptionDtoListResponse {
    /**
     * 
     * @type {Array<BranchOptionDto>}
     * @memberof BranchOptionDtoListResponse
     */
    'data': Array<BranchOptionDto>;
    /**
     * 
     * @type {Meta}
     * @memberof BranchOptionDtoListResponse
     */
    'meta': Meta;
}
/**
 * 
 * @export
 * @interface BranchShowDto
 */
export interface BranchShowDto {
    /**
     * 
     * @type {number}
     * @memberof BranchShowDto
     */
    'id': number;
    /**
     * 
     * @type {string}
     * @memberof BranchShowDto
     */
    'name': string;
    /**
     * 
     * @type {string}
     * @memberof BranchShowDto
     */
    'street'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof BranchShowDto
     */
    'houseNumber'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof BranchShowDto
     */
    'zip'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof BranchShowDto
     */
    'city'?: string | null;
    /**
     * 
     * @type {States}
     * @memberof BranchShowDto
     */
    'state'?: States | null;
    /**
     * 
     * @type {CountryOptionDto}
     * @memberof BranchShowDto
     */
    'country': CountryOptionDto;
}


/**
 * 
 * @export
 * @interface BranchShowDtoDataResponse
 */
export interface BranchShowDtoDataResponse {
    /**
     * 
     * @type {BranchShowDto}
     * @memberof BranchShowDtoDataResponse
     */
    'data': BranchShowDto;
    /**
     * 
     * @type {Meta}
     * @memberof BranchShowDtoDataResponse
     */
    'meta': Meta;
}
/**
 * 
 * @export
 * @interface BranchUpdateDto
 */
export interface BranchUpdateDto {
    /**
     * 
     * @type {string}
     * @memberof BranchUpdateDto
     */
    'name': string;
    /**
     * 
     * @type {string}
     * @memberof BranchUpdateDto
     */
    'street'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof BranchUpdateDto
     */
    'houseNumber'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof BranchUpdateDto
     */
    'zip'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof BranchUpdateDto
     */
    'city'?: string | null;
    /**
     * 
     * @type {States}
     * @memberof BranchUpdateDto
     */
    'state'?: States | null;
    /**
     * 
     * @type {number}
     * @memberof BranchUpdateDto
     */
    'countryId': number;
}


/**
 * 
 * @export
 * @interface ConfigDto
 */
export interface ConfigDto {
    /**
     * 
     * @type {LanguageSettingsDto}
     * @memberof ConfigDto
     */
    'languageSettings': LanguageSettingsDto;
}
/**
 * 
 * @export
 * @interface ConfigDtoDataResponse
 */
export interface ConfigDtoDataResponse {
    /**
     * 
     * @type {ConfigDto}
     * @memberof ConfigDtoDataResponse
     */
    'data': ConfigDto;
    /**
     * 
     * @type {Meta}
     * @memberof ConfigDtoDataResponse
     */
    'meta': Meta;
}
/**
 * 
 * @export
 * @interface CountryOptionDto
 */
export interface CountryOptionDto {
    /**
     * 
     * @type {number}
     * @memberof CountryOptionDto
     */
    'id': number;
    /**
     * 
     * @type {string}
     * @memberof CountryOptionDto
     */
    'isoCode': string;
    /**
     * 
     * @type {Array<CountryTranslationDto>}
     * @memberof CountryOptionDto
     */
    'translations': Array<CountryTranslationDto>;
}
/**
 * 
 * @export
 * @interface CountryOptionDtoListResponse
 */
export interface CountryOptionDtoListResponse {
    /**
     * 
     * @type {Array<CountryOptionDto>}
     * @memberof CountryOptionDtoListResponse
     */
    'data': Array<CountryOptionDto>;
    /**
     * 
     * @type {Meta}
     * @memberof CountryOptionDtoListResponse
     */
    'meta': Meta;
}
/**
 * 
 * @export
 * @interface CountryTranslationDto
 */
export interface CountryTranslationDto {
    /**
     * 
     * @type {number}
     * @memberof CountryTranslationDto
     */
    'id': number;
    /**
     * 
     * @type {string}
     * @memberof CountryTranslationDto
     */
    'name': string;
}
/**
 * 
 * @export
 * @interface EmployeeAccountDto
 */
export interface EmployeeAccountDto {
    /**
     * 
     * @type {number}
     * @memberof EmployeeAccountDto
     */
    'id': number;
    /**
     * 
     * @type {string}
     * @memberof EmployeeAccountDto
     */
    'name': string;
    /**
     * 
     * @type {AccountType}
     * @memberof EmployeeAccountDto
     */
    'type': AccountType;
    /**
     * 
     * @type {number}
     * @memberof EmployeeAccountDto
     */
    'balance': number;
}


/**
 * 
 * @export
 * @interface EmployeeCreateDto
 */
export interface EmployeeCreateDto {
    /**
     * 
     * @type {string}
     * @memberof EmployeeCreateDto
     */
    'employeeNumber': string;
    /**
     * 
     * @type {string}
     * @memberof EmployeeCreateDto
     */
    'firstName': string;
    /**
     * 
     * @type {string}
     * @memberof EmployeeCreateDto
     */
    'lastName': string;
    /**
     * 
     * @type {string}
     * @memberof EmployeeCreateDto
     */
    'street'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof EmployeeCreateDto
     */
    'houseNumber'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof EmployeeCreateDto
     */
    'postalCode'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof EmployeeCreateDto
     */
    'city'?: string | null;
    /**
     * 
     * @type {number}
     * @memberof EmployeeCreateDto
     */
    'countryId'?: number | null;
    /**
     * 
     * @type {number}
     * @memberof EmployeeCreateDto
     */
    'branchId'?: number | null;
    /**
     * 
     * @type {string}
     * @memberof EmployeeCreateDto
     */
    'iban'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof EmployeeCreateDto
     */
    'placeOfBirth'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof EmployeeCreateDto
     */
    'countryOfBirth'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof EmployeeCreateDto
     */
    'dateOfBirth'?: string | null;
    /**
     * 
     * @type {Gender}
     * @memberof EmployeeCreateDto
     */
    'gender'?: Gender | null;
    /**
     * 
     * @type {string}
     * @memberof EmployeeCreateDto
     */
    'entryDate': string;
    /**
     * 
     * @type {string}
     * @memberof EmployeeCreateDto
     */
    'leaveDate'?: string | null;
    /**
     * 
     * @type {number}
     * @memberof EmployeeCreateDto
     */
    'hoursPerDay': number;
    /**
     * 
     * @type {number}
     * @memberof EmployeeCreateDto
     */
    'yearlyVacationDays': number;
    /**
     * 
     * @type {string}
     * @memberof EmployeeCreateDto
     */
    'userId'?: string | null;
    /**
     * 
     * @type {number}
     * @memberof EmployeeCreateDto
     */
    'worktimeAccountId'?: number | null;
    /**
     * 
     * @type {number}
     * @memberof EmployeeCreateDto
     */
    'vacationAccountId'?: number | null;
}


/**
 * 
 * @export
 * @interface EmployeeDashboardDto
 */
export interface EmployeeDashboardDto {
    /**
     * 
     * @type {number}
     * @memberof EmployeeDashboardDto
     */
    'totalVacationDays': number;
    /**
     * 
     * @type {number}
     * @memberof EmployeeDashboardDto
     */
    'availableVacationDays': number;
    /**
     * 
     * @type {number}
     * @memberof EmployeeDashboardDto
     */
    'pendingVacationDays': number;
    /**
     * 
     * @type {YearVacation}
     * @memberof EmployeeDashboardDto
     */
    'previousYearVacation'?: YearVacation | null;
    /**
     * 
     * @type {Array<EmployeeDashboardVacationDto>}
     * @memberof EmployeeDashboardDto
     */
    'nextVacations': Array<EmployeeDashboardVacationDto>;
}
/**
 * 
 * @export
 * @interface EmployeeDashboardVacationDto
 */
export interface EmployeeDashboardVacationDto {
    /**
     * 
     * @type {number}
     * @memberof EmployeeDashboardVacationDto
     */
    'id': number;
    /**
     * 
     * @type {string}
     * @memberof EmployeeDashboardVacationDto
     */
    'start': string;
    /**
     * 
     * @type {string}
     * @memberof EmployeeDashboardVacationDto
     */
    'end': string;
    /**
     * 
     * @type {string}
     * @memberof EmployeeDashboardVacationDto
     */
    'remark'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof EmployeeDashboardVacationDto
     */
    'declineReason'?: string | null;
    /**
     * 
     * @type {VacationStatus}
     * @memberof EmployeeDashboardVacationDto
     */
    'status': VacationStatus;
    /**
     * 
     * @type {boolean}
     * @memberof EmployeeDashboardVacationDto
     */
    'halfDay'?: boolean | null;
    /**
     * 
     * @type {EmployeeOptionDto}
     * @memberof EmployeeDashboardVacationDto
     */
    'standIn'?: EmployeeOptionDto | null;
    /**
     * 
     * @type {number}
     * @memberof EmployeeDashboardVacationDto
     */
    'vacationDaysCount': number;
}


/**
 * 
 * @export
 * @interface EmployeeListDto
 */
export interface EmployeeListDto {
    /**
     * 
     * @type {number}
     * @memberof EmployeeListDto
     */
    'id': number;
    /**
     * 
     * @type {string}
     * @memberof EmployeeListDto
     */
    'employeeNumber': string;
    /**
     * 
     * @type {string}
     * @memberof EmployeeListDto
     */
    'firstName': string;
    /**
     * 
     * @type {string}
     * @memberof EmployeeListDto
     */
    'lastName': string;
    /**
     * 
     * @type {string}
     * @memberof EmployeeListDto
     */
    'street'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof EmployeeListDto
     */
    'houseNumber'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof EmployeeListDto
     */
    'postalCode'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof EmployeeListDto
     */
    'city'?: string | null;
    /**
     * 
     * @type {CountryOptionDto}
     * @memberof EmployeeListDto
     */
    'country'?: CountryOptionDto | null;
    /**
     * 
     * @type {BranchOptionDto}
     * @memberof EmployeeListDto
     */
    'branch'?: BranchOptionDto | null;
    /**
     * 
     * @type {string}
     * @memberof EmployeeListDto
     */
    'iban'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof EmployeeListDto
     */
    'placeOfBirth'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof EmployeeListDto
     */
    'countryOfBirth'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof EmployeeListDto
     */
    'dateOfBirth'?: string | null;
    /**
     * 
     * @type {Gender}
     * @memberof EmployeeListDto
     */
    'gender'?: Gender | null;
    /**
     * 
     * @type {string}
     * @memberof EmployeeListDto
     */
    'entryDate': string;
    /**
     * 
     * @type {string}
     * @memberof EmployeeListDto
     */
    'leaveDate'?: string | null;
    /**
     * 
     * @type {number}
     * @memberof EmployeeListDto
     */
    'hoursPerDay': number;
    /**
     * 
     * @type {number}
     * @memberof EmployeeListDto
     */
    'yearlyVacationDays': number;
    /**
     * 
     * @type {AccountOptionDto}
     * @memberof EmployeeListDto
     */
    'worktimeAccount'?: AccountOptionDto | null;
    /**
     * 
     * @type {AccountOptionDto}
     * @memberof EmployeeListDto
     */
    'vacationAccount'?: AccountOptionDto | null;
    /**
     * 
     * @type {UserOptionDto}
     * @memberof EmployeeListDto
     */
    'user'?: UserOptionDto | null;
}


/**
 * 
 * @export
 * @interface EmployeeListDtoListResponse
 */
export interface EmployeeListDtoListResponse {
    /**
     * 
     * @type {Array<EmployeeListDto>}
     * @memberof EmployeeListDtoListResponse
     */
    'data': Array<EmployeeListDto>;
    /**
     * 
     * @type {Meta}
     * @memberof EmployeeListDtoListResponse
     */
    'meta': Meta;
}
/**
 * 
 * @export
 * @interface EmployeeListVacationDto
 */
export interface EmployeeListVacationDto {
    /**
     * 
     * @type {number}
     * @memberof EmployeeListVacationDto
     */
    'id': number;
    /**
     * 
     * @type {string}
     * @memberof EmployeeListVacationDto
     */
    'start': string;
    /**
     * 
     * @type {string}
     * @memberof EmployeeListVacationDto
     */
    'end': string;
    /**
     * 
     * @type {string}
     * @memberof EmployeeListVacationDto
     */
    'remark'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof EmployeeListVacationDto
     */
    'declineReason'?: string | null;
    /**
     * 
     * @type {VacationStatus}
     * @memberof EmployeeListVacationDto
     */
    'status': VacationStatus;
    /**
     * 
     * @type {boolean}
     * @memberof EmployeeListVacationDto
     */
    'halfDay'?: boolean | null;
    /**
     * 
     * @type {EmployeeOptionDto}
     * @memberof EmployeeListVacationDto
     */
    'standIn'?: EmployeeOptionDto | null;
}


/**
 * 
 * @export
 * @interface EmployeeListVacationDtoListResponse
 */
export interface EmployeeListVacationDtoListResponse {
    /**
     * 
     * @type {Array<EmployeeListVacationDto>}
     * @memberof EmployeeListVacationDtoListResponse
     */
    'data': Array<EmployeeListVacationDto>;
    /**
     * 
     * @type {Meta}
     * @memberof EmployeeListVacationDtoListResponse
     */
    'meta': Meta;
}
/**
 * 
 * @export
 * @interface EmployeeOptionDto
 */
export interface EmployeeOptionDto {
    /**
     * 
     * @type {number}
     * @memberof EmployeeOptionDto
     */
    'id': number;
    /**
     * 
     * @type {string}
     * @memberof EmployeeOptionDto
     */
    'firstName': string;
    /**
     * 
     * @type {string}
     * @memberof EmployeeOptionDto
     */
    'lastName': string;
}
/**
 * 
 * @export
 * @interface EmployeeOptionDtoListResponse
 */
export interface EmployeeOptionDtoListResponse {
    /**
     * 
     * @type {Array<EmployeeOptionDto>}
     * @memberof EmployeeOptionDtoListResponse
     */
    'data': Array<EmployeeOptionDto>;
    /**
     * 
     * @type {Meta}
     * @memberof EmployeeOptionDtoListResponse
     */
    'meta': Meta;
}
/**
 * 
 * @export
 * @interface EmployeeOverviewDayDto
 */
export interface EmployeeOverviewDayDto {
    /**
     * 
     * @type {string}
     * @memberof EmployeeOverviewDayDto
     */
    'date': string;
    /**
     * 
     * @type {number}
     * @memberof EmployeeOverviewDayDto
     */
    'hoursOfTimes': number;
    /**
     * 
     * @type {number}
     * @memberof EmployeeOverviewDayDto
     */
    'hoursOfVacations': number;
}
/**
 * 
 * @export
 * @interface EmployeeOverviewDto
 */
export interface EmployeeOverviewDto {
    /**
     * 
     * @type {number}
     * @memberof EmployeeOverviewDto
     */
    'hoursTotal': number;
    /**
     * 
     * @type {number}
     * @memberof EmployeeOverviewDto
     */
    'hoursPerDay': number;
    /**
     * 
     * @type {Array<EmployeeOverviewDayDto>}
     * @memberof EmployeeOverviewDto
     */
    'dayData': Array<EmployeeOverviewDayDto>;
}
/**
 * 
 * @export
 * @interface EmployeeOverviewDtoDataResponse
 */
export interface EmployeeOverviewDtoDataResponse {
    /**
     * 
     * @type {EmployeeOverviewDto}
     * @memberof EmployeeOverviewDtoDataResponse
     */
    'data': EmployeeOverviewDto;
    /**
     * 
     * @type {Meta}
     * @memberof EmployeeOverviewDtoDataResponse
     */
    'meta': Meta;
}
/**
 * 
 * @export
 * @interface EmployeeShowDto
 */
export interface EmployeeShowDto {
    /**
     * 
     * @type {number}
     * @memberof EmployeeShowDto
     */
    'id': number;
    /**
     * 
     * @type {string}
     * @memberof EmployeeShowDto
     */
    'employeeNumber': string;
    /**
     * 
     * @type {string}
     * @memberof EmployeeShowDto
     */
    'firstName': string;
    /**
     * 
     * @type {string}
     * @memberof EmployeeShowDto
     */
    'lastName': string;
    /**
     * 
     * @type {string}
     * @memberof EmployeeShowDto
     */
    'street'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof EmployeeShowDto
     */
    'houseNumber'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof EmployeeShowDto
     */
    'postalCode'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof EmployeeShowDto
     */
    'city'?: string | null;
    /**
     * 
     * @type {CountryOptionDto}
     * @memberof EmployeeShowDto
     */
    'country'?: CountryOptionDto | null;
    /**
     * 
     * @type {BranchOptionDto}
     * @memberof EmployeeShowDto
     */
    'branch'?: BranchOptionDto | null;
    /**
     * 
     * @type {string}
     * @memberof EmployeeShowDto
     */
    'iban'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof EmployeeShowDto
     */
    'placeOfBirth'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof EmployeeShowDto
     */
    'countryOfBirth'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof EmployeeShowDto
     */
    'dateOfBirth'?: string | null;
    /**
     * 
     * @type {Gender}
     * @memberof EmployeeShowDto
     */
    'gender'?: Gender | null;
    /**
     * 
     * @type {string}
     * @memberof EmployeeShowDto
     */
    'entryDate': string;
    /**
     * 
     * @type {string}
     * @memberof EmployeeShowDto
     */
    'leaveDate'?: string | null;
    /**
     * 
     * @type {number}
     * @memberof EmployeeShowDto
     */
    'hoursPerDay': number;
    /**
     * 
     * @type {number}
     * @memberof EmployeeShowDto
     */
    'yearlyVacationDays': number;
    /**
     * 
     * @type {AccountOptionDto}
     * @memberof EmployeeShowDto
     */
    'worktimeAccount'?: AccountOptionDto | null;
    /**
     * 
     * @type {EmployeeAccountDto}
     * @memberof EmployeeShowDto
     */
    'vacationAccount': EmployeeAccountDto;
    /**
     * 
     * @type {UserOptionDto}
     * @memberof EmployeeShowDto
     */
    'user'?: UserOptionDto | null;
}


/**
 * 
 * @export
 * @interface EmployeeShowDtoDataResponse
 */
export interface EmployeeShowDtoDataResponse {
    /**
     * 
     * @type {EmployeeShowDto}
     * @memberof EmployeeShowDtoDataResponse
     */
    'data': EmployeeShowDto;
    /**
     * 
     * @type {Meta}
     * @memberof EmployeeShowDtoDataResponse
     */
    'meta': Meta;
}
/**
 * 
 * @export
 * @interface EmployeeTimeListDto
 */
export interface EmployeeTimeListDto {
    /**
     * 
     * @type {number}
     * @memberof EmployeeTimeListDto
     */
    'id': number;
    /**
     * 
     * @type {string}
     * @memberof EmployeeTimeListDto
     */
    'start': string;
    /**
     * 
     * @type {string}
     * @memberof EmployeeTimeListDto
     */
    'end'?: string | null;
}
/**
 * 
 * @export
 * @interface EmployeeTimeListDtoListResponse
 */
export interface EmployeeTimeListDtoListResponse {
    /**
     * 
     * @type {Array<EmployeeTimeListDto>}
     * @memberof EmployeeTimeListDtoListResponse
     */
    'data': Array<EmployeeTimeListDto>;
    /**
     * 
     * @type {Meta}
     * @memberof EmployeeTimeListDtoListResponse
     */
    'meta': Meta;
}
/**
 * 
 * @export
 * @interface EmployeeUpdateDto
 */
export interface EmployeeUpdateDto {
    /**
     * 
     * @type {string}
     * @memberof EmployeeUpdateDto
     */
    'employeeNumber': string;
    /**
     * 
     * @type {string}
     * @memberof EmployeeUpdateDto
     */
    'firstName': string;
    /**
     * 
     * @type {string}
     * @memberof EmployeeUpdateDto
     */
    'lastName': string;
    /**
     * 
     * @type {string}
     * @memberof EmployeeUpdateDto
     */
    'street'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof EmployeeUpdateDto
     */
    'houseNumber'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof EmployeeUpdateDto
     */
    'postalCode'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof EmployeeUpdateDto
     */
    'city'?: string | null;
    /**
     * 
     * @type {number}
     * @memberof EmployeeUpdateDto
     */
    'countryId'?: number | null;
    /**
     * 
     * @type {number}
     * @memberof EmployeeUpdateDto
     */
    'branchId'?: number | null;
    /**
     * 
     * @type {string}
     * @memberof EmployeeUpdateDto
     */
    'iban'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof EmployeeUpdateDto
     */
    'placeOfBirth'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof EmployeeUpdateDto
     */
    'countryOfBirth'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof EmployeeUpdateDto
     */
    'dateOfBirth'?: string | null;
    /**
     * 
     * @type {Gender}
     * @memberof EmployeeUpdateDto
     */
    'gender'?: Gender | null;
    /**
     * 
     * @type {string}
     * @memberof EmployeeUpdateDto
     */
    'entryDate': string;
    /**
     * 
     * @type {string}
     * @memberof EmployeeUpdateDto
     */
    'leaveDate'?: string | null;
    /**
     * 
     * @type {number}
     * @memberof EmployeeUpdateDto
     */
    'hoursPerDay': number;
    /**
     * 
     * @type {number}
     * @memberof EmployeeUpdateDto
     */
    'yearlyVacationDays': number;
    /**
     * 
     * @type {string}
     * @memberof EmployeeUpdateDto
     */
    'userId'?: string | null;
    /**
     * 
     * @type {number}
     * @memberof EmployeeUpdateDto
     */
    'worktimeAccountId'?: number | null;
    /**
     * 
     * @type {number}
     * @memberof EmployeeUpdateDto
     */
    'vacationAccountId'?: number | null;
}


/**
 * 
 * @export
 * @interface EmployeeWithBalanceDto
 */
export interface EmployeeWithBalanceDto {
    /**
     * 
     * @type {number}
     * @memberof EmployeeWithBalanceDto
     */
    'id': number;
    /**
     * 
     * @type {string}
     * @memberof EmployeeWithBalanceDto
     */
    'firstName': string;
    /**
     * 
     * @type {string}
     * @memberof EmployeeWithBalanceDto
     */
    'lastName': string;
    /**
     * 
     * @type {number}
     * @memberof EmployeeWithBalanceDto
     */
    'currentBalance': number;
}
/**
 * 
 * @export
 * @enum {string}
 */

export const Gender = {
    Male: 'male',
    Female: 'female'
} as const;

export type Gender = typeof Gender[keyof typeof Gender];


/**
 * 
 * @export
 * @interface IdentityProviderDto
 */
export interface IdentityProviderDto {
    /**
     * 
     * @type {string}
     * @memberof IdentityProviderDto
     */
    'name': string;
    /**
     * 
     * @type {string}
     * @memberof IdentityProviderDto
     */
    'url': string;
}
/**
 * 
 * @export
 * @interface IdentityProviderDtoListDataResponse
 */
export interface IdentityProviderDtoListDataResponse {
    /**
     * 
     * @type {Array<IdentityProviderDto>}
     * @memberof IdentityProviderDtoListDataResponse
     */
    'data': Array<IdentityProviderDto>;
    /**
     * 
     * @type {Meta}
     * @memberof IdentityProviderDtoListDataResponse
     */
    'meta': Meta;
}
/**
 * 
 * @export
 * @interface Int32CreatedResponse
 */
export interface Int32CreatedResponse {
    /**
     * 
     * @type {number}
     * @memberof Int32CreatedResponse
     */
    'id': number;
    /**
     * 
     * @type {Int32IdResponse}
     * @memberof Int32CreatedResponse
     */
    'data': Int32IdResponse;
}
/**
 * 
 * @export
 * @interface Int32IdResponse
 */
export interface Int32IdResponse {
    /**
     * 
     * @type {number}
     * @memberof Int32IdResponse
     */
    'id': number;
}
/**
 * 
 * @export
 * @interface JsonNode
 */
export interface JsonNode {
    /**
     * 
     * @type {JsonNodeOptions}
     * @memberof JsonNode
     */
    'options'?: JsonNodeOptions | null;
    /**
     * 
     * @type {JsonNode}
     * @memberof JsonNode
     */
    'parent'?: JsonNode | null;
    /**
     * 
     * @type {JsonNode}
     * @memberof JsonNode
     */
    'root': JsonNode;
}
/**
 * 
 * @export
 * @interface JsonNodeOptions
 */
export interface JsonNodeOptions {
    /**
     * 
     * @type {boolean}
     * @memberof JsonNodeOptions
     */
    'propertyNameCaseInsensitive': boolean;
}
/**
 * 
 * @export
 * @interface JsonValue
 */
export interface JsonValue {
    /**
     * 
     * @type {JsonNodeOptions}
     * @memberof JsonValue
     */
    'options'?: JsonNodeOptions | null;
    /**
     * 
     * @type {JsonNode}
     * @memberof JsonValue
     */
    'parent'?: JsonNode | null;
    /**
     * 
     * @type {JsonNode}
     * @memberof JsonValue
     */
    'root': JsonNode;
}
/**
 * 
 * @export
 * @interface LanguageSettingsDto
 */
export interface LanguageSettingsDto {
    /**
     * 
     * @type {string}
     * @memberof LanguageSettingsDto
     */
    'fallback': string;
    /**
     * 
     * @type {Array<string>}
     * @memberof LanguageSettingsDto
     */
    'availableLanguages': Array<string>;
    /**
     * 
     * @type {string}
     * @memberof LanguageSettingsDto
     */
    'fileUrl': string;
}
/**
 * 
 * @export
 * @interface ListOwnPayrollDto
 */
export interface ListOwnPayrollDto {
    /**
     * 
     * @type {string}
     * @memberof ListOwnPayrollDto
     */
    'id': string;
    /**
     * 
     * @type {string}
     * @memberof ListOwnPayrollDto
     */
    'name': string;
    /**
     * 
     * @type {number}
     * @memberof ListOwnPayrollDto
     */
    'year': number;
    /**
     * 
     * @type {number}
     * @memberof ListOwnPayrollDto
     */
    'month': number;
}
/**
 * 
 * @export
 * @interface ListOwnPayrollDtoListResponse
 */
export interface ListOwnPayrollDtoListResponse {
    /**
     * 
     * @type {Array<ListOwnPayrollDto>}
     * @memberof ListOwnPayrollDtoListResponse
     */
    'data': Array<ListOwnPayrollDto>;
    /**
     * 
     * @type {Meta}
     * @memberof ListOwnPayrollDtoListResponse
     */
    'meta': Meta;
}
/**
 * 
 * @export
 * @interface ListPayrollDto
 */
export interface ListPayrollDto {
    /**
     * 
     * @type {string}
     * @memberof ListPayrollDto
     */
    'id': string;
    /**
     * 
     * @type {string}
     * @memberof ListPayrollDto
     */
    'name': string;
    /**
     * 
     * @type {EmployeeOptionDto}
     * @memberof ListPayrollDto
     */
    'employee': EmployeeOptionDto;
    /**
     * 
     * @type {number}
     * @memberof ListPayrollDto
     */
    'year': number;
    /**
     * 
     * @type {number}
     * @memberof ListPayrollDto
     */
    'month': number;
}
/**
 * 
 * @export
 * @interface ListPayrollDtoListResponse
 */
export interface ListPayrollDtoListResponse {
    /**
     * 
     * @type {Array<ListPayrollDto>}
     * @memberof ListPayrollDtoListResponse
     */
    'data': Array<ListPayrollDto>;
    /**
     * 
     * @type {Meta}
     * @memberof ListPayrollDtoListResponse
     */
    'meta': Meta;
}
/**
 * 
 * @export
 * @interface ListPayrollsFilterItem
 */
export interface ListPayrollsFilterItem {
    /**
     * 
     * @type {string}
     * @memberof ListPayrollsFilterItem
     */
    'operator': string;
    /**
     * 
     * @type {string}
     * @memberof ListPayrollsFilterItem
     */
    'valueKey': string;
    /**
     * 
     * @type {JsonValue}
     * @memberof ListPayrollsFilterItem
     */
    'value': JsonValue;
}
/**
 * 
 * @export
 * @interface ListVacationDto
 */
export interface ListVacationDto {
    /**
     * 
     * @type {number}
     * @memberof ListVacationDto
     */
    'id': number;
    /**
     * 
     * @type {string}
     * @memberof ListVacationDto
     */
    'start': string;
    /**
     * 
     * @type {string}
     * @memberof ListVacationDto
     */
    'end': string;
    /**
     * 
     * @type {EmployeeOptionDto}
     * @memberof ListVacationDto
     */
    'employee': EmployeeOptionDto;
    /**
     * 
     * @type {string}
     * @memberof ListVacationDto
     */
    'remark'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ListVacationDto
     */
    'declineReason'?: string | null;
    /**
     * 
     * @type {VacationStatus}
     * @memberof ListVacationDto
     */
    'status': VacationStatus;
    /**
     * 
     * @type {boolean}
     * @memberof ListVacationDto
     */
    'halfDay'?: boolean | null;
    /**
     * 
     * @type {EmployeeOptionDto}
     * @memberof ListVacationDto
     */
    'standIn'?: EmployeeOptionDto | null;
}


/**
 * 
 * @export
 * @interface ListVacationDtoListResponse
 */
export interface ListVacationDtoListResponse {
    /**
     * 
     * @type {Array<ListVacationDto>}
     * @memberof ListVacationDtoListResponse
     */
    'data': Array<ListVacationDto>;
    /**
     * 
     * @type {Meta}
     * @memberof ListVacationDtoListResponse
     */
    'meta': Meta;
}
/**
 * 
 * @export
 * @interface ListVacationsFilterItem
 */
export interface ListVacationsFilterItem {
    /**
     * 
     * @type {string}
     * @memberof ListVacationsFilterItem
     */
    'operator': string;
    /**
     * 
     * @type {string}
     * @memberof ListVacationsFilterItem
     */
    'valueKey': string;
    /**
     * 
     * @type {JsonValue}
     * @memberof ListVacationsFilterItem
     */
    'value': JsonValue;
}
/**
 * 
 * @export
 * @interface Meta
 */
export interface Meta {
    /**
     * 
     * @type {number}
     * @memberof Meta
     */
    'total': number;
    /**
     * 
     * @type {number}
     * @memberof Meta
     */
    'per_page': number;
    /**
     * 
     * @type {number}
     * @memberof Meta
     */
    'current_page': number;
    /**
     * 
     * @type {number}
     * @memberof Meta
     */
    'from': number;
    /**
     * 
     * @type {number}
     * @memberof Meta
     */
    'to': number;
    /**
     * 
     * @type {number}
     * @memberof Meta
     */
    'last_page': number;
}
/**
 * 
 * @export
 * @interface OffDayCreateDto
 */
export interface OffDayCreateDto {
    /**
     * 
     * @type {string}
     * @memberof OffDayCreateDto
     */
    'date': string;
    /**
     * 
     * @type {boolean}
     * @memberof OffDayCreateDto
     */
    'halfDay': boolean;
    /**
     * 
     * @type {number}
     * @memberof OffDayCreateDto
     */
    'branchId': number;
}
/**
 * 
 * @export
 * @interface OffDayListDto
 */
export interface OffDayListDto {
    /**
     * 
     * @type {number}
     * @memberof OffDayListDto
     */
    'id': number;
    /**
     * 
     * @type {string}
     * @memberof OffDayListDto
     */
    'date': string;
    /**
     * 
     * @type {boolean}
     * @memberof OffDayListDto
     */
    'halfDay': boolean;
    /**
     * 
     * @type {BranchOptionDto}
     * @memberof OffDayListDto
     */
    'branch': BranchOptionDto;
}
/**
 * 
 * @export
 * @interface OffDayListDtoListResponse
 */
export interface OffDayListDtoListResponse {
    /**
     * 
     * @type {Array<OffDayListDto>}
     * @memberof OffDayListDtoListResponse
     */
    'data': Array<OffDayListDto>;
    /**
     * 
     * @type {Meta}
     * @memberof OffDayListDtoListResponse
     */
    'meta': Meta;
}
/**
 * 
 * @export
 * @interface OffDayShowDto
 */
export interface OffDayShowDto {
    /**
     * 
     * @type {number}
     * @memberof OffDayShowDto
     */
    'id': number;
    /**
     * 
     * @type {string}
     * @memberof OffDayShowDto
     */
    'date': string;
    /**
     * 
     * @type {boolean}
     * @memberof OffDayShowDto
     */
    'halfDay': boolean;
    /**
     * 
     * @type {BranchOptionDto}
     * @memberof OffDayShowDto
     */
    'branch': BranchOptionDto;
}
/**
 * 
 * @export
 * @interface OffDayShowDtoDataResponse
 */
export interface OffDayShowDtoDataResponse {
    /**
     * 
     * @type {OffDayShowDto}
     * @memberof OffDayShowDtoDataResponse
     */
    'data': OffDayShowDto;
    /**
     * 
     * @type {Meta}
     * @memberof OffDayShowDtoDataResponse
     */
    'meta': Meta;
}
/**
 * 
 * @export
 * @interface OffDayUpdateDto
 */
export interface OffDayUpdateDto {
    /**
     * 
     * @type {string}
     * @memberof OffDayUpdateDto
     */
    'date': string;
    /**
     * 
     * @type {boolean}
     * @memberof OffDayUpdateDto
     */
    'halfDay': boolean;
    /**
     * 
     * @type {number}
     * @memberof OffDayUpdateDto
     */
    'branchId': number;
}
/**
 * 
 * @export
 * @interface OwnTimeListDto
 */
export interface OwnTimeListDto {
    /**
     * 
     * @type {number}
     * @memberof OwnTimeListDto
     */
    'id': number;
    /**
     * 
     * @type {string}
     * @memberof OwnTimeListDto
     */
    'start': string;
    /**
     * 
     * @type {string}
     * @memberof OwnTimeListDto
     */
    'end'?: string | null;
    /**
     * 
     * @type {ActivityOptionDto}
     * @memberof OwnTimeListDto
     */
    'activity': ActivityOptionDto;
    /**
     * 
     * @type {ProjectOptionDto}
     * @memberof OwnTimeListDto
     */
    'project': ProjectOptionDto;
}
/**
 * 
 * @export
 * @interface OwnTimeListDtoListResponse
 */
export interface OwnTimeListDtoListResponse {
    /**
     * 
     * @type {Array<OwnTimeListDto>}
     * @memberof OwnTimeListDtoListResponse
     */
    'data': Array<OwnTimeListDto>;
    /**
     * 
     * @type {Meta}
     * @memberof OwnTimeListDtoListResponse
     */
    'meta': Meta;
}
/**
 * 
 * @export
 * @interface OwnVacationDto
 */
export interface OwnVacationDto {
    /**
     * 
     * @type {number}
     * @memberof OwnVacationDto
     */
    'id': number;
    /**
     * 
     * @type {string}
     * @memberof OwnVacationDto
     */
    'start': string;
    /**
     * 
     * @type {string}
     * @memberof OwnVacationDto
     */
    'end': string;
    /**
     * 
     * @type {string}
     * @memberof OwnVacationDto
     */
    'remark'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof OwnVacationDto
     */
    'declineReason'?: string | null;
    /**
     * 
     * @type {VacationStatus}
     * @memberof OwnVacationDto
     */
    'status': VacationStatus;
    /**
     * 
     * @type {boolean}
     * @memberof OwnVacationDto
     */
    'halfDay'?: boolean | null;
    /**
     * 
     * @type {EmployeeOptionDto}
     * @memberof OwnVacationDto
     */
    'standIn'?: EmployeeOptionDto | null;
}


/**
 * 
 * @export
 * @interface OwnVacationDtoListResponse
 */
export interface OwnVacationDtoListResponse {
    /**
     * 
     * @type {Array<OwnVacationDto>}
     * @memberof OwnVacationDtoListResponse
     */
    'data': Array<OwnVacationDto>;
    /**
     * 
     * @type {Meta}
     * @memberof OwnVacationDtoListResponse
     */
    'meta': Meta;
}
/**
 * 
 * @export
 * @interface PayrollsListFilter
 */
export interface PayrollsListFilter {
    /**
     * 
     * @type {Array<ListPayrollsFilterItem>}
     * @memberof PayrollsListFilter
     */
    'filterItems': Array<ListPayrollsFilterItem>;
}
/**
 * 
 * @export
 * @interface PermissionDto
 */
export interface PermissionDto {
    /**
     * 
     * @type {number}
     * @memberof PermissionDto
     */
    'id': number;
    /**
     * 
     * @type {string}
     * @memberof PermissionDto
     */
    'name': string;
}
/**
 * 
 * @export
 * @interface ProjectCreateDto
 */
export interface ProjectCreateDto {
    /**
     * 
     * @type {string}
     * @memberof ProjectCreateDto
     */
    'name': string;
    /**
     * 
     * @type {string}
     * @memberof ProjectCreateDto
     */
    'description'?: string | null;
    /**
     * 
     * @type {Array<ProjectSyncActivityDto>}
     * @memberof ProjectCreateDto
     */
    'activities': Array<ProjectSyncActivityDto>;
}
/**
 * 
 * @export
 * @interface ProjectListDto
 */
export interface ProjectListDto {
    /**
     * 
     * @type {number}
     * @memberof ProjectListDto
     */
    'id': number;
    /**
     * 
     * @type {string}
     * @memberof ProjectListDto
     */
    'name': string;
    /**
     * 
     * @type {string}
     * @memberof ProjectListDto
     */
    'description': string;
}
/**
 * 
 * @export
 * @interface ProjectListDtoListResponse
 */
export interface ProjectListDtoListResponse {
    /**
     * 
     * @type {Array<ProjectListDto>}
     * @memberof ProjectListDtoListResponse
     */
    'data': Array<ProjectListDto>;
    /**
     * 
     * @type {Meta}
     * @memberof ProjectListDtoListResponse
     */
    'meta': Meta;
}
/**
 * 
 * @export
 * @interface ProjectOptionDto
 */
export interface ProjectOptionDto {
    /**
     * 
     * @type {number}
     * @memberof ProjectOptionDto
     */
    'id': number;
    /**
     * 
     * @type {string}
     * @memberof ProjectOptionDto
     */
    'name': string;
}
/**
 * 
 * @export
 * @interface ProjectOptionDtoListResponse
 */
export interface ProjectOptionDtoListResponse {
    /**
     * 
     * @type {Array<ProjectOptionDto>}
     * @memberof ProjectOptionDtoListResponse
     */
    'data': Array<ProjectOptionDto>;
    /**
     * 
     * @type {Meta}
     * @memberof ProjectOptionDtoListResponse
     */
    'meta': Meta;
}
/**
 * 
 * @export
 * @interface ProjectShowDto
 */
export interface ProjectShowDto {
    /**
     * 
     * @type {number}
     * @memberof ProjectShowDto
     */
    'id': number;
    /**
     * 
     * @type {string}
     * @memberof ProjectShowDto
     */
    'name': string;
    /**
     * 
     * @type {string}
     * @memberof ProjectShowDto
     */
    'description': string;
    /**
     * 
     * @type {Array<ActivityOptionDto>}
     * @memberof ProjectShowDto
     */
    'activities': Array<ActivityOptionDto>;
}
/**
 * 
 * @export
 * @interface ProjectShowDtoDataResponse
 */
export interface ProjectShowDtoDataResponse {
    /**
     * 
     * @type {ProjectShowDto}
     * @memberof ProjectShowDtoDataResponse
     */
    'data': ProjectShowDto;
    /**
     * 
     * @type {Meta}
     * @memberof ProjectShowDtoDataResponse
     */
    'meta': Meta;
}
/**
 * 
 * @export
 * @interface ProjectSyncActivityDto
 */
export interface ProjectSyncActivityDto {
    /**
     * 
     * @type {string}
     * @memberof ProjectSyncActivityDto
     */
    'kind': string;
}
/**
 * 
 * @export
 * @interface ProjectUpdateDto
 */
export interface ProjectUpdateDto {
    /**
     * 
     * @type {string}
     * @memberof ProjectUpdateDto
     */
    'name': string;
    /**
     * 
     * @type {string}
     * @memberof ProjectUpdateDto
     */
    'description'?: string | null;
    /**
     * 
     * @type {Array<ProjectSyncActivityDto>}
     * @memberof ProjectUpdateDto
     */
    'activities': Array<ProjectSyncActivityDto>;
}
/**
 * 
 * @export
 * @interface Request
 */
export interface Request {
    /**
     * 
     * @type {string}
     * @memberof Request
     */
    'email': string;
    /**
     * 
     * @type {string}
     * @memberof Request
     */
    'password': string;
}
/**
 * 
 * @export
 * @interface RoleCreateDto
 */
export interface RoleCreateDto {
    /**
     * 
     * @type {string}
     * @memberof RoleCreateDto
     */
    'name': string;
    /**
     * 
     * @type {Array<number>}
     * @memberof RoleCreateDto
     */
    'permissions': Array<number>;
    /**
     * 
     * @type {number}
     * @memberof RoleCreateDto
     */
    'timesVisibilityId': number;
    /**
     * 
     * @type {number}
     * @memberof RoleCreateDto
     */
    'vacationsVisibilityId': number;
    /**
     * 
     * @type {number}
     * @memberof RoleCreateDto
     */
    'payrollsVisibilityId': number;
}
/**
 * 
 * @export
 * @interface RoleListDto
 */
export interface RoleListDto {
    /**
     * 
     * @type {number}
     * @memberof RoleListDto
     */
    'id': number;
    /**
     * 
     * @type {string}
     * @memberof RoleListDto
     */
    'name': string;
    /**
     * 
     * @type {Array<UserOptionDto>}
     * @memberof RoleListDto
     */
    'users': Array<UserOptionDto>;
}
/**
 * 
 * @export
 * @interface RoleListDtoListResponse
 */
export interface RoleListDtoListResponse {
    /**
     * 
     * @type {Array<RoleListDto>}
     * @memberof RoleListDtoListResponse
     */
    'data': Array<RoleListDto>;
    /**
     * 
     * @type {Meta}
     * @memberof RoleListDtoListResponse
     */
    'meta': Meta;
}
/**
 * 
 * @export
 * @interface RoleOptionDto
 */
export interface RoleOptionDto {
    /**
     * 
     * @type {number}
     * @memberof RoleOptionDto
     */
    'id': number;
    /**
     * 
     * @type {string}
     * @memberof RoleOptionDto
     */
    'name': string;
}
/**
 * 
 * @export
 * @interface RoleOptionDtoListResponse
 */
export interface RoleOptionDtoListResponse {
    /**
     * 
     * @type {Array<RoleOptionDto>}
     * @memberof RoleOptionDtoListResponse
     */
    'data': Array<RoleOptionDto>;
    /**
     * 
     * @type {Meta}
     * @memberof RoleOptionDtoListResponse
     */
    'meta': Meta;
}
/**
 * 
 * @export
 * @interface RoleShowDto
 */
export interface RoleShowDto {
    /**
     * 
     * @type {number}
     * @memberof RoleShowDto
     */
    'id': number;
    /**
     * 
     * @type {string}
     * @memberof RoleShowDto
     */
    'name': string;
    /**
     * 
     * @type {Array<UserOptionDto>}
     * @memberof RoleShowDto
     */
    'users': Array<UserOptionDto>;
    /**
     * 
     * @type {Array<PermissionDto>}
     * @memberof RoleShowDto
     */
    'permissions': Array<PermissionDto>;
    /**
     * 
     * @type {PermissionDto}
     * @memberof RoleShowDto
     */
    'vacationsVisibility': PermissionDto;
    /**
     * 
     * @type {PermissionDto}
     * @memberof RoleShowDto
     */
    'timesVisibility': PermissionDto;
    /**
     * 
     * @type {PermissionDto}
     * @memberof RoleShowDto
     */
    'payrollsVisibility'?: PermissionDto | null;
}
/**
 * 
 * @export
 * @interface RoleShowDtoDataResponse
 */
export interface RoleShowDtoDataResponse {
    /**
     * 
     * @type {RoleShowDto}
     * @memberof RoleShowDtoDataResponse
     */
    'data': RoleShowDto;
    /**
     * 
     * @type {Meta}
     * @memberof RoleShowDtoDataResponse
     */
    'meta': Meta;
}
/**
 * 
 * @export
 * @interface RoleUpdateDto
 */
export interface RoleUpdateDto {
    /**
     * 
     * @type {string}
     * @memberof RoleUpdateDto
     */
    'name': string;
    /**
     * 
     * @type {Array<number>}
     * @memberof RoleUpdateDto
     */
    'permissions': Array<number>;
    /**
     * 
     * @type {number}
     * @memberof RoleUpdateDto
     */
    'timesVisibilityId': number;
    /**
     * 
     * @type {number}
     * @memberof RoleUpdateDto
     */
    'vacationsVisibilityId': number;
    /**
     * 
     * @type {number}
     * @memberof RoleUpdateDto
     */
    'payrollsVisibilityId': number;
}
/**
 * 
 * @export
 * @enum {string}
 */

export const States = {
    All: 'all',
    Bw: 'bw',
    By: 'by',
    Be: 'be',
    Bb: 'bb',
    Hb: 'hb',
    Hh: 'hh',
    He: 'he',
    Mv: 'mv',
    Ni: 'ni',
    Nw: 'nw',
    Rp: 'rp',
    Sl: 'sl',
    Sn: 'sn',
    St: 'st',
    Sh: 'sh',
    Th: 'th'
} as const;

export type States = typeof States[keyof typeof States];


/**
 * 
 * @export
 * @interface StatesListResponse
 */
export interface StatesListResponse {
    /**
     * 
     * @type {Array<States>}
     * @memberof StatesListResponse
     */
    'data': Array<States>;
    /**
     * 
     * @type {Meta}
     * @memberof StatesListResponse
     */
    'meta': Meta;
}
/**
 * 
 * @export
 * @interface SubtractTransactionDto
 */
export interface SubtractTransactionDto {
    /**
     * 
     * @type {number}
     * @memberof SubtractTransactionDto
     */
    'value': number;
    /**
     * 
     * @type {TransactionType}
     * @memberof SubtractTransactionDto
     */
    'type': TransactionType;
}


/**
 * 
 * @export
 * @interface TeamCreateDto
 */
export interface TeamCreateDto {
    /**
     * 
     * @type {string}
     * @memberof TeamCreateDto
     */
    'name': string;
    /**
     * 
     * @type {Array<number>}
     * @memberof TeamCreateDto
     */
    'employeeIds': Array<number>;
    /**
     * 
     * @type {Array<number>}
     * @memberof TeamCreateDto
     */
    'leaderIds': Array<number>;
}
/**
 * 
 * @export
 * @interface TeamListDto
 */
export interface TeamListDto {
    /**
     * 
     * @type {number}
     * @memberof TeamListDto
     */
    'id': number;
    /**
     * 
     * @type {string}
     * @memberof TeamListDto
     */
    'name': string;
    /**
     * 
     * @type {Array<EmployeeOptionDto>}
     * @memberof TeamListDto
     */
    'employees': Array<EmployeeOptionDto>;
    /**
     * 
     * @type {Array<EmployeeOptionDto>}
     * @memberof TeamListDto
     */
    'leaders': Array<EmployeeOptionDto>;
}
/**
 * 
 * @export
 * @interface TeamListDtoListResponse
 */
export interface TeamListDtoListResponse {
    /**
     * 
     * @type {Array<TeamListDto>}
     * @memberof TeamListDtoListResponse
     */
    'data': Array<TeamListDto>;
    /**
     * 
     * @type {Meta}
     * @memberof TeamListDtoListResponse
     */
    'meta': Meta;
}
/**
 * 
 * @export
 * @interface TeamOptionDto
 */
export interface TeamOptionDto {
    /**
     * 
     * @type {number}
     * @memberof TeamOptionDto
     */
    'id': number;
    /**
     * 
     * @type {string}
     * @memberof TeamOptionDto
     */
    'name': string;
}
/**
 * 
 * @export
 * @interface TeamOptionDtoListResponse
 */
export interface TeamOptionDtoListResponse {
    /**
     * 
     * @type {Array<TeamOptionDto>}
     * @memberof TeamOptionDtoListResponse
     */
    'data': Array<TeamOptionDto>;
    /**
     * 
     * @type {Meta}
     * @memberof TeamOptionDtoListResponse
     */
    'meta': Meta;
}
/**
 * 
 * @export
 * @interface TeamShowDto
 */
export interface TeamShowDto {
    /**
     * 
     * @type {number}
     * @memberof TeamShowDto
     */
    'id': number;
    /**
     * 
     * @type {string}
     * @memberof TeamShowDto
     */
    'name': string;
    /**
     * 
     * @type {Array<EmployeeOptionDto>}
     * @memberof TeamShowDto
     */
    'employees': Array<EmployeeOptionDto>;
    /**
     * 
     * @type {Array<EmployeeOptionDto>}
     * @memberof TeamShowDto
     */
    'leaders': Array<EmployeeOptionDto>;
}
/**
 * 
 * @export
 * @interface TeamShowDtoDataResponse
 */
export interface TeamShowDtoDataResponse {
    /**
     * 
     * @type {TeamShowDto}
     * @memberof TeamShowDtoDataResponse
     */
    'data': TeamShowDto;
    /**
     * 
     * @type {Meta}
     * @memberof TeamShowDtoDataResponse
     */
    'meta': Meta;
}
/**
 * 
 * @export
 * @interface TeamUpdateDto
 */
export interface TeamUpdateDto {
    /**
     * 
     * @type {string}
     * @memberof TeamUpdateDto
     */
    'name': string;
    /**
     * 
     * @type {Array<number>}
     * @memberof TeamUpdateDto
     */
    'employeeIds': Array<number>;
    /**
     * 
     * @type {Array<number>}
     * @memberof TeamUpdateDto
     */
    'leaderIds': Array<number>;
}
/**
 * 
 * @export
 * @interface TimeCreateDto
 */
export interface TimeCreateDto {
    /**
     * 
     * @type {string}
     * @memberof TimeCreateDto
     */
    'start': string;
    /**
     * 
     * @type {string}
     * @memberof TimeCreateDto
     */
    'end'?: string | null;
    /**
     * 
     * @type {number}
     * @memberof TimeCreateDto
     */
    'employeeId': number;
    /**
     * 
     * @type {number}
     * @memberof TimeCreateDto
     */
    'activityId': number;
    /**
     * 
     * @type {number}
     * @memberof TimeCreateDto
     */
    'projectId': number;
}
/**
 * 
 * @export
 * @interface TimeCreateOwnDto
 */
export interface TimeCreateOwnDto {
    /**
     * 
     * @type {string}
     * @memberof TimeCreateOwnDto
     */
    'start': string;
    /**
     * 
     * @type {string}
     * @memberof TimeCreateOwnDto
     */
    'end'?: string | null;
    /**
     * 
     * @type {number}
     * @memberof TimeCreateOwnDto
     */
    'activityId': number;
    /**
     * 
     * @type {number}
     * @memberof TimeCreateOwnDto
     */
    'projectId': number;
}
/**
 * 
 * @export
 * @interface TimeListDto
 */
export interface TimeListDto {
    /**
     * 
     * @type {number}
     * @memberof TimeListDto
     */
    'id': number;
    /**
     * 
     * @type {string}
     * @memberof TimeListDto
     */
    'start': string;
    /**
     * 
     * @type {string}
     * @memberof TimeListDto
     */
    'end'?: string | null;
    /**
     * 
     * @type {EmployeeOptionDto}
     * @memberof TimeListDto
     */
    'employee': EmployeeOptionDto;
    /**
     * 
     * @type {ActivityOptionDto}
     * @memberof TimeListDto
     */
    'activity': ActivityOptionDto;
    /**
     * 
     * @type {ProjectOptionDto}
     * @memberof TimeListDto
     */
    'project': ProjectOptionDto;
}
/**
 * 
 * @export
 * @interface TimeListDtoListResponse
 */
export interface TimeListDtoListResponse {
    /**
     * 
     * @type {Array<TimeListDto>}
     * @memberof TimeListDtoListResponse
     */
    'data': Array<TimeListDto>;
    /**
     * 
     * @type {Meta}
     * @memberof TimeListDtoListResponse
     */
    'meta': Meta;
}
/**
 * 
 * @export
 * @interface TimeShowDto
 */
export interface TimeShowDto {
    /**
     * 
     * @type {number}
     * @memberof TimeShowDto
     */
    'id': number;
    /**
     * 
     * @type {string}
     * @memberof TimeShowDto
     */
    'start': string;
    /**
     * 
     * @type {string}
     * @memberof TimeShowDto
     */
    'end'?: string | null;
    /**
     * 
     * @type {EmployeeOptionDto}
     * @memberof TimeShowDto
     */
    'employee': EmployeeOptionDto;
    /**
     * 
     * @type {ActivityOptionDto}
     * @memberof TimeShowDto
     */
    'activity': ActivityOptionDto;
    /**
     * 
     * @type {ProjectOptionDto}
     * @memberof TimeShowDto
     */
    'project': ProjectOptionDto;
}
/**
 * 
 * @export
 * @interface TimeShowDtoDataResponse
 */
export interface TimeShowDtoDataResponse {
    /**
     * 
     * @type {TimeShowDto}
     * @memberof TimeShowDtoDataResponse
     */
    'data': TimeShowDto;
    /**
     * 
     * @type {Meta}
     * @memberof TimeShowDtoDataResponse
     */
    'meta': Meta;
}
/**
 * 
 * @export
 * @interface TimeUpdateDto
 */
export interface TimeUpdateDto {
    /**
     * 
     * @type {string}
     * @memberof TimeUpdateDto
     */
    'start': string;
    /**
     * 
     * @type {string}
     * @memberof TimeUpdateDto
     */
    'end'?: string | null;
    /**
     * 
     * @type {number}
     * @memberof TimeUpdateDto
     */
    'employeeId': number;
    /**
     * 
     * @type {number}
     * @memberof TimeUpdateDto
     */
    'activityId': number;
    /**
     * 
     * @type {number}
     * @memberof TimeUpdateDto
     */
    'projectId': number;
}
/**
 * 
 * @export
 * @interface TimeUpdateOwnDto
 */
export interface TimeUpdateOwnDto {
    /**
     * 
     * @type {string}
     * @memberof TimeUpdateOwnDto
     */
    'start': string;
    /**
     * 
     * @type {string}
     * @memberof TimeUpdateOwnDto
     */
    'end'?: string | null;
    /**
     * 
     * @type {number}
     * @memberof TimeUpdateOwnDto
     */
    'activityId': number;
    /**
     * 
     * @type {number}
     * @memberof TimeUpdateOwnDto
     */
    'projectId': number;
}
/**
 * 
 * @export
 * @enum {string}
 */

export const TransactionType = {
    Annualvacation: 'annualvacation',
    Vacationrequest: 'vacationrequest',
    Specialvacation: 'specialvacation',
    Vacationtransfer: 'vacationtransfer',
    Vacationexpiration: 'vacationexpiration'
} as const;

export type TransactionType = typeof TransactionType[keyof typeof TransactionType];


/**
 * 
 * @export
 * @interface UpdateEmployeeVacationEntitlementDto
 */
export interface UpdateEmployeeVacationEntitlementDto {
    /**
     * 
     * @type {string}
     * @memberof UpdateEmployeeVacationEntitlementDto
     */
    'entryDate': string;
    /**
     * 
     * @type {string}
     * @memberof UpdateEmployeeVacationEntitlementDto
     */
    'leaveDate'?: string | null;
    /**
     * 
     * @type {number}
     * @memberof UpdateEmployeeVacationEntitlementDto
     */
    'hoursPerDay': number;
    /**
     * 
     * @type {number}
     * @memberof UpdateEmployeeVacationEntitlementDto
     */
    'yearlyVacationDays': number;
}
/**
 * 
 * @export
 * @interface UserCreateDto
 */
export interface UserCreateDto {
    /**
     * 
     * @type {string}
     * @memberof UserCreateDto
     */
    'email': string;
    /**
     * 
     * @type {string}
     * @memberof UserCreateDto
     */
    'firstName': string;
    /**
     * 
     * @type {string}
     * @memberof UserCreateDto
     */
    'lastName': string;
    /**
     * 
     * @type {Array<number>}
     * @memberof UserCreateDto
     */
    'roleIds': Array<number>;
}
/**
 * 
 * @export
 * @interface UserListDto
 */
export interface UserListDto {
    /**
     * 
     * @type {string}
     * @memberof UserListDto
     */
    'id': string;
    /**
     * 
     * @type {string}
     * @memberof UserListDto
     */
    'firstName': string;
    /**
     * 
     * @type {string}
     * @memberof UserListDto
     */
    'lastName': string;
    /**
     * 
     * @type {string}
     * @memberof UserListDto
     */
    'email': string;
    /**
     * 
     * @type {Array<RoleOptionDto>}
     * @memberof UserListDto
     */
    'roles': Array<RoleOptionDto>;
}
/**
 * 
 * @export
 * @interface UserListDtoListResponse
 */
export interface UserListDtoListResponse {
    /**
     * 
     * @type {Array<UserListDto>}
     * @memberof UserListDtoListResponse
     */
    'data': Array<UserListDto>;
    /**
     * 
     * @type {Meta}
     * @memberof UserListDtoListResponse
     */
    'meta': Meta;
}
/**
 * 
 * @export
 * @interface UserOptionDto
 */
export interface UserOptionDto {
    /**
     * 
     * @type {string}
     * @memberof UserOptionDto
     */
    'id': string;
    /**
     * 
     * @type {string}
     * @memberof UserOptionDto
     */
    'firstName': string;
    /**
     * 
     * @type {string}
     * @memberof UserOptionDto
     */
    'lastName': string;
    /**
     * 
     * @type {string}
     * @memberof UserOptionDto
     */
    'email': string;
}
/**
 * 
 * @export
 * @interface UserOptionDtoListResponse
 */
export interface UserOptionDtoListResponse {
    /**
     * 
     * @type {Array<UserOptionDto>}
     * @memberof UserOptionDtoListResponse
     */
    'data': Array<UserOptionDto>;
    /**
     * 
     * @type {Meta}
     * @memberof UserOptionDtoListResponse
     */
    'meta': Meta;
}
/**
 * 
 * @export
 * @interface UserShowDto
 */
export interface UserShowDto {
    /**
     * 
     * @type {string}
     * @memberof UserShowDto
     */
    'id': string;
    /**
     * 
     * @type {string}
     * @memberof UserShowDto
     */
    'firstName': string;
    /**
     * 
     * @type {string}
     * @memberof UserShowDto
     */
    'lastName': string;
    /**
     * 
     * @type {string}
     * @memberof UserShowDto
     */
    'email': string;
    /**
     * 
     * @type {Array<RoleOptionDto>}
     * @memberof UserShowDto
     */
    'roles': Array<RoleOptionDto>;
}
/**
 * 
 * @export
 * @interface UserShowDtoDataResponse
 */
export interface UserShowDtoDataResponse {
    /**
     * 
     * @type {UserShowDto}
     * @memberof UserShowDtoDataResponse
     */
    'data': UserShowDto;
    /**
     * 
     * @type {Meta}
     * @memberof UserShowDtoDataResponse
     */
    'meta': Meta;
}
/**
 * 
 * @export
 * @interface UserUpdateDto
 */
export interface UserUpdateDto {
    /**
     * 
     * @type {string}
     * @memberof UserUpdateDto
     */
    'email': string;
    /**
     * 
     * @type {string}
     * @memberof UserUpdateDto
     */
    'firstName': string;
    /**
     * 
     * @type {string}
     * @memberof UserUpdateDto
     */
    'lastName': string;
    /**
     * 
     * @type {Array<number>}
     * @memberof UserUpdateDto
     */
    'roleIds': Array<number>;
}
/**
 * 
 * @export
 * @interface VacationCreateDto
 */
export interface VacationCreateDto {
    /**
     * 
     * @type {string}
     * @memberof VacationCreateDto
     */
    'start': string;
    /**
     * 
     * @type {string}
     * @memberof VacationCreateDto
     */
    'end': string;
    /**
     * 
     * @type {number}
     * @memberof VacationCreateDto
     */
    'employeeId': number;
    /**
     * 
     * @type {string}
     * @memberof VacationCreateDto
     */
    'remark'?: string | null;
    /**
     * 
     * @type {boolean}
     * @memberof VacationCreateDto
     */
    'halfDay'?: boolean | null;
    /**
     * 
     * @type {number}
     * @memberof VacationCreateDto
     */
    'standInId'?: number | null;
}
/**
 * 
 * @export
 * @interface VacationCreateOwnDto
 */
export interface VacationCreateOwnDto {
    /**
     * 
     * @type {string}
     * @memberof VacationCreateOwnDto
     */
    'start': string;
    /**
     * 
     * @type {string}
     * @memberof VacationCreateOwnDto
     */
    'end': string;
    /**
     * 
     * @type {string}
     * @memberof VacationCreateOwnDto
     */
    'remark'?: string | null;
    /**
     * 
     * @type {boolean}
     * @memberof VacationCreateOwnDto
     */
    'halfDay'?: boolean | null;
    /**
     * 
     * @type {number}
     * @memberof VacationCreateOwnDto
     */
    'standInId'?: number | null;
}
/**
 * 
 * @export
 * @interface VacationListFilter
 */
export interface VacationListFilter {
    /**
     * 
     * @type {Array<ListVacationsFilterItem>}
     * @memberof VacationListFilter
     */
    'filterItems': Array<ListVacationsFilterItem>;
}
/**
 * 
 * @export
 * @interface VacationShowDto
 */
export interface VacationShowDto {
    /**
     * 
     * @type {number}
     * @memberof VacationShowDto
     */
    'id': number;
    /**
     * 
     * @type {string}
     * @memberof VacationShowDto
     */
    'start': string;
    /**
     * 
     * @type {string}
     * @memberof VacationShowDto
     */
    'end': string;
    /**
     * 
     * @type {string}
     * @memberof VacationShowDto
     */
    'remark'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof VacationShowDto
     */
    'declineReason'?: string | null;
    /**
     * 
     * @type {VacationStatus}
     * @memberof VacationShowDto
     */
    'status': VacationStatus;
    /**
     * 
     * @type {boolean}
     * @memberof VacationShowDto
     */
    'halfDay'?: boolean | null;
    /**
     * 
     * @type {EmployeeWithBalanceDto}
     * @memberof VacationShowDto
     */
    'employee': EmployeeWithBalanceDto;
    /**
     * 
     * @type {EmployeeOptionDto}
     * @memberof VacationShowDto
     */
    'standIn'?: EmployeeOptionDto | null;
    /**
     * 
     * @type {number}
     * @memberof VacationShowDto
     */
    'vacationDayCount': number;
}


/**
 * 
 * @export
 * @interface VacationShowDtoDataResponse
 */
export interface VacationShowDtoDataResponse {
    /**
     * 
     * @type {VacationShowDto}
     * @memberof VacationShowDtoDataResponse
     */
    'data': VacationShowDto;
    /**
     * 
     * @type {Meta}
     * @memberof VacationShowDtoDataResponse
     */
    'meta': Meta;
}
/**
 * 
 * @export
 * @enum {string}
 */

export const VacationStatus = {
    Pending: 'pending',
    Approved: 'approved',
    Declined: 'declined',
    Cancelled: 'cancelled'
} as const;

export type VacationStatus = typeof VacationStatus[keyof typeof VacationStatus];


/**
 * 
 * @export
 * @interface VacationStatusUpdateDto
 */
export interface VacationStatusUpdateDto {
    /**
     * 
     * @type {VacationStatus}
     * @memberof VacationStatusUpdateDto
     */
    'status': VacationStatus;
    /**
     * 
     * @type {string}
     * @memberof VacationStatusUpdateDto
     */
    'declineReason'?: string | null;
}


/**
 * 
 * @export
 * @interface VacationUpdateDto
 */
export interface VacationUpdateDto {
    /**
     * 
     * @type {string}
     * @memberof VacationUpdateDto
     */
    'start': string;
    /**
     * 
     * @type {string}
     * @memberof VacationUpdateDto
     */
    'end': string;
    /**
     * 
     * @type {number}
     * @memberof VacationUpdateDto
     */
    'employeeId': number;
    /**
     * 
     * @type {string}
     * @memberof VacationUpdateDto
     */
    'remark'?: string | null;
    /**
     * 
     * @type {boolean}
     * @memberof VacationUpdateDto
     */
    'halfDay'?: boolean | null;
    /**
     * 
     * @type {number}
     * @memberof VacationUpdateDto
     */
    'standInId'?: number | null;
}
/**
 * 
 * @export
 * @interface VacationUpdateOwnDto
 */
export interface VacationUpdateOwnDto {
    /**
     * 
     * @type {string}
     * @memberof VacationUpdateOwnDto
     */
    'start': string;
    /**
     * 
     * @type {string}
     * @memberof VacationUpdateOwnDto
     */
    'end': string;
    /**
     * 
     * @type {string}
     * @memberof VacationUpdateOwnDto
     */
    'remark'?: string | null;
    /**
     * 
     * @type {boolean}
     * @memberof VacationUpdateOwnDto
     */
    'halfDay'?: boolean | null;
    /**
     * 
     * @type {number}
     * @memberof VacationUpdateOwnDto
     */
    'standInId'?: number | null;
}
/**
 * 
 * @export
 * @interface YearVacation
 */
export interface YearVacation {
    /**
     * 
     * @type {number}
     * @memberof YearVacation
     */
    'days': number;
    /**
     * 
     * @type {string}
     * @memberof YearVacation
     */
    'expirationDate': string;
}

/**
 * APIApi - axios parameter creator
 * @export
 */
export const APIApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {number} id 
         * @param {AddTransactionDto} addTransactionDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        addAccountTransaction: async (id: number, addTransactionDto: AddTransactionDto, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('addAccountTransaction', 'id', id)
            // verify required parameter 'addTransactionDto' is not null or undefined
            assertParamExists('addAccountTransaction', 'addTransactionDto', addTransactionDto)
            const localVarPath = `/api/backend/accounts/{id}/transactions/add`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(addTransactionDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} providerId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiAuthProviderIdAuthenticateGet: async (providerId: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'providerId' is not null or undefined
            assertParamExists('apiAuthProviderIdAuthenticateGet', 'providerId', providerId)
            const localVarPath = `/api/auth/{providerId}/authenticate`
                .replace(`{${"providerId"}}`, encodeURIComponent(String(providerId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} providerId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiAuthProviderIdLoginGet: async (providerId: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'providerId' is not null or undefined
            assertParamExists('apiAuthProviderIdLoginGet', 'providerId', providerId)
            const localVarPath = `/api/auth/{providerId}/login`
                .replace(`{${"providerId"}}`, encodeURIComponent(String(providerId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiAuthProvidersGet: async (options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/auth/providers`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiHealthGet: async (options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/health`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        cancelOwnVacation: async (id: number, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('cancelOwnVacation', 'id', id)
            const localVarPath = `/api/backend/vacations/own/{id}/cancel`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {ActivityCreateDto} activityCreateDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createActivity: async (activityCreateDto: ActivityCreateDto, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'activityCreateDto' is not null or undefined
            assertParamExists('createActivity', 'activityCreateDto', activityCreateDto)
            const localVarPath = `/api/backend/activities`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(activityCreateDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {BranchCreateDto} branchCreateDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createBranch: async (branchCreateDto: BranchCreateDto, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'branchCreateDto' is not null or undefined
            assertParamExists('createBranch', 'branchCreateDto', branchCreateDto)
            const localVarPath = `/api/backend/branches`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(branchCreateDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {EmployeeCreateDto} employeeCreateDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createEmployee: async (employeeCreateDto: EmployeeCreateDto, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'employeeCreateDto' is not null or undefined
            assertParamExists('createEmployee', 'employeeCreateDto', employeeCreateDto)
            const localVarPath = `/api/backend/employees`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(employeeCreateDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {OffDayCreateDto} offDayCreateDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createOffDay: async (offDayCreateDto: OffDayCreateDto, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'offDayCreateDto' is not null or undefined
            assertParamExists('createOffDay', 'offDayCreateDto', offDayCreateDto)
            const localVarPath = `/api/backend/off-days`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(offDayCreateDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {TimeCreateOwnDto} timeCreateOwnDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createOwnTime: async (timeCreateOwnDto: TimeCreateOwnDto, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'timeCreateOwnDto' is not null or undefined
            assertParamExists('createOwnTime', 'timeCreateOwnDto', timeCreateOwnDto)
            const localVarPath = `/api/backend/times/own`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(timeCreateOwnDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {VacationCreateOwnDto} vacationCreateOwnDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createOwnVacation: async (vacationCreateOwnDto: VacationCreateOwnDto, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'vacationCreateOwnDto' is not null or undefined
            assertParamExists('createOwnVacation', 'vacationCreateOwnDto', vacationCreateOwnDto)
            const localVarPath = `/api/backend/vacations/own`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(vacationCreateOwnDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {ProjectCreateDto} projectCreateDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createProject: async (projectCreateDto: ProjectCreateDto, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'projectCreateDto' is not null or undefined
            assertParamExists('createProject', 'projectCreateDto', projectCreateDto)
            const localVarPath = `/api/backend/projects`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(projectCreateDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {RoleCreateDto} roleCreateDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createRole: async (roleCreateDto: RoleCreateDto, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'roleCreateDto' is not null or undefined
            assertParamExists('createRole', 'roleCreateDto', roleCreateDto)
            const localVarPath = `/api/backend/roles`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(roleCreateDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {TeamCreateDto} teamCreateDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createTeam: async (teamCreateDto: TeamCreateDto, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'teamCreateDto' is not null or undefined
            assertParamExists('createTeam', 'teamCreateDto', teamCreateDto)
            const localVarPath = `/api/backend/teams`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(teamCreateDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {TimeCreateDto} timeCreateDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createTime: async (timeCreateDto: TimeCreateDto, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'timeCreateDto' is not null or undefined
            assertParamExists('createTime', 'timeCreateDto', timeCreateDto)
            const localVarPath = `/api/backend/times`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(timeCreateDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {UserCreateDto} userCreateDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createUser: async (userCreateDto: UserCreateDto, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'userCreateDto' is not null or undefined
            assertParamExists('createUser', 'userCreateDto', userCreateDto)
            const localVarPath = `/api/backend/users`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(userCreateDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {VacationCreateDto} vacationCreateDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createVacation: async (vacationCreateDto: VacationCreateDto, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'vacationCreateDto' is not null or undefined
            assertParamExists('createVacation', 'vacationCreateDto', vacationCreateDto)
            const localVarPath = `/api/backend/vacations`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(vacationCreateDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteActivity: async (id: number, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('deleteActivity', 'id', id)
            const localVarPath = `/api/backend/activities/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteBranch: async (id: number, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('deleteBranch', 'id', id)
            const localVarPath = `/api/backend/branches/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteEmployee: async (id: number, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('deleteEmployee', 'id', id)
            const localVarPath = `/api/backend/employees/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteOffDay: async (id: number, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('deleteOffDay', 'id', id)
            const localVarPath = `/api/backend/off-days/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deletePayroll: async (id: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('deletePayroll', 'id', id)
            const localVarPath = `/api/backend/payrolls/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteProject: async (id: number, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('deleteProject', 'id', id)
            const localVarPath = `/api/backend/projects/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteRole: async (id: number, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('deleteRole', 'id', id)
            const localVarPath = `/api/backend/roles/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteTeam: async (id: number, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('deleteTeam', 'id', id)
            const localVarPath = `/api/backend/teams/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteTime: async (id: number, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('deleteTime', 'id', id)
            const localVarPath = `/api/backend/times/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteUser: async (id: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('deleteUser', 'id', id)
            const localVarPath = `/api/backend/users/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteVacation: async (id: number, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('deleteVacation', 'id', id)
            const localVarPath = `/api/backend/vacations/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        downloadOwnPayroll: async (id: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('downloadOwnPayroll', 'id', id)
            const localVarPath = `/api/backend/payrolls/{id}/own`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        downloadPayroll: async (id: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('downloadPayroll', 'id', id)
            const localVarPath = `/api/backend/payrolls/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} accountId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAccountTransactions: async (accountId: number, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'accountId' is not null or undefined
            assertParamExists('getAccountTransactions', 'accountId', accountId)
            const localVarPath = `/api/backend/accounts/{accountId}/transactions`
                .replace(`{${"accountId"}}`, encodeURIComponent(String(accountId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getOwn: async (options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/backend/permissions/own`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        listActivities: async (options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/backend/activities`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} search 
         * @param {number} page 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        listActivityOptions: async (search: string, page: number, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'search' is not null or undefined
            assertParamExists('listActivityOptions', 'search', search)
            // verify required parameter 'page' is not null or undefined
            assertParamExists('listActivityOptions', 'page', page)
            const localVarPath = `/api/backend/activities/options`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (search !== undefined) {
                localVarQueryParameter['search'] = search;
            }

            if (page !== undefined) {
                localVarQueryParameter['page'] = page;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        listAll: async (options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/backend/permissions`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} search 
         * @param {number} page 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        listBranchOptions: async (search: string, page: number, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'search' is not null or undefined
            assertParamExists('listBranchOptions', 'search', search)
            // verify required parameter 'page' is not null or undefined
            assertParamExists('listBranchOptions', 'page', page)
            const localVarPath = `/api/backend/branches/options`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (search !== undefined) {
                localVarQueryParameter['search'] = search;
            }

            if (page !== undefined) {
                localVarQueryParameter['page'] = page;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        listBranches: async (options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/backend/branches`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} search 
         * @param {number} page 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        listCountryOptions: async (search: string, page: number, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'search' is not null or undefined
            assertParamExists('listCountryOptions', 'search', search)
            // verify required parameter 'page' is not null or undefined
            assertParamExists('listCountryOptions', 'page', page)
            const localVarPath = `/api/backend/countries/options`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (search !== undefined) {
                localVarQueryParameter['search'] = search;
            }

            if (page !== undefined) {
                localVarQueryParameter['page'] = page;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} search 
         * @param {number} page 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        listEmployeeOptions: async (search: string, page: number, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'search' is not null or undefined
            assertParamExists('listEmployeeOptions', 'search', search)
            // verify required parameter 'page' is not null or undefined
            assertParamExists('listEmployeeOptions', 'page', page)
            const localVarPath = `/api/backend/employees/options`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (search !== undefined) {
                localVarQueryParameter['search'] = search;
            }

            if (page !== undefined) {
                localVarQueryParameter['page'] = page;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        listEmployeeTimes: async (id: number, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('listEmployeeTimes', 'id', id)
            const localVarPath = `/api/backend/employees/{id}/times`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        listEmployeeVacations: async (id: number, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('listEmployeeVacations', 'id', id)
            const localVarPath = `/api/backend/employees/{id}/vacations`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        listEmployees: async (options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/backend/employees`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        listOffDays: async (options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/backend/off-days`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        listOwnPayrolls: async (options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/backend/payrolls/own`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        listOwnTimes: async (options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/backend/times/own`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        listOwnVacations: async (options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/backend/vacations/own`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {PayrollsListFilter} [payrollsListFilter] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        listPayrolls: async (payrollsListFilter?: PayrollsListFilter, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/backend/payrolls/list`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(payrollsListFilter, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} search 
         * @param {number} page 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        listProjectOptions: async (search: string, page: number, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'search' is not null or undefined
            assertParamExists('listProjectOptions', 'search', search)
            // verify required parameter 'page' is not null or undefined
            assertParamExists('listProjectOptions', 'page', page)
            const localVarPath = `/api/backend/projects/options`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (search !== undefined) {
                localVarQueryParameter['search'] = search;
            }

            if (page !== undefined) {
                localVarQueryParameter['page'] = page;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        listProjects: async (options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/backend/projects`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} search 
         * @param {number} page 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        listRoleOptions: async (search: string, page: number, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'search' is not null or undefined
            assertParamExists('listRoleOptions', 'search', search)
            // verify required parameter 'page' is not null or undefined
            assertParamExists('listRoleOptions', 'page', page)
            const localVarPath = `/api/backend/roles/options`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (search !== undefined) {
                localVarQueryParameter['search'] = search;
            }

            if (page !== undefined) {
                localVarQueryParameter['page'] = page;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        listRoles: async (options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/backend/roles`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} search 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        listStateOptions: async (search: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'search' is not null or undefined
            assertParamExists('listStateOptions', 'search', search)
            const localVarPath = `/api/backend/states/options`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (search !== undefined) {
                localVarQueryParameter['search'] = search;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} search 
         * @param {number} page 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        listTeamOptions: async (search: string, page: number, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'search' is not null or undefined
            assertParamExists('listTeamOptions', 'search', search)
            // verify required parameter 'page' is not null or undefined
            assertParamExists('listTeamOptions', 'page', page)
            const localVarPath = `/api/backend/teams/options`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (search !== undefined) {
                localVarQueryParameter['search'] = search;
            }

            if (page !== undefined) {
                localVarQueryParameter['page'] = page;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        listTeams: async (options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/backend/teams`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        listTimes: async (options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/backend/times`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} search 
         * @param {number} page 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        listUserOptions: async (search: string, page: number, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'search' is not null or undefined
            assertParamExists('listUserOptions', 'search', search)
            // verify required parameter 'page' is not null or undefined
            assertParamExists('listUserOptions', 'page', page)
            const localVarPath = `/api/backend/users/options`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (search !== undefined) {
                localVarQueryParameter['search'] = search;
            }

            if (page !== undefined) {
                localVarQueryParameter['page'] = page;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        listUsers: async (options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/backend/users`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {VacationListFilter} [vacationListFilter] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        listVacations: async (vacationListFilter?: VacationListFilter, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/backend/vacations/list`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(vacationListFilter, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {Request} request 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        login: async (request: Request, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'request' is not null or undefined
            assertParamExists('login', 'request', request)
            const localVarPath = `/api/auth/login`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(request, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        logout: async (options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/auth/logout`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        refresh: async (options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/auth/refresh`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        showActivity: async (id: number, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('showActivity', 'id', id)
            const localVarPath = `/api/backend/activities/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        showBranch: async (id: number, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('showBranch', 'id', id)
            const localVarPath = `/api/backend/branches/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        showConfig: async (options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/backend/config`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        showEmployee: async (id: number, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('showEmployee', 'id', id)
            const localVarPath = `/api/backend/employees/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        showEmployeeDashboard: async (options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/backend/employee-dashboard`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} id 
         * @param {string} dateFrom 
         * @param {string} dateTo 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        showEmployeeOverview: async (id: number, dateFrom: string, dateTo: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('showEmployeeOverview', 'id', id)
            // verify required parameter 'dateFrom' is not null or undefined
            assertParamExists('showEmployeeOverview', 'dateFrom', dateFrom)
            // verify required parameter 'dateTo' is not null or undefined
            assertParamExists('showEmployeeOverview', 'dateTo', dateTo)
            const localVarPath = `/api/backend/employees/{id}/overview`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (dateFrom !== undefined) {
                localVarQueryParameter['dateFrom'] = (dateFrom as any instanceof Date) ?
                    (dateFrom as any).toISOString().substring(0,10) :
                    dateFrom;
            }

            if (dateTo !== undefined) {
                localVarQueryParameter['dateTo'] = (dateTo as any instanceof Date) ?
                    (dateTo as any).toISOString().substring(0,10) :
                    dateTo;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        showOffDay: async (id: number, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('showOffDay', 'id', id)
            const localVarPath = `/api/backend/off-days/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        showProject: async (id: number, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('showProject', 'id', id)
            const localVarPath = `/api/backend/projects/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        showRole: async (id: number, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('showRole', 'id', id)
            const localVarPath = `/api/backend/roles/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        showTeam: async (id: number, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('showTeam', 'id', id)
            const localVarPath = `/api/backend/teams/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        showTime: async (id: number, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('showTime', 'id', id)
            const localVarPath = `/api/backend/times/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        showUser: async (id: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('showUser', 'id', id)
            const localVarPath = `/api/backend/users/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        showVacation: async (id: number, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('showVacation', 'id', id)
            const localVarPath = `/api/backend/vacations/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} id 
         * @param {SubtractTransactionDto} subtractTransactionDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        subtractAccountTransaction: async (id: number, subtractTransactionDto: SubtractTransactionDto, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('subtractAccountTransaction', 'id', id)
            // verify required parameter 'subtractTransactionDto' is not null or undefined
            assertParamExists('subtractAccountTransaction', 'subtractTransactionDto', subtractTransactionDto)
            const localVarPath = `/api/backend/accounts/{id}/transactions/subtract`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(subtractTransactionDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} id 
         * @param {ActivityUpdateDto} activityUpdateDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateActivity: async (id: number, activityUpdateDto: ActivityUpdateDto, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('updateActivity', 'id', id)
            // verify required parameter 'activityUpdateDto' is not null or undefined
            assertParamExists('updateActivity', 'activityUpdateDto', activityUpdateDto)
            const localVarPath = `/api/backend/activities/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(activityUpdateDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} id 
         * @param {BranchUpdateDto} branchUpdateDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateBranch: async (id: number, branchUpdateDto: BranchUpdateDto, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('updateBranch', 'id', id)
            // verify required parameter 'branchUpdateDto' is not null or undefined
            assertParamExists('updateBranch', 'branchUpdateDto', branchUpdateDto)
            const localVarPath = `/api/backend/branches/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(branchUpdateDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} id 
         * @param {EmployeeUpdateDto} employeeUpdateDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateEmployee: async (id: number, employeeUpdateDto: EmployeeUpdateDto, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('updateEmployee', 'id', id)
            // verify required parameter 'employeeUpdateDto' is not null or undefined
            assertParamExists('updateEmployee', 'employeeUpdateDto', employeeUpdateDto)
            const localVarPath = `/api/backend/employees/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(employeeUpdateDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} id 
         * @param {UpdateEmployeeVacationEntitlementDto} updateEmployeeVacationEntitlementDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateEmployeeVacationEntitlement: async (id: number, updateEmployeeVacationEntitlementDto: UpdateEmployeeVacationEntitlementDto, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('updateEmployeeVacationEntitlement', 'id', id)
            // verify required parameter 'updateEmployeeVacationEntitlementDto' is not null or undefined
            assertParamExists('updateEmployeeVacationEntitlement', 'updateEmployeeVacationEntitlementDto', updateEmployeeVacationEntitlementDto)
            const localVarPath = `/api/backend/employees/{id}/vacation-entitlement`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(updateEmployeeVacationEntitlementDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} id 
         * @param {OffDayUpdateDto} offDayUpdateDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateOffDay: async (id: number, offDayUpdateDto: OffDayUpdateDto, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('updateOffDay', 'id', id)
            // verify required parameter 'offDayUpdateDto' is not null or undefined
            assertParamExists('updateOffDay', 'offDayUpdateDto', offDayUpdateDto)
            const localVarPath = `/api/backend/off-days/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(offDayUpdateDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} id 
         * @param {TimeUpdateOwnDto} timeUpdateOwnDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateOwnTime: async (id: number, timeUpdateOwnDto: TimeUpdateOwnDto, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('updateOwnTime', 'id', id)
            // verify required parameter 'timeUpdateOwnDto' is not null or undefined
            assertParamExists('updateOwnTime', 'timeUpdateOwnDto', timeUpdateOwnDto)
            const localVarPath = `/api/backend/times/own/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(timeUpdateOwnDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} id 
         * @param {VacationUpdateOwnDto} vacationUpdateOwnDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateOwnVacation: async (id: number, vacationUpdateOwnDto: VacationUpdateOwnDto, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('updateOwnVacation', 'id', id)
            // verify required parameter 'vacationUpdateOwnDto' is not null or undefined
            assertParamExists('updateOwnVacation', 'vacationUpdateOwnDto', vacationUpdateOwnDto)
            const localVarPath = `/api/backend/vacations/own/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(vacationUpdateOwnDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} id 
         * @param {ProjectUpdateDto} projectUpdateDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateProject: async (id: number, projectUpdateDto: ProjectUpdateDto, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('updateProject', 'id', id)
            // verify required parameter 'projectUpdateDto' is not null or undefined
            assertParamExists('updateProject', 'projectUpdateDto', projectUpdateDto)
            const localVarPath = `/api/backend/projects/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(projectUpdateDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} id 
         * @param {RoleUpdateDto} roleUpdateDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateRole: async (id: number, roleUpdateDto: RoleUpdateDto, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('updateRole', 'id', id)
            // verify required parameter 'roleUpdateDto' is not null or undefined
            assertParamExists('updateRole', 'roleUpdateDto', roleUpdateDto)
            const localVarPath = `/api/backend/roles/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(roleUpdateDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} id 
         * @param {TeamUpdateDto} teamUpdateDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateTeam: async (id: number, teamUpdateDto: TeamUpdateDto, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('updateTeam', 'id', id)
            // verify required parameter 'teamUpdateDto' is not null or undefined
            assertParamExists('updateTeam', 'teamUpdateDto', teamUpdateDto)
            const localVarPath = `/api/backend/teams/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(teamUpdateDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} id 
         * @param {TimeUpdateDto} timeUpdateDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateTime: async (id: number, timeUpdateDto: TimeUpdateDto, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('updateTime', 'id', id)
            // verify required parameter 'timeUpdateDto' is not null or undefined
            assertParamExists('updateTime', 'timeUpdateDto', timeUpdateDto)
            const localVarPath = `/api/backend/times/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(timeUpdateDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} id 
         * @param {UserUpdateDto} userUpdateDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateUser: async (id: string, userUpdateDto: UserUpdateDto, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('updateUser', 'id', id)
            // verify required parameter 'userUpdateDto' is not null or undefined
            assertParamExists('updateUser', 'userUpdateDto', userUpdateDto)
            const localVarPath = `/api/backend/users/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(userUpdateDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} id 
         * @param {VacationUpdateDto} vacationUpdateDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateVacation: async (id: number, vacationUpdateDto: VacationUpdateDto, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('updateVacation', 'id', id)
            // verify required parameter 'vacationUpdateDto' is not null or undefined
            assertParamExists('updateVacation', 'vacationUpdateDto', vacationUpdateDto)
            const localVarPath = `/api/backend/vacations/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(vacationUpdateDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} id 
         * @param {VacationStatusUpdateDto} vacationStatusUpdateDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateVacationStatus: async (id: number, vacationStatusUpdateDto: VacationStatusUpdateDto, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('updateVacationStatus', 'id', id)
            // verify required parameter 'vacationStatusUpdateDto' is not null or undefined
            assertParamExists('updateVacationStatus', 'vacationStatusUpdateDto', vacationStatusUpdateDto)
            const localVarPath = `/api/backend/vacations/{id}/status`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(vacationStatusUpdateDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {Array<File>} files 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        uploadPayrolls: async (files: Array<File>, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'files' is not null or undefined
            assertParamExists('uploadPayrolls', 'files', files)
            const localVarPath = `/api/backend/payrolls`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;
            const localVarFormParams = new ((configuration && configuration.formDataCtor) || FormData)();

            if (files) {
                files.forEach((element) => {
                    localVarFormParams.append('files', element as any);
                })
            }

    
    
            localVarHeaderParameter['Content-Type'] = 'multipart/form-data';
    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = localVarFormParams;

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * APIApi - functional programming interface
 * @export
 */
export const APIApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = APIApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {number} id 
         * @param {AddTransactionDto} addTransactionDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async addAccountTransaction(id: number, addTransactionDto: AddTransactionDto, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.addAccountTransaction(id, addTransactionDto, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['APIApi.addAccountTransaction']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {string} providerId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiAuthProviderIdAuthenticateGet(providerId: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiAuthProviderIdAuthenticateGet(providerId, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['APIApi.apiAuthProviderIdAuthenticateGet']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {string} providerId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiAuthProviderIdLoginGet(providerId: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiAuthProviderIdLoginGet(providerId, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['APIApi.apiAuthProviderIdLoginGet']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiAuthProvidersGet(options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<IdentityProviderDtoListDataResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiAuthProvidersGet(options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['APIApi.apiAuthProvidersGet']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiHealthGet(options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiHealthGet(options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['APIApi.apiHealthGet']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async cancelOwnVacation(id: number, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.cancelOwnVacation(id, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['APIApi.cancelOwnVacation']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {ActivityCreateDto} activityCreateDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async createActivity(activityCreateDto: ActivityCreateDto, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Int32CreatedResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.createActivity(activityCreateDto, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['APIApi.createActivity']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {BranchCreateDto} branchCreateDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async createBranch(branchCreateDto: BranchCreateDto, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Int32CreatedResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.createBranch(branchCreateDto, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['APIApi.createBranch']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {EmployeeCreateDto} employeeCreateDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async createEmployee(employeeCreateDto: EmployeeCreateDto, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Int32CreatedResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.createEmployee(employeeCreateDto, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['APIApi.createEmployee']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {OffDayCreateDto} offDayCreateDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async createOffDay(offDayCreateDto: OffDayCreateDto, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Int32CreatedResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.createOffDay(offDayCreateDto, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['APIApi.createOffDay']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {TimeCreateOwnDto} timeCreateOwnDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async createOwnTime(timeCreateOwnDto: TimeCreateOwnDto, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Int32CreatedResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.createOwnTime(timeCreateOwnDto, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['APIApi.createOwnTime']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {VacationCreateOwnDto} vacationCreateOwnDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async createOwnVacation(vacationCreateOwnDto: VacationCreateOwnDto, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Int32CreatedResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.createOwnVacation(vacationCreateOwnDto, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['APIApi.createOwnVacation']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {ProjectCreateDto} projectCreateDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async createProject(projectCreateDto: ProjectCreateDto, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Int32CreatedResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.createProject(projectCreateDto, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['APIApi.createProject']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {RoleCreateDto} roleCreateDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async createRole(roleCreateDto: RoleCreateDto, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Int32CreatedResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.createRole(roleCreateDto, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['APIApi.createRole']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {TeamCreateDto} teamCreateDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async createTeam(teamCreateDto: TeamCreateDto, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Int32CreatedResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.createTeam(teamCreateDto, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['APIApi.createTeam']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {TimeCreateDto} timeCreateDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async createTime(timeCreateDto: TimeCreateDto, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Int32CreatedResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.createTime(timeCreateDto, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['APIApi.createTime']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {UserCreateDto} userCreateDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async createUser(userCreateDto: UserCreateDto, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Int32CreatedResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.createUser(userCreateDto, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['APIApi.createUser']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {VacationCreateDto} vacationCreateDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async createVacation(vacationCreateDto: VacationCreateDto, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Int32CreatedResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.createVacation(vacationCreateDto, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['APIApi.createVacation']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async deleteActivity(id: number, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.deleteActivity(id, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['APIApi.deleteActivity']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async deleteBranch(id: number, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.deleteBranch(id, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['APIApi.deleteBranch']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async deleteEmployee(id: number, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.deleteEmployee(id, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['APIApi.deleteEmployee']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async deleteOffDay(id: number, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.deleteOffDay(id, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['APIApi.deleteOffDay']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async deletePayroll(id: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.deletePayroll(id, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['APIApi.deletePayroll']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async deleteProject(id: number, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.deleteProject(id, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['APIApi.deleteProject']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async deleteRole(id: number, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.deleteRole(id, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['APIApi.deleteRole']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async deleteTeam(id: number, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.deleteTeam(id, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['APIApi.deleteTeam']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async deleteTime(id: number, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.deleteTime(id, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['APIApi.deleteTime']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async deleteUser(id: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.deleteUser(id, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['APIApi.deleteUser']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async deleteVacation(id: number, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.deleteVacation(id, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['APIApi.deleteVacation']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async downloadOwnPayroll(id: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.downloadOwnPayroll(id, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['APIApi.downloadOwnPayroll']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async downloadPayroll(id: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.downloadPayroll(id, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['APIApi.downloadPayroll']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {number} accountId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getAccountTransactions(accountId: number, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<AccountTransactionDtoListResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getAccountTransactions(accountId, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['APIApi.getAccountTransactions']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getOwn(options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getOwn(options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['APIApi.getOwn']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async listActivities(options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ActivityListDtoListResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.listActivities(options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['APIApi.listActivities']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {string} search 
         * @param {number} page 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async listActivityOptions(search: string, page: number, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ActivityOptionDtoListResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.listActivityOptions(search, page, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['APIApi.listActivityOptions']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async listAll(options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.listAll(options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['APIApi.listAll']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {string} search 
         * @param {number} page 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async listBranchOptions(search: string, page: number, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<BranchOptionDtoListResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.listBranchOptions(search, page, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['APIApi.listBranchOptions']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async listBranches(options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<BranchListDtoListResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.listBranches(options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['APIApi.listBranches']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {string} search 
         * @param {number} page 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async listCountryOptions(search: string, page: number, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<CountryOptionDtoListResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.listCountryOptions(search, page, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['APIApi.listCountryOptions']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {string} search 
         * @param {number} page 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async listEmployeeOptions(search: string, page: number, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<EmployeeOptionDtoListResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.listEmployeeOptions(search, page, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['APIApi.listEmployeeOptions']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async listEmployeeTimes(id: number, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<EmployeeTimeListDtoListResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.listEmployeeTimes(id, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['APIApi.listEmployeeTimes']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async listEmployeeVacations(id: number, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<EmployeeListVacationDtoListResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.listEmployeeVacations(id, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['APIApi.listEmployeeVacations']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async listEmployees(options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<EmployeeListDtoListResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.listEmployees(options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['APIApi.listEmployees']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async listOffDays(options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<OffDayListDtoListResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.listOffDays(options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['APIApi.listOffDays']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async listOwnPayrolls(options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ListOwnPayrollDtoListResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.listOwnPayrolls(options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['APIApi.listOwnPayrolls']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async listOwnTimes(options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<OwnTimeListDtoListResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.listOwnTimes(options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['APIApi.listOwnTimes']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async listOwnVacations(options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<OwnVacationDtoListResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.listOwnVacations(options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['APIApi.listOwnVacations']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {PayrollsListFilter} [payrollsListFilter] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async listPayrolls(payrollsListFilter?: PayrollsListFilter, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ListPayrollDtoListResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.listPayrolls(payrollsListFilter, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['APIApi.listPayrolls']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {string} search 
         * @param {number} page 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async listProjectOptions(search: string, page: number, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ProjectOptionDtoListResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.listProjectOptions(search, page, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['APIApi.listProjectOptions']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async listProjects(options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ProjectListDtoListResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.listProjects(options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['APIApi.listProjects']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {string} search 
         * @param {number} page 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async listRoleOptions(search: string, page: number, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<RoleOptionDtoListResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.listRoleOptions(search, page, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['APIApi.listRoleOptions']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async listRoles(options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<RoleListDtoListResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.listRoles(options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['APIApi.listRoles']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {string} search 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async listStateOptions(search: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<StatesListResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.listStateOptions(search, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['APIApi.listStateOptions']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {string} search 
         * @param {number} page 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async listTeamOptions(search: string, page: number, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<TeamOptionDtoListResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.listTeamOptions(search, page, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['APIApi.listTeamOptions']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async listTeams(options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<TeamListDtoListResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.listTeams(options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['APIApi.listTeams']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async listTimes(options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<TimeListDtoListResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.listTimes(options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['APIApi.listTimes']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {string} search 
         * @param {number} page 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async listUserOptions(search: string, page: number, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<UserOptionDtoListResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.listUserOptions(search, page, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['APIApi.listUserOptions']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async listUsers(options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<UserListDtoListResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.listUsers(options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['APIApi.listUsers']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {VacationListFilter} [vacationListFilter] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async listVacations(vacationListFilter?: VacationListFilter, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ListVacationDtoListResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.listVacations(vacationListFilter, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['APIApi.listVacations']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {Request} request 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async login(request: Request, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.login(request, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['APIApi.login']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async logout(options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.logout(options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['APIApi.logout']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async refresh(options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.refresh(options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['APIApi.refresh']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async showActivity(id: number, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ActivityShowDtoDataResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.showActivity(id, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['APIApi.showActivity']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async showBranch(id: number, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<BranchShowDtoDataResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.showBranch(id, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['APIApi.showBranch']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async showConfig(options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ConfigDtoDataResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.showConfig(options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['APIApi.showConfig']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async showEmployee(id: number, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<EmployeeShowDtoDataResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.showEmployee(id, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['APIApi.showEmployee']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async showEmployeeDashboard(options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<EmployeeDashboardDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.showEmployeeDashboard(options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['APIApi.showEmployeeDashboard']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {number} id 
         * @param {string} dateFrom 
         * @param {string} dateTo 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async showEmployeeOverview(id: number, dateFrom: string, dateTo: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<EmployeeOverviewDtoDataResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.showEmployeeOverview(id, dateFrom, dateTo, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['APIApi.showEmployeeOverview']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async showOffDay(id: number, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<OffDayShowDtoDataResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.showOffDay(id, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['APIApi.showOffDay']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async showProject(id: number, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ProjectShowDtoDataResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.showProject(id, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['APIApi.showProject']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async showRole(id: number, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<RoleShowDtoDataResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.showRole(id, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['APIApi.showRole']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async showTeam(id: number, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<TeamShowDtoDataResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.showTeam(id, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['APIApi.showTeam']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async showTime(id: number, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<TimeShowDtoDataResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.showTime(id, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['APIApi.showTime']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async showUser(id: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<UserShowDtoDataResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.showUser(id, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['APIApi.showUser']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async showVacation(id: number, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<VacationShowDtoDataResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.showVacation(id, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['APIApi.showVacation']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {number} id 
         * @param {SubtractTransactionDto} subtractTransactionDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async subtractAccountTransaction(id: number, subtractTransactionDto: SubtractTransactionDto, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.subtractAccountTransaction(id, subtractTransactionDto, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['APIApi.subtractAccountTransaction']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {number} id 
         * @param {ActivityUpdateDto} activityUpdateDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async updateActivity(id: number, activityUpdateDto: ActivityUpdateDto, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.updateActivity(id, activityUpdateDto, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['APIApi.updateActivity']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {number} id 
         * @param {BranchUpdateDto} branchUpdateDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async updateBranch(id: number, branchUpdateDto: BranchUpdateDto, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.updateBranch(id, branchUpdateDto, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['APIApi.updateBranch']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {number} id 
         * @param {EmployeeUpdateDto} employeeUpdateDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async updateEmployee(id: number, employeeUpdateDto: EmployeeUpdateDto, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.updateEmployee(id, employeeUpdateDto, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['APIApi.updateEmployee']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {number} id 
         * @param {UpdateEmployeeVacationEntitlementDto} updateEmployeeVacationEntitlementDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async updateEmployeeVacationEntitlement(id: number, updateEmployeeVacationEntitlementDto: UpdateEmployeeVacationEntitlementDto, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.updateEmployeeVacationEntitlement(id, updateEmployeeVacationEntitlementDto, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['APIApi.updateEmployeeVacationEntitlement']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {number} id 
         * @param {OffDayUpdateDto} offDayUpdateDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async updateOffDay(id: number, offDayUpdateDto: OffDayUpdateDto, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.updateOffDay(id, offDayUpdateDto, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['APIApi.updateOffDay']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {number} id 
         * @param {TimeUpdateOwnDto} timeUpdateOwnDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async updateOwnTime(id: number, timeUpdateOwnDto: TimeUpdateOwnDto, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.updateOwnTime(id, timeUpdateOwnDto, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['APIApi.updateOwnTime']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {number} id 
         * @param {VacationUpdateOwnDto} vacationUpdateOwnDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async updateOwnVacation(id: number, vacationUpdateOwnDto: VacationUpdateOwnDto, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.updateOwnVacation(id, vacationUpdateOwnDto, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['APIApi.updateOwnVacation']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {number} id 
         * @param {ProjectUpdateDto} projectUpdateDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async updateProject(id: number, projectUpdateDto: ProjectUpdateDto, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.updateProject(id, projectUpdateDto, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['APIApi.updateProject']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {number} id 
         * @param {RoleUpdateDto} roleUpdateDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async updateRole(id: number, roleUpdateDto: RoleUpdateDto, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.updateRole(id, roleUpdateDto, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['APIApi.updateRole']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {number} id 
         * @param {TeamUpdateDto} teamUpdateDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async updateTeam(id: number, teamUpdateDto: TeamUpdateDto, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.updateTeam(id, teamUpdateDto, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['APIApi.updateTeam']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {number} id 
         * @param {TimeUpdateDto} timeUpdateDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async updateTime(id: number, timeUpdateDto: TimeUpdateDto, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.updateTime(id, timeUpdateDto, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['APIApi.updateTime']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {string} id 
         * @param {UserUpdateDto} userUpdateDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async updateUser(id: string, userUpdateDto: UserUpdateDto, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.updateUser(id, userUpdateDto, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['APIApi.updateUser']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {number} id 
         * @param {VacationUpdateDto} vacationUpdateDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async updateVacation(id: number, vacationUpdateDto: VacationUpdateDto, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.updateVacation(id, vacationUpdateDto, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['APIApi.updateVacation']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {number} id 
         * @param {VacationStatusUpdateDto} vacationStatusUpdateDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async updateVacationStatus(id: number, vacationStatusUpdateDto: VacationStatusUpdateDto, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.updateVacationStatus(id, vacationStatusUpdateDto, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['APIApi.updateVacationStatus']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {Array<File>} files 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async uploadPayrolls(files: Array<File>, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.uploadPayrolls(files, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['APIApi.uploadPayrolls']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
    }
};

/**
 * APIApi - factory interface
 * @export
 */
export const APIApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = APIApiFp(configuration)
    return {
        /**
         * 
         * @param {number} id 
         * @param {AddTransactionDto} addTransactionDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        addAccountTransaction(id: number, addTransactionDto: AddTransactionDto, options?: any): AxiosPromise<void> {
            return localVarFp.addAccountTransaction(id, addTransactionDto, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} providerId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiAuthProviderIdAuthenticateGet(providerId: string, options?: any): AxiosPromise<void> {
            return localVarFp.apiAuthProviderIdAuthenticateGet(providerId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} providerId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiAuthProviderIdLoginGet(providerId: string, options?: any): AxiosPromise<void> {
            return localVarFp.apiAuthProviderIdLoginGet(providerId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiAuthProvidersGet(options?: any): AxiosPromise<IdentityProviderDtoListDataResponse> {
            return localVarFp.apiAuthProvidersGet(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiHealthGet(options?: any): AxiosPromise<void> {
            return localVarFp.apiHealthGet(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        cancelOwnVacation(id: number, options?: any): AxiosPromise<void> {
            return localVarFp.cancelOwnVacation(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {ActivityCreateDto} activityCreateDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createActivity(activityCreateDto: ActivityCreateDto, options?: any): AxiosPromise<Int32CreatedResponse> {
            return localVarFp.createActivity(activityCreateDto, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {BranchCreateDto} branchCreateDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createBranch(branchCreateDto: BranchCreateDto, options?: any): AxiosPromise<Int32CreatedResponse> {
            return localVarFp.createBranch(branchCreateDto, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {EmployeeCreateDto} employeeCreateDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createEmployee(employeeCreateDto: EmployeeCreateDto, options?: any): AxiosPromise<Int32CreatedResponse> {
            return localVarFp.createEmployee(employeeCreateDto, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {OffDayCreateDto} offDayCreateDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createOffDay(offDayCreateDto: OffDayCreateDto, options?: any): AxiosPromise<Int32CreatedResponse> {
            return localVarFp.createOffDay(offDayCreateDto, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {TimeCreateOwnDto} timeCreateOwnDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createOwnTime(timeCreateOwnDto: TimeCreateOwnDto, options?: any): AxiosPromise<Int32CreatedResponse> {
            return localVarFp.createOwnTime(timeCreateOwnDto, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {VacationCreateOwnDto} vacationCreateOwnDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createOwnVacation(vacationCreateOwnDto: VacationCreateOwnDto, options?: any): AxiosPromise<Int32CreatedResponse> {
            return localVarFp.createOwnVacation(vacationCreateOwnDto, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {ProjectCreateDto} projectCreateDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createProject(projectCreateDto: ProjectCreateDto, options?: any): AxiosPromise<Int32CreatedResponse> {
            return localVarFp.createProject(projectCreateDto, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {RoleCreateDto} roleCreateDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createRole(roleCreateDto: RoleCreateDto, options?: any): AxiosPromise<Int32CreatedResponse> {
            return localVarFp.createRole(roleCreateDto, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {TeamCreateDto} teamCreateDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createTeam(teamCreateDto: TeamCreateDto, options?: any): AxiosPromise<Int32CreatedResponse> {
            return localVarFp.createTeam(teamCreateDto, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {TimeCreateDto} timeCreateDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createTime(timeCreateDto: TimeCreateDto, options?: any): AxiosPromise<Int32CreatedResponse> {
            return localVarFp.createTime(timeCreateDto, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {UserCreateDto} userCreateDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createUser(userCreateDto: UserCreateDto, options?: any): AxiosPromise<Int32CreatedResponse> {
            return localVarFp.createUser(userCreateDto, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {VacationCreateDto} vacationCreateDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createVacation(vacationCreateDto: VacationCreateDto, options?: any): AxiosPromise<Int32CreatedResponse> {
            return localVarFp.createVacation(vacationCreateDto, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteActivity(id: number, options?: any): AxiosPromise<void> {
            return localVarFp.deleteActivity(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteBranch(id: number, options?: any): AxiosPromise<void> {
            return localVarFp.deleteBranch(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteEmployee(id: number, options?: any): AxiosPromise<void> {
            return localVarFp.deleteEmployee(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteOffDay(id: number, options?: any): AxiosPromise<void> {
            return localVarFp.deleteOffDay(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deletePayroll(id: string, options?: any): AxiosPromise<void> {
            return localVarFp.deletePayroll(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteProject(id: number, options?: any): AxiosPromise<void> {
            return localVarFp.deleteProject(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteRole(id: number, options?: any): AxiosPromise<void> {
            return localVarFp.deleteRole(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteTeam(id: number, options?: any): AxiosPromise<void> {
            return localVarFp.deleteTeam(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteTime(id: number, options?: any): AxiosPromise<void> {
            return localVarFp.deleteTime(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteUser(id: string, options?: any): AxiosPromise<void> {
            return localVarFp.deleteUser(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteVacation(id: number, options?: any): AxiosPromise<void> {
            return localVarFp.deleteVacation(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        downloadOwnPayroll(id: string, options?: any): AxiosPromise<void> {
            return localVarFp.downloadOwnPayroll(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        downloadPayroll(id: string, options?: any): AxiosPromise<void> {
            return localVarFp.downloadPayroll(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} accountId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAccountTransactions(accountId: number, options?: any): AxiosPromise<AccountTransactionDtoListResponse> {
            return localVarFp.getAccountTransactions(accountId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getOwn(options?: any): AxiosPromise<void> {
            return localVarFp.getOwn(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        listActivities(options?: any): AxiosPromise<ActivityListDtoListResponse> {
            return localVarFp.listActivities(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} search 
         * @param {number} page 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        listActivityOptions(search: string, page: number, options?: any): AxiosPromise<ActivityOptionDtoListResponse> {
            return localVarFp.listActivityOptions(search, page, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        listAll(options?: any): AxiosPromise<void> {
            return localVarFp.listAll(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} search 
         * @param {number} page 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        listBranchOptions(search: string, page: number, options?: any): AxiosPromise<BranchOptionDtoListResponse> {
            return localVarFp.listBranchOptions(search, page, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        listBranches(options?: any): AxiosPromise<BranchListDtoListResponse> {
            return localVarFp.listBranches(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} search 
         * @param {number} page 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        listCountryOptions(search: string, page: number, options?: any): AxiosPromise<CountryOptionDtoListResponse> {
            return localVarFp.listCountryOptions(search, page, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} search 
         * @param {number} page 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        listEmployeeOptions(search: string, page: number, options?: any): AxiosPromise<EmployeeOptionDtoListResponse> {
            return localVarFp.listEmployeeOptions(search, page, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        listEmployeeTimes(id: number, options?: any): AxiosPromise<EmployeeTimeListDtoListResponse> {
            return localVarFp.listEmployeeTimes(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        listEmployeeVacations(id: number, options?: any): AxiosPromise<EmployeeListVacationDtoListResponse> {
            return localVarFp.listEmployeeVacations(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        listEmployees(options?: any): AxiosPromise<EmployeeListDtoListResponse> {
            return localVarFp.listEmployees(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        listOffDays(options?: any): AxiosPromise<OffDayListDtoListResponse> {
            return localVarFp.listOffDays(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        listOwnPayrolls(options?: any): AxiosPromise<ListOwnPayrollDtoListResponse> {
            return localVarFp.listOwnPayrolls(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        listOwnTimes(options?: any): AxiosPromise<OwnTimeListDtoListResponse> {
            return localVarFp.listOwnTimes(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        listOwnVacations(options?: any): AxiosPromise<OwnVacationDtoListResponse> {
            return localVarFp.listOwnVacations(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {PayrollsListFilter} [payrollsListFilter] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        listPayrolls(payrollsListFilter?: PayrollsListFilter, options?: any): AxiosPromise<ListPayrollDtoListResponse> {
            return localVarFp.listPayrolls(payrollsListFilter, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} search 
         * @param {number} page 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        listProjectOptions(search: string, page: number, options?: any): AxiosPromise<ProjectOptionDtoListResponse> {
            return localVarFp.listProjectOptions(search, page, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        listProjects(options?: any): AxiosPromise<ProjectListDtoListResponse> {
            return localVarFp.listProjects(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} search 
         * @param {number} page 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        listRoleOptions(search: string, page: number, options?: any): AxiosPromise<RoleOptionDtoListResponse> {
            return localVarFp.listRoleOptions(search, page, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        listRoles(options?: any): AxiosPromise<RoleListDtoListResponse> {
            return localVarFp.listRoles(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} search 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        listStateOptions(search: string, options?: any): AxiosPromise<StatesListResponse> {
            return localVarFp.listStateOptions(search, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} search 
         * @param {number} page 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        listTeamOptions(search: string, page: number, options?: any): AxiosPromise<TeamOptionDtoListResponse> {
            return localVarFp.listTeamOptions(search, page, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        listTeams(options?: any): AxiosPromise<TeamListDtoListResponse> {
            return localVarFp.listTeams(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        listTimes(options?: any): AxiosPromise<TimeListDtoListResponse> {
            return localVarFp.listTimes(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} search 
         * @param {number} page 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        listUserOptions(search: string, page: number, options?: any): AxiosPromise<UserOptionDtoListResponse> {
            return localVarFp.listUserOptions(search, page, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        listUsers(options?: any): AxiosPromise<UserListDtoListResponse> {
            return localVarFp.listUsers(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {VacationListFilter} [vacationListFilter] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        listVacations(vacationListFilter?: VacationListFilter, options?: any): AxiosPromise<ListVacationDtoListResponse> {
            return localVarFp.listVacations(vacationListFilter, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {Request} request 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        login(request: Request, options?: any): AxiosPromise<void> {
            return localVarFp.login(request, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        logout(options?: any): AxiosPromise<void> {
            return localVarFp.logout(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        refresh(options?: any): AxiosPromise<void> {
            return localVarFp.refresh(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        showActivity(id: number, options?: any): AxiosPromise<ActivityShowDtoDataResponse> {
            return localVarFp.showActivity(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        showBranch(id: number, options?: any): AxiosPromise<BranchShowDtoDataResponse> {
            return localVarFp.showBranch(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        showConfig(options?: any): AxiosPromise<ConfigDtoDataResponse> {
            return localVarFp.showConfig(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        showEmployee(id: number, options?: any): AxiosPromise<EmployeeShowDtoDataResponse> {
            return localVarFp.showEmployee(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        showEmployeeDashboard(options?: any): AxiosPromise<EmployeeDashboardDto> {
            return localVarFp.showEmployeeDashboard(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} id 
         * @param {string} dateFrom 
         * @param {string} dateTo 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        showEmployeeOverview(id: number, dateFrom: string, dateTo: string, options?: any): AxiosPromise<EmployeeOverviewDtoDataResponse> {
            return localVarFp.showEmployeeOverview(id, dateFrom, dateTo, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        showOffDay(id: number, options?: any): AxiosPromise<OffDayShowDtoDataResponse> {
            return localVarFp.showOffDay(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        showProject(id: number, options?: any): AxiosPromise<ProjectShowDtoDataResponse> {
            return localVarFp.showProject(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        showRole(id: number, options?: any): AxiosPromise<RoleShowDtoDataResponse> {
            return localVarFp.showRole(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        showTeam(id: number, options?: any): AxiosPromise<TeamShowDtoDataResponse> {
            return localVarFp.showTeam(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        showTime(id: number, options?: any): AxiosPromise<TimeShowDtoDataResponse> {
            return localVarFp.showTime(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        showUser(id: string, options?: any): AxiosPromise<UserShowDtoDataResponse> {
            return localVarFp.showUser(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        showVacation(id: number, options?: any): AxiosPromise<VacationShowDtoDataResponse> {
            return localVarFp.showVacation(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} id 
         * @param {SubtractTransactionDto} subtractTransactionDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        subtractAccountTransaction(id: number, subtractTransactionDto: SubtractTransactionDto, options?: any): AxiosPromise<void> {
            return localVarFp.subtractAccountTransaction(id, subtractTransactionDto, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} id 
         * @param {ActivityUpdateDto} activityUpdateDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateActivity(id: number, activityUpdateDto: ActivityUpdateDto, options?: any): AxiosPromise<void> {
            return localVarFp.updateActivity(id, activityUpdateDto, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} id 
         * @param {BranchUpdateDto} branchUpdateDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateBranch(id: number, branchUpdateDto: BranchUpdateDto, options?: any): AxiosPromise<void> {
            return localVarFp.updateBranch(id, branchUpdateDto, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} id 
         * @param {EmployeeUpdateDto} employeeUpdateDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateEmployee(id: number, employeeUpdateDto: EmployeeUpdateDto, options?: any): AxiosPromise<void> {
            return localVarFp.updateEmployee(id, employeeUpdateDto, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} id 
         * @param {UpdateEmployeeVacationEntitlementDto} updateEmployeeVacationEntitlementDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateEmployeeVacationEntitlement(id: number, updateEmployeeVacationEntitlementDto: UpdateEmployeeVacationEntitlementDto, options?: any): AxiosPromise<void> {
            return localVarFp.updateEmployeeVacationEntitlement(id, updateEmployeeVacationEntitlementDto, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} id 
         * @param {OffDayUpdateDto} offDayUpdateDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateOffDay(id: number, offDayUpdateDto: OffDayUpdateDto, options?: any): AxiosPromise<void> {
            return localVarFp.updateOffDay(id, offDayUpdateDto, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} id 
         * @param {TimeUpdateOwnDto} timeUpdateOwnDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateOwnTime(id: number, timeUpdateOwnDto: TimeUpdateOwnDto, options?: any): AxiosPromise<void> {
            return localVarFp.updateOwnTime(id, timeUpdateOwnDto, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} id 
         * @param {VacationUpdateOwnDto} vacationUpdateOwnDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateOwnVacation(id: number, vacationUpdateOwnDto: VacationUpdateOwnDto, options?: any): AxiosPromise<void> {
            return localVarFp.updateOwnVacation(id, vacationUpdateOwnDto, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} id 
         * @param {ProjectUpdateDto} projectUpdateDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateProject(id: number, projectUpdateDto: ProjectUpdateDto, options?: any): AxiosPromise<void> {
            return localVarFp.updateProject(id, projectUpdateDto, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} id 
         * @param {RoleUpdateDto} roleUpdateDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateRole(id: number, roleUpdateDto: RoleUpdateDto, options?: any): AxiosPromise<void> {
            return localVarFp.updateRole(id, roleUpdateDto, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} id 
         * @param {TeamUpdateDto} teamUpdateDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateTeam(id: number, teamUpdateDto: TeamUpdateDto, options?: any): AxiosPromise<void> {
            return localVarFp.updateTeam(id, teamUpdateDto, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} id 
         * @param {TimeUpdateDto} timeUpdateDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateTime(id: number, timeUpdateDto: TimeUpdateDto, options?: any): AxiosPromise<void> {
            return localVarFp.updateTime(id, timeUpdateDto, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} id 
         * @param {UserUpdateDto} userUpdateDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateUser(id: string, userUpdateDto: UserUpdateDto, options?: any): AxiosPromise<void> {
            return localVarFp.updateUser(id, userUpdateDto, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} id 
         * @param {VacationUpdateDto} vacationUpdateDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateVacation(id: number, vacationUpdateDto: VacationUpdateDto, options?: any): AxiosPromise<void> {
            return localVarFp.updateVacation(id, vacationUpdateDto, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} id 
         * @param {VacationStatusUpdateDto} vacationStatusUpdateDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateVacationStatus(id: number, vacationStatusUpdateDto: VacationStatusUpdateDto, options?: any): AxiosPromise<void> {
            return localVarFp.updateVacationStatus(id, vacationStatusUpdateDto, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {Array<File>} files 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        uploadPayrolls(files: Array<File>, options?: any): AxiosPromise<void> {
            return localVarFp.uploadPayrolls(files, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * APIApi - object-oriented interface
 * @export
 * @class APIApi
 * @extends {BaseAPI}
 */
export class APIApi extends BaseAPI {
    /**
     * 
     * @param {number} id 
     * @param {AddTransactionDto} addTransactionDto 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof APIApi
     */
    public addAccountTransaction(id: number, addTransactionDto: AddTransactionDto, options?: RawAxiosRequestConfig) {
        return APIApiFp(this.configuration).addAccountTransaction(id, addTransactionDto, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} providerId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof APIApi
     */
    public apiAuthProviderIdAuthenticateGet(providerId: string, options?: RawAxiosRequestConfig) {
        return APIApiFp(this.configuration).apiAuthProviderIdAuthenticateGet(providerId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} providerId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof APIApi
     */
    public apiAuthProviderIdLoginGet(providerId: string, options?: RawAxiosRequestConfig) {
        return APIApiFp(this.configuration).apiAuthProviderIdLoginGet(providerId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof APIApi
     */
    public apiAuthProvidersGet(options?: RawAxiosRequestConfig) {
        return APIApiFp(this.configuration).apiAuthProvidersGet(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof APIApi
     */
    public apiHealthGet(options?: RawAxiosRequestConfig) {
        return APIApiFp(this.configuration).apiHealthGet(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof APIApi
     */
    public cancelOwnVacation(id: number, options?: RawAxiosRequestConfig) {
        return APIApiFp(this.configuration).cancelOwnVacation(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {ActivityCreateDto} activityCreateDto 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof APIApi
     */
    public createActivity(activityCreateDto: ActivityCreateDto, options?: RawAxiosRequestConfig) {
        return APIApiFp(this.configuration).createActivity(activityCreateDto, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {BranchCreateDto} branchCreateDto 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof APIApi
     */
    public createBranch(branchCreateDto: BranchCreateDto, options?: RawAxiosRequestConfig) {
        return APIApiFp(this.configuration).createBranch(branchCreateDto, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {EmployeeCreateDto} employeeCreateDto 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof APIApi
     */
    public createEmployee(employeeCreateDto: EmployeeCreateDto, options?: RawAxiosRequestConfig) {
        return APIApiFp(this.configuration).createEmployee(employeeCreateDto, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {OffDayCreateDto} offDayCreateDto 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof APIApi
     */
    public createOffDay(offDayCreateDto: OffDayCreateDto, options?: RawAxiosRequestConfig) {
        return APIApiFp(this.configuration).createOffDay(offDayCreateDto, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {TimeCreateOwnDto} timeCreateOwnDto 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof APIApi
     */
    public createOwnTime(timeCreateOwnDto: TimeCreateOwnDto, options?: RawAxiosRequestConfig) {
        return APIApiFp(this.configuration).createOwnTime(timeCreateOwnDto, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {VacationCreateOwnDto} vacationCreateOwnDto 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof APIApi
     */
    public createOwnVacation(vacationCreateOwnDto: VacationCreateOwnDto, options?: RawAxiosRequestConfig) {
        return APIApiFp(this.configuration).createOwnVacation(vacationCreateOwnDto, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {ProjectCreateDto} projectCreateDto 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof APIApi
     */
    public createProject(projectCreateDto: ProjectCreateDto, options?: RawAxiosRequestConfig) {
        return APIApiFp(this.configuration).createProject(projectCreateDto, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {RoleCreateDto} roleCreateDto 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof APIApi
     */
    public createRole(roleCreateDto: RoleCreateDto, options?: RawAxiosRequestConfig) {
        return APIApiFp(this.configuration).createRole(roleCreateDto, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {TeamCreateDto} teamCreateDto 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof APIApi
     */
    public createTeam(teamCreateDto: TeamCreateDto, options?: RawAxiosRequestConfig) {
        return APIApiFp(this.configuration).createTeam(teamCreateDto, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {TimeCreateDto} timeCreateDto 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof APIApi
     */
    public createTime(timeCreateDto: TimeCreateDto, options?: RawAxiosRequestConfig) {
        return APIApiFp(this.configuration).createTime(timeCreateDto, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {UserCreateDto} userCreateDto 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof APIApi
     */
    public createUser(userCreateDto: UserCreateDto, options?: RawAxiosRequestConfig) {
        return APIApiFp(this.configuration).createUser(userCreateDto, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {VacationCreateDto} vacationCreateDto 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof APIApi
     */
    public createVacation(vacationCreateDto: VacationCreateDto, options?: RawAxiosRequestConfig) {
        return APIApiFp(this.configuration).createVacation(vacationCreateDto, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof APIApi
     */
    public deleteActivity(id: number, options?: RawAxiosRequestConfig) {
        return APIApiFp(this.configuration).deleteActivity(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof APIApi
     */
    public deleteBranch(id: number, options?: RawAxiosRequestConfig) {
        return APIApiFp(this.configuration).deleteBranch(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof APIApi
     */
    public deleteEmployee(id: number, options?: RawAxiosRequestConfig) {
        return APIApiFp(this.configuration).deleteEmployee(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof APIApi
     */
    public deleteOffDay(id: number, options?: RawAxiosRequestConfig) {
        return APIApiFp(this.configuration).deleteOffDay(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof APIApi
     */
    public deletePayroll(id: string, options?: RawAxiosRequestConfig) {
        return APIApiFp(this.configuration).deletePayroll(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof APIApi
     */
    public deleteProject(id: number, options?: RawAxiosRequestConfig) {
        return APIApiFp(this.configuration).deleteProject(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof APIApi
     */
    public deleteRole(id: number, options?: RawAxiosRequestConfig) {
        return APIApiFp(this.configuration).deleteRole(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof APIApi
     */
    public deleteTeam(id: number, options?: RawAxiosRequestConfig) {
        return APIApiFp(this.configuration).deleteTeam(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof APIApi
     */
    public deleteTime(id: number, options?: RawAxiosRequestConfig) {
        return APIApiFp(this.configuration).deleteTime(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof APIApi
     */
    public deleteUser(id: string, options?: RawAxiosRequestConfig) {
        return APIApiFp(this.configuration).deleteUser(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof APIApi
     */
    public deleteVacation(id: number, options?: RawAxiosRequestConfig) {
        return APIApiFp(this.configuration).deleteVacation(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof APIApi
     */
    public downloadOwnPayroll(id: string, options?: RawAxiosRequestConfig) {
        return APIApiFp(this.configuration).downloadOwnPayroll(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof APIApi
     */
    public downloadPayroll(id: string, options?: RawAxiosRequestConfig) {
        return APIApiFp(this.configuration).downloadPayroll(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} accountId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof APIApi
     */
    public getAccountTransactions(accountId: number, options?: RawAxiosRequestConfig) {
        return APIApiFp(this.configuration).getAccountTransactions(accountId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof APIApi
     */
    public getOwn(options?: RawAxiosRequestConfig) {
        return APIApiFp(this.configuration).getOwn(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof APIApi
     */
    public listActivities(options?: RawAxiosRequestConfig) {
        return APIApiFp(this.configuration).listActivities(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} search 
     * @param {number} page 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof APIApi
     */
    public listActivityOptions(search: string, page: number, options?: RawAxiosRequestConfig) {
        return APIApiFp(this.configuration).listActivityOptions(search, page, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof APIApi
     */
    public listAll(options?: RawAxiosRequestConfig) {
        return APIApiFp(this.configuration).listAll(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} search 
     * @param {number} page 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof APIApi
     */
    public listBranchOptions(search: string, page: number, options?: RawAxiosRequestConfig) {
        return APIApiFp(this.configuration).listBranchOptions(search, page, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof APIApi
     */
    public listBranches(options?: RawAxiosRequestConfig) {
        return APIApiFp(this.configuration).listBranches(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} search 
     * @param {number} page 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof APIApi
     */
    public listCountryOptions(search: string, page: number, options?: RawAxiosRequestConfig) {
        return APIApiFp(this.configuration).listCountryOptions(search, page, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} search 
     * @param {number} page 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof APIApi
     */
    public listEmployeeOptions(search: string, page: number, options?: RawAxiosRequestConfig) {
        return APIApiFp(this.configuration).listEmployeeOptions(search, page, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof APIApi
     */
    public listEmployeeTimes(id: number, options?: RawAxiosRequestConfig) {
        return APIApiFp(this.configuration).listEmployeeTimes(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof APIApi
     */
    public listEmployeeVacations(id: number, options?: RawAxiosRequestConfig) {
        return APIApiFp(this.configuration).listEmployeeVacations(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof APIApi
     */
    public listEmployees(options?: RawAxiosRequestConfig) {
        return APIApiFp(this.configuration).listEmployees(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof APIApi
     */
    public listOffDays(options?: RawAxiosRequestConfig) {
        return APIApiFp(this.configuration).listOffDays(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof APIApi
     */
    public listOwnPayrolls(options?: RawAxiosRequestConfig) {
        return APIApiFp(this.configuration).listOwnPayrolls(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof APIApi
     */
    public listOwnTimes(options?: RawAxiosRequestConfig) {
        return APIApiFp(this.configuration).listOwnTimes(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof APIApi
     */
    public listOwnVacations(options?: RawAxiosRequestConfig) {
        return APIApiFp(this.configuration).listOwnVacations(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {PayrollsListFilter} [payrollsListFilter] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof APIApi
     */
    public listPayrolls(payrollsListFilter?: PayrollsListFilter, options?: RawAxiosRequestConfig) {
        return APIApiFp(this.configuration).listPayrolls(payrollsListFilter, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} search 
     * @param {number} page 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof APIApi
     */
    public listProjectOptions(search: string, page: number, options?: RawAxiosRequestConfig) {
        return APIApiFp(this.configuration).listProjectOptions(search, page, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof APIApi
     */
    public listProjects(options?: RawAxiosRequestConfig) {
        return APIApiFp(this.configuration).listProjects(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} search 
     * @param {number} page 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof APIApi
     */
    public listRoleOptions(search: string, page: number, options?: RawAxiosRequestConfig) {
        return APIApiFp(this.configuration).listRoleOptions(search, page, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof APIApi
     */
    public listRoles(options?: RawAxiosRequestConfig) {
        return APIApiFp(this.configuration).listRoles(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} search 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof APIApi
     */
    public listStateOptions(search: string, options?: RawAxiosRequestConfig) {
        return APIApiFp(this.configuration).listStateOptions(search, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} search 
     * @param {number} page 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof APIApi
     */
    public listTeamOptions(search: string, page: number, options?: RawAxiosRequestConfig) {
        return APIApiFp(this.configuration).listTeamOptions(search, page, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof APIApi
     */
    public listTeams(options?: RawAxiosRequestConfig) {
        return APIApiFp(this.configuration).listTeams(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof APIApi
     */
    public listTimes(options?: RawAxiosRequestConfig) {
        return APIApiFp(this.configuration).listTimes(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} search 
     * @param {number} page 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof APIApi
     */
    public listUserOptions(search: string, page: number, options?: RawAxiosRequestConfig) {
        return APIApiFp(this.configuration).listUserOptions(search, page, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof APIApi
     */
    public listUsers(options?: RawAxiosRequestConfig) {
        return APIApiFp(this.configuration).listUsers(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {VacationListFilter} [vacationListFilter] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof APIApi
     */
    public listVacations(vacationListFilter?: VacationListFilter, options?: RawAxiosRequestConfig) {
        return APIApiFp(this.configuration).listVacations(vacationListFilter, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {Request} request 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof APIApi
     */
    public login(request: Request, options?: RawAxiosRequestConfig) {
        return APIApiFp(this.configuration).login(request, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof APIApi
     */
    public logout(options?: RawAxiosRequestConfig) {
        return APIApiFp(this.configuration).logout(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof APIApi
     */
    public refresh(options?: RawAxiosRequestConfig) {
        return APIApiFp(this.configuration).refresh(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof APIApi
     */
    public showActivity(id: number, options?: RawAxiosRequestConfig) {
        return APIApiFp(this.configuration).showActivity(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof APIApi
     */
    public showBranch(id: number, options?: RawAxiosRequestConfig) {
        return APIApiFp(this.configuration).showBranch(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof APIApi
     */
    public showConfig(options?: RawAxiosRequestConfig) {
        return APIApiFp(this.configuration).showConfig(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof APIApi
     */
    public showEmployee(id: number, options?: RawAxiosRequestConfig) {
        return APIApiFp(this.configuration).showEmployee(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof APIApi
     */
    public showEmployeeDashboard(options?: RawAxiosRequestConfig) {
        return APIApiFp(this.configuration).showEmployeeDashboard(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} id 
     * @param {string} dateFrom 
     * @param {string} dateTo 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof APIApi
     */
    public showEmployeeOverview(id: number, dateFrom: string, dateTo: string, options?: RawAxiosRequestConfig) {
        return APIApiFp(this.configuration).showEmployeeOverview(id, dateFrom, dateTo, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof APIApi
     */
    public showOffDay(id: number, options?: RawAxiosRequestConfig) {
        return APIApiFp(this.configuration).showOffDay(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof APIApi
     */
    public showProject(id: number, options?: RawAxiosRequestConfig) {
        return APIApiFp(this.configuration).showProject(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof APIApi
     */
    public showRole(id: number, options?: RawAxiosRequestConfig) {
        return APIApiFp(this.configuration).showRole(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof APIApi
     */
    public showTeam(id: number, options?: RawAxiosRequestConfig) {
        return APIApiFp(this.configuration).showTeam(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof APIApi
     */
    public showTime(id: number, options?: RawAxiosRequestConfig) {
        return APIApiFp(this.configuration).showTime(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof APIApi
     */
    public showUser(id: string, options?: RawAxiosRequestConfig) {
        return APIApiFp(this.configuration).showUser(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof APIApi
     */
    public showVacation(id: number, options?: RawAxiosRequestConfig) {
        return APIApiFp(this.configuration).showVacation(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} id 
     * @param {SubtractTransactionDto} subtractTransactionDto 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof APIApi
     */
    public subtractAccountTransaction(id: number, subtractTransactionDto: SubtractTransactionDto, options?: RawAxiosRequestConfig) {
        return APIApiFp(this.configuration).subtractAccountTransaction(id, subtractTransactionDto, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} id 
     * @param {ActivityUpdateDto} activityUpdateDto 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof APIApi
     */
    public updateActivity(id: number, activityUpdateDto: ActivityUpdateDto, options?: RawAxiosRequestConfig) {
        return APIApiFp(this.configuration).updateActivity(id, activityUpdateDto, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} id 
     * @param {BranchUpdateDto} branchUpdateDto 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof APIApi
     */
    public updateBranch(id: number, branchUpdateDto: BranchUpdateDto, options?: RawAxiosRequestConfig) {
        return APIApiFp(this.configuration).updateBranch(id, branchUpdateDto, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} id 
     * @param {EmployeeUpdateDto} employeeUpdateDto 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof APIApi
     */
    public updateEmployee(id: number, employeeUpdateDto: EmployeeUpdateDto, options?: RawAxiosRequestConfig) {
        return APIApiFp(this.configuration).updateEmployee(id, employeeUpdateDto, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} id 
     * @param {UpdateEmployeeVacationEntitlementDto} updateEmployeeVacationEntitlementDto 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof APIApi
     */
    public updateEmployeeVacationEntitlement(id: number, updateEmployeeVacationEntitlementDto: UpdateEmployeeVacationEntitlementDto, options?: RawAxiosRequestConfig) {
        return APIApiFp(this.configuration).updateEmployeeVacationEntitlement(id, updateEmployeeVacationEntitlementDto, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} id 
     * @param {OffDayUpdateDto} offDayUpdateDto 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof APIApi
     */
    public updateOffDay(id: number, offDayUpdateDto: OffDayUpdateDto, options?: RawAxiosRequestConfig) {
        return APIApiFp(this.configuration).updateOffDay(id, offDayUpdateDto, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} id 
     * @param {TimeUpdateOwnDto} timeUpdateOwnDto 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof APIApi
     */
    public updateOwnTime(id: number, timeUpdateOwnDto: TimeUpdateOwnDto, options?: RawAxiosRequestConfig) {
        return APIApiFp(this.configuration).updateOwnTime(id, timeUpdateOwnDto, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} id 
     * @param {VacationUpdateOwnDto} vacationUpdateOwnDto 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof APIApi
     */
    public updateOwnVacation(id: number, vacationUpdateOwnDto: VacationUpdateOwnDto, options?: RawAxiosRequestConfig) {
        return APIApiFp(this.configuration).updateOwnVacation(id, vacationUpdateOwnDto, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} id 
     * @param {ProjectUpdateDto} projectUpdateDto 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof APIApi
     */
    public updateProject(id: number, projectUpdateDto: ProjectUpdateDto, options?: RawAxiosRequestConfig) {
        return APIApiFp(this.configuration).updateProject(id, projectUpdateDto, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} id 
     * @param {RoleUpdateDto} roleUpdateDto 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof APIApi
     */
    public updateRole(id: number, roleUpdateDto: RoleUpdateDto, options?: RawAxiosRequestConfig) {
        return APIApiFp(this.configuration).updateRole(id, roleUpdateDto, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} id 
     * @param {TeamUpdateDto} teamUpdateDto 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof APIApi
     */
    public updateTeam(id: number, teamUpdateDto: TeamUpdateDto, options?: RawAxiosRequestConfig) {
        return APIApiFp(this.configuration).updateTeam(id, teamUpdateDto, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} id 
     * @param {TimeUpdateDto} timeUpdateDto 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof APIApi
     */
    public updateTime(id: number, timeUpdateDto: TimeUpdateDto, options?: RawAxiosRequestConfig) {
        return APIApiFp(this.configuration).updateTime(id, timeUpdateDto, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} id 
     * @param {UserUpdateDto} userUpdateDto 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof APIApi
     */
    public updateUser(id: string, userUpdateDto: UserUpdateDto, options?: RawAxiosRequestConfig) {
        return APIApiFp(this.configuration).updateUser(id, userUpdateDto, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} id 
     * @param {VacationUpdateDto} vacationUpdateDto 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof APIApi
     */
    public updateVacation(id: number, vacationUpdateDto: VacationUpdateDto, options?: RawAxiosRequestConfig) {
        return APIApiFp(this.configuration).updateVacation(id, vacationUpdateDto, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} id 
     * @param {VacationStatusUpdateDto} vacationStatusUpdateDto 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof APIApi
     */
    public updateVacationStatus(id: number, vacationStatusUpdateDto: VacationStatusUpdateDto, options?: RawAxiosRequestConfig) {
        return APIApiFp(this.configuration).updateVacationStatus(id, vacationStatusUpdateDto, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {Array<File>} files 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof APIApi
     */
    public uploadPayrolls(files: Array<File>, options?: RawAxiosRequestConfig) {
        return APIApiFp(this.configuration).uploadPayrolls(files, options).then((request) => request(this.axios, this.basePath));
    }
}



