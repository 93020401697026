import { CrudDrawer, DateField, EntityDrawerProps, SingleSelectField, TextField } from "@dgs/core";
import React, { FC, useCallback } from "react";
import { useTranslation } from "react-i18next";
import { EmployeeSingleSelectField } from "~root/employees/employee_select_field";
import { HalfDayField } from "~root/vacations/HalfDayField";
import { ALL_VACATION_STATUS, IVacationFormState } from "~shared/types";

export const VacationDrawer: FC<EntityDrawerProps<IVacationFormState>> = (props) => {
	const { t } = useTranslation();

	const validate = useCallback(
		(values: IVacationFormState) => {
			const errors: { [K in keyof IVacationFormState]?: any } = {};

			if (!values.start) {
				errors.start = t("Field is required");
			}
			if (!values.end) {
				errors.end = t("Field is required");
			}
			if (!values.employee) {
				errors.employee = t("Field is required");
			}

			return errors;
		},
		[t],
	);

	return (
		<CrudDrawer
			{...props}
			validate={validate}
			body={({ values, setFieldValue }) => (
				<>
					<DateField name="start" label={t("Start")} required />
					<DateField name="end" label={t("End")} required />
					<HalfDayField name="halfDay" label={t("Half day")} />
					<TextField name="remark" label={t("Remark")} />
					<EmployeeSingleSelectField
						name="employee"
						label={t("Employee")}
						required
						onChange={(e) => {
							if (e.target.value && values.standIn?.id === e.target.value.id) {
								setFieldValue("standIn", null);
							}
						}}
					/>
					<EmployeeSingleSelectField
						name="standIn"
						label={t("Stand in")}
						idsToRemove={values.employee ? [values.employee.id] : []}
					/>
					<SingleSelectField name="status" label={t("Status")} options={[...ALL_VACATION_STATUS]} getLabel={t} />
					{values.status === "declined" && <TextField name="declineReason" label={t("Decline reason")} />}
				</>
			)}
		/>
	);
};
