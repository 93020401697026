import {CrudDrawer, DateField, EntityDrawerProps, TextField} from "@dgs/core";
import React, {FC, useCallback} from "react";
import {useTranslation} from "react-i18next";
import {IVacationEntitlementFormState} from "~shared/types";

export const VacationEntitlementUpdateDrawer: FC<EntityDrawerProps<IVacationEntitlementFormState>> = (props) => {
    const {t} = useTranslation();

    const validate = useCallback(
        (values: IVacationEntitlementFormState) => {
            const errors: { [K in keyof IVacationEntitlementFormState]?: any } = {};

            /*if (!values.changeDate) {
                errors.changeDate = t("Field is required");
            } else if (isBefore(parse(values.changeDate, "yyyy-MM-dd", new Date()), new Date())) {
                errors.changeDate = t("Date must be in the future");
            }*/
            if (!values.entryDate) {
                errors.entryDate = t("Field is required");
            }
            if (!values.hoursPerDay) {
                errors.hoursPerDay = t("Field is required");
            }
            if (!values.yearlyVacationDays) {
                errors.yearlyVacationDays = t("Field is required");
            }
            /*if (!values.branch) {
                errors.branch = t("Field is required");
            }*/

            return errors;
        },
        [t],
    );

    return (
        <CrudDrawer
            {...props}
            validate={validate}
            body={
                <>
                    {/*<DateField name="changeDate" label={t("Change date")} required/>*/}
                    <DateField name="entryDate" label={t("Entry date")} required/>
                    <DateField name="leaveDate" label={t("Leave date")}/>
                    <TextField name="yearlyVacationDays" label={t("Yearly vacation days")} required type="number"/>
                    <TextField name="hoursPerDay" label={t("Hours per day")} type="number" required/>
                    {/*<BranchSelectField name="branch" label={t("Branch")} required/>*/}
                </>
            }
        />
    );
};
