import {
	DynamicConfigProvider,
	FCC,
	RootContainer,
	ToastProvider,
	createTenantComponentsColors,
	createTenantComponentsTheme,
	getTheme,
} from "@dgs/core";
import { QueryClient, QueryClientProvider } from "@tanstack/react-query";
import React from "react";
import { BrowserRouter } from "react-router-dom";
import { ThemeProvider, createGlobalStyle } from "styled-components";
import { PermaLocaleProvider } from "~shared/providers/perma_locale_provider";
import { PersonalManagerAuthProvider } from "./auth";
import { config } from "./config";
import { PersonalManagerAxiosConfig } from "./personal_manager_axios_config";

const queryClient = new QueryClient({
	defaultOptions: {
		queries: { staleTime: 100000, retry: false },
	},
});

const GlobalStyles = createGlobalStyle`
  ${({ theme }) => theme.global};
`;

export const AppProviders: FCC = ({ children }) => {
	const baseTheme = getTheme(config.mode);
	const theme = createTenantComponentsTheme(baseTheme, createTenantComponentsColors(baseTheme.colors));

	return (
		<BrowserRouter>
			<QueryClientProvider client={queryClient}>
				<DynamicConfigProvider>
					<PersonalManagerAuthProvider>
						<PermaLocaleProvider>
							<ThemeProvider theme={theme}>
								<ToastProvider>
									<GlobalStyles />
									<RootContainer>
										<PersonalManagerAxiosConfig />
										{children}
									</RootContainer>
								</ToastProvider>
							</ThemeProvider>
						</PermaLocaleProvider>
					</PersonalManagerAuthProvider>
				</DynamicConfigProvider>
			</QueryClientProvider>
		</BrowserRouter>
	);
};
