import coreDe from "@dgs/core/src/i18n/translations/de.json";
import coreEn from "@dgs/core/src/i18n/translations/en.json";
import i18n from "i18next";
import { initReactI18next } from "react-i18next";
import de from "./de.json";
import en from "./en.json";

export const resources = {
	en: { translation: { ...coreEn, ...en } },
	de: { translation: { ...coreDe, ...de } },
} as const;

i18n.use(initReactI18next).init({
	resources,
	lng: "de",
	keySeparator: false, // we do not use keys in form messages.welcome
	interpolation: {
		escapeValue: false, // react already safes from xss
	},
	debug: false,
});

export default i18n;
