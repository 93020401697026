import { AutoSidebar, SidebarItem, useUserPermission } from "@dgs/core";
import React, { FC, useMemo } from "react";
import { useTranslation } from "react-i18next";
import styled from "styled-components";
import { PermissionName } from "~shared/types";
import { SidebarHeader } from "./sidebar_header";

const StyledAutoSidebar = styled(AutoSidebar)`
	position: relative;
	z-index: ${({ theme }) => theme.zIndices.content.l2};
`;

export const Sidebar: FC = () => {
	const { t } = useTranslation();
	const { permissions } = useUserPermission();

	const sidebarItems = useMemo<SidebarItem<PermissionName>[]>(
		() => [
			{
				icon: "dashboard",
				key: t("Dashboard"),
				permissions: ["Permissions.Vacations.Read"],
				to: "/dashboard",
			},
			{
				icon: "time",
				key: t("Times"),
				permissions: ["Permissions.Times.Read"],
				to: "/times",
				children: [
					{
						icon: "time",
						key: t("My times"),
						permissions: ["Permissions.Times.Read"],
						to: "/times/own",
					},
					{
						icon: "time",
						key: t("All times"),
						permissions: ["Permissions.Times.Visibility.All"],
						to: "/times/all",
					},
				],
			},
			{
				icon: "calendar",
				key: t("Vacations"),
				permissions: ["Permissions.Vacations.Read"],
				to: "/vacations",
				children: [
					{
						icon: "calendar",
						key: t("My vacations"),
						permissions: ["Permissions.Vacations.Read"],
						to: "/vacations/own",
					},
					{
						icon: "calendar",
						key: t("All vacations"),
						permissions: ["Permissions.Vacations.Visibility.All"],
						to: "/vacations/all",
					},
				],
			},

			{
				icon: "file_download",
				key: t("Payrolls"),
				permissions: ["Permissions.Payrolls.Read"],
				to: "/payrolls",
				children: [
					{
						icon: "file_download",
						key: t("My payrolls"),
						permissions: ["Permissions.Payrolls.Read"],
						to: "/payrolls/own",
					},
					{
						icon: "file_download",
						key: t("All payrolls"),
						permissions: ["Permissions.Payrolls.Visibility.All"],
						to: "/payrolls/all",
					},
				],
			},
			{
				icon: "inventory",
				key: t("Projects"),
				permissions: ["Permissions.Projects.Read"],
				to: "/projects",
			},
			{
				icon: "refresh",
				key: t("Activities"),
				permissions: ["Permissions.Activities.Read"],
				to: "/activities",
			},
			{
				icon: "people",
				key: t("Employees"),
				permissions: ["Permissions.Employees.Read"],
				to: "/employees",
			},
			{
				icon: "group",
				key: t("Teams"),
				permissions: ["Permissions.Teams.Read"],
				to: "/teams",
			},
			{
				icon: "layers",
				key: t("Branches"),
				permissions: ["Permissions.Branches.Read"],
				to: "/branches",
			},
			{
				icon: "announcement",
				key: t("Off days"),
				permissions: ["Permissions.OffDays.Read"],
				to: "/off-days",
			},
			{
				icon: "warning",
				key: t("Users & Roles"),
				permissions: ["Permissions.Users.Read", "Permissions.Roles.Read"],
				to: "/users-roles",
				children: [
					{
						icon: "person",
						key: t("Users"),
						permissions: ["Permissions.Users.Read"],
						to: "/users-roles/users",
					},
					{
						icon: "lock_open",
						key: t("Roles"),
						permissions: ["Permissions.Roles.Read"],
						to: "/users-roles/roles",
					},
				],
			},
		],
		[t],
	);

	return <StyledAutoSidebar header={<SidebarHeader />} sidebarItems={sidebarItems} permissions={permissions} />;
};
