import {
	ContextMenu,
	ContextMenuAnchorItem,
	ContextMenuDeleteItem,
	DrawerAutoDataList,
	FormattedDate,
	IDrawerDataListColumn,
	IShowEndpoint,
	Id,
	apiIsOK,
	mapFetcherConfigToAxiosConfig,
} from "@dgs/core";
import React, { FC, useCallback, useMemo } from "react";
import { useTranslation } from "react-i18next";
import { EmployeeOptionDto, ListPayrollDto } from "~root/api_gen";
import { IPayrollCreateFormState, PayrollCreateDrawer } from "~root/employees/details/payrolls/PayrollDrawer";
import { EmployeeSingleSelectField } from "~root/employees/employee_select_field";
import { payrollKeys } from "~shared/api/payrolls/vacationKeys";
import { useInvalidation } from "~shared/api/useInvalidation";
import { useSuccessToast } from "~shared/api/useSuccessToast";
import { webserviceApi } from "~shared/api/webserviceApi";

const emptyPayroll: IPayrollCreateFormState = {
	files: [],
};
export const PayrollsList: FC = () => {
	const { t } = useTranslation();
	const invalidateListOnSuccess = useInvalidation([[...payrollKeys.lists()]]);
	const showDeletedToastOnSuccess = useSuccessToast(t("Success"), t("Payroll successfully deleted"));
	const showSavedToastOnSuccess = useSuccessToast(t("Success"), t("Payroll successfully saved"));
	const createPayroll = useCallback(
		async (values: IPayrollCreateFormState) => {
			return webserviceApi
				.uploadPayrolls(Array.from(values.files))
				.then(apiIsOK)
				.then(invalidateListOnSuccess)
				.then(showSavedToastOnSuccess);
		},
		[invalidateListOnSuccess, showSavedToastOnSuccess],
	);
	const deletePayroll = useCallback(
		async (id: Id) => {
			return webserviceApi
				.deletePayroll(id.toString())
				.then(apiIsOK)
				.then(invalidateListOnSuccess)
				.then(showDeletedToastOnSuccess);
		},
		[invalidateListOnSuccess, showDeletedToastOnSuccess],
	);
	const dataListColumns: IDrawerDataListColumn<ListPayrollDto>[] = useMemo(() => {
		return [
			{
				heading: t("Name"),
				valueKey: "name",
				type: "value",
			},
			{
				heading: t("Employee"),
				valueKey: "employee",
				render: ({ employee }) => `${employee.firstName} ${employee.lastName}`,
				type: "value",
				filter: {
					operators: ["="],
					renderValueField: ({ fieldName }) => <EmployeeSingleSelectField name={fieldName} label={t("Employee")} />,
					renderActiveColumnFilterValue: (value: EmployeeOptionDto) => (
						<span>{`${value.firstName} ${value.lastName}`}</span>
					),
				},
			},
			{
				heading: t("Month"),
				valueKey: "id",
				type: "value",
				render: ({ year, month }) => (
					<FormattedDate date={new Date(year, month - 1, 1)} format={{ year: "numeric", month: "short" }} />
				),
			},
			{
				heading: "",
				type: "actions",
				size: "max-content",
				action: ({ id }) => (
					<ContextMenu displayShortcutsAs="icon">
						<ContextMenuAnchorItem
							title={t("Download payroll")}
							label={t("Download payroll")}
							href={`/api/backend/payrolls/${id}`}
							icon="file_download"
							shortcut="primary"
						/>
						<ContextMenuDeleteItem
							action={() => deletePayroll(id)}
							title={t("Delete payroll")}
							label={t("Delete payroll")}
							heading={t("Delete")}
							labels={{ close: t("Close"), confirm: t("Confirm") }}
						>
							{t("Are you sure you want to delete this payroll?")}
						</ContextMenuDeleteItem>
					</ContextMenu>
				),
			},
		];
	}, [deletePayroll, t]);

	return (
		<DrawerAutoDataList
			canCreate
			columns={dataListColumns}
			translations={{
				empty: t("No payrolls available."),
				title: t("All payrolls"),
			}}
			service={{
				index: (config) =>
					webserviceApi.listPayrolls({ filterItems: config.filterParams }, mapFetcherConfigToAxiosConfig(config)),
				listKey: [...payrollKeys.lists()],
				indexKey: [...payrollKeys.all],
				show: (() => {}) as unknown as IShowEndpoint<any>["show"],
				showKey: () => [],
			}}
			renderCreateDrawer={(drawerProps) => (
				<PayrollCreateDrawer
					{...drawerProps}
					heading={t("Upload payrolls")}
					initialValues={emptyPayroll}
					onSubmit={createPayroll}
				/>
			)}
		/>
	);
};
