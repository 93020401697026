import { Id } from "@dgs/core";

export interface ListConfig {
	page: number;
	perPage: number;
	search: string;
	searchColumns: string;
}

export const timeKeys = {
	all: ["times"] as const,
	lists: () => [...timeKeys.all, "lists"] as const,
	list: (config: ListConfig) => [...timeKeys.lists(), JSON.stringify(config)] as const,
	ownList: () => [...timeKeys.lists(), "own"] as const,
	details: () => [...timeKeys.all, "detail"] as const,
	detail: (id: Id) => [...timeKeys.details(), `${id}`],
	employeeList: (employeeId: Id) => [...timeKeys.lists(), "employees", `${employeeId}`] as const,
};
