import { Id } from "@dgs/core";

export const employeeKeys = {
	all: ["employees"] as const,
	lists: () => [...employeeKeys.all, "lists"] as const,
	details: () => [...employeeKeys.all, "detail"] as const,
	detail: (id: Id) => [...employeeKeys.details(), `${id}`],
	overview: (id: Id) => [...employeeKeys.detail(id), "overview"],
	vacationList: (id: Id) => [...employeeKeys.detail(id), "vacations"] as const,
	transactionList: (id: Id, accountId: Id) => [...employeeKeys.detail(id), "accounts", `${accountId}`] as const,
};
