import { Id } from "@dgs/core";
import { EmployeeOptionDto, VacationShowDto } from "~root/api_gen";
import { IEmployeeOptionResource } from "./employee";

export const ALL_VACATION_STATUS = ["pending", "approved", "declined", "cancelled"] as const;

export type IVacationStatus = (typeof ALL_VACATION_STATUS)[number];

export type IVacationIndexResource = {
	id: Id;
	start: string;
	end: string;
	remark: string;
	declineReason: string | null; // TODO: Union type?
	status: IVacationStatus;
	halfDay: boolean;
	employee: IEmployeeOptionResource;
	standIn: IEmployeeOptionResource | null;
};

export type IVacationOptionResource = IVacationIndexResource;

export type IVacationUpdateResource = {
	id: Id;
	start: string;
	end: string;
	remark: string;
	declineReason: string | null; // TODO: Union type?
	status: IVacationStatus;
	halfDay: boolean;
	employee: IEmployeeOptionResource;
	standIn: IEmployeeOptionResource | null;
};

export type IVacationFormState = Omit<VacationShowDto, "id" | "employee" | "standIn" | "vacationDayCount"> & {
	employee: EmployeeOptionDto | null;
	standIn?: EmployeeOptionDto | null;
};
