import {
	ContextMenu,
	ContextMenuDeleteItem,
	ContextMenuItem,
	DrawerAutoDataList,
	FormattedDate,
	IDrawerDataListColumn,
	apiIsOK,
	mapFetcherConfigToAxiosConfig,
} from "@dgs/core";
import React, { useMemo } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import { employeeKeys } from "~shared/api/employee/employeeKeys";
import { useInvalidation, useUpdateReset } from "~shared/api/useInvalidation";
import { useSuccessToast } from "~shared/api/useSuccessToast";
import { webserviceApi } from "~shared/api/webserviceApi";
import { IEmployeeCreateFormState } from "~shared/types";
import { EmployeeListDto } from "../api_gen";
import { EmployeeCreateDrawer } from "./employee_create_drawer";
import { EmployeeUpdateDrawer } from "./employee_update_drawer";

export const EmployeeList = () => {
	const { t } = useTranslation();
	const invalidateOnSuccess = useInvalidation([[...employeeKeys.lists()]]);
	const resetOnUpdate = useUpdateReset();
	const showSavedToastOnSuccess = useSuccessToast(t("Success"), t("Employee was saved."));
	const showDeletedToastOnSuccess = useSuccessToast(t("Success"), t("Employee was deleted."));
	const navigate = useNavigate();
	const emptyEmployee: IEmployeeCreateFormState = {
		firstName: "",
		lastName: "",
		street: null,
		houseNumber: null,
		postalCode: null,
		city: null,
		country: null,
		placeOfBirth: null,
		countryOfBirth: null,
		dateOfBirth: null,
		entryDate: "",
		leaveDate: null,
		gender: null,
		iban: null,
		yearlyVacationDays: 28,
		hoursPerDay: 8,
		user: null,
		branch: null,
		employeeNumber: "",
	};
	const dataListColumns: IDrawerDataListColumn<EmployeeListDto>[] = useMemo(() => {
		return [
			{
				heading: t("First name"),
				valueKey: "firstName",
				type: "value",
			},
			{
				heading: t("Last name"),
				valueKey: "lastName",
				type: "value",
			},
			{
				heading: t("Employee number"),
				valueKey: "employeeNumber",
				type: "value",
			},
			{
				heading: t("Date of birth"),
				valueKey: "dateOfBirth",
				type: "value",
				render: ({ dateOfBirth }) => <FormattedDate date={dateOfBirth} />,
			},
			{
				heading: t("Entry date"),
				valueKey: "entryDate",
				type: "value",
				render: ({ entryDate }) => <FormattedDate date={entryDate} />,
			},
			{
				heading: t("Leave date"),
				valueKey: "leaveDate",
				type: "value",
				render: ({ leaveDate }) => <FormattedDate date={leaveDate} />,
			},
			{
				heading: t("Yearly vacation days"),
				valueKey: "yearlyVacationDays",
				type: "value",
			},
			{
				heading: t("Hours per day"),
				valueKey: "hoursPerDay",
				type: "value",
			},
			{
				heading: t("User"),
				valueKey: "user.email",
				type: "value",
			},
			{
				heading: t("Branch"),
				valueKey: "branch.name",
				type: "value",
			},
			{
				heading: "",
				type: "actions",
				size: "max-content",
				action: ({ id }, openDrawer) => (
					<ContextMenu displayShortcutsAs="icon">
						<ContextMenuItem
							title={t("Edit employee")}
							label={t("Edit employee")}
							action={openDrawer}
							icon="edit"
							shortcut="primary"
						/>
						<ContextMenuDeleteItem
							action={() =>
								webserviceApi.deleteEmployee(id).then(apiIsOK).then(invalidateOnSuccess).then(showDeletedToastOnSuccess)
							}
							title={t("Delete employee")}
							label={t("Delete employee")}
							heading={t("Delete")}
							labels={{ close: t("Close"), confirm: t("Confirm") }}
						>
							{t("Are you sure you want to delete this employee?")}
						</ContextMenuDeleteItem>
					</ContextMenu>
				),
			},
		];
	}, [invalidateOnSuccess, showDeletedToastOnSuccess, t]);

	return (
		<DrawerAutoDataList
			canCreate
			canUpdate
			service={{
				index: (config) => webserviceApi.listEmployees(mapFetcherConfigToAxiosConfig(config)),
				indexKey: [...employeeKeys.all],
				listKey: [...employeeKeys.lists()],
				show: (id) => webserviceApi.showEmployee(+id),
				showKey: (id) => [...employeeKeys.detail(id)],
			}}
			columns={dataListColumns}
			onRowClick={(e) => navigate(`${e.id}`)}
			renderCreateDrawer={(drawerProps) => (
				<EmployeeCreateDrawer
					{...drawerProps}
					heading={t("Add employee")}
					initialValues={emptyEmployee}
					onSubmit={(entityFormState) =>
						webserviceApi
							.createEmployee({
								...entityFormState,
								countryId: entityFormState.country?.id ?? null,
								userId: entityFormState.user?.id ?? null,
								branchId: entityFormState.branch?.id ?? null,
							})
							.then(apiIsOK)
							.then(invalidateOnSuccess)
							.then(showSavedToastOnSuccess)
					}
				/>
			)}
			renderUpdateDrawer={(entityResource, updateDrawerProps) => (
				<EmployeeUpdateDrawer
					{...updateDrawerProps}
					heading={t("Edit employee")}
					initialValues={entityResource}
					onSubmit={(entityFormState) =>
						webserviceApi
							.updateEmployee(entityResource.id, {
								...entityFormState,
								countryId: entityFormState.country?.id ?? null,
								userId: entityFormState.user?.id ?? null,
								branchId: entityFormState.branch?.id ?? null,
							})
							.then(apiIsOK)
							.then(invalidateOnSuccess)
							.then((isApiOk) => resetOnUpdate(isApiOk, employeeKeys.detail(entityResource.id)))
							.then(showSavedToastOnSuccess)
					}
				/>
			)}
			translations={{
				title: t("Employees"),
				empty: t("No employee available."),
			}}
		/>
	);
};
