import {
	AutoDataList,
	ContextMenu,
	ContextMenuAnchorItem,
	FormattedDate,
	IDataListColumn,
	mapFetcherConfigToAxiosConfig,
} from "@dgs/core";
import React, { FC, useMemo } from "react";
import { useTranslation } from "react-i18next";
import { ListOwnPayrollDto } from "~root/api_gen";
import { payrollKeys } from "~shared/api/payrolls/vacationKeys";
import { webserviceApi } from "~shared/api/webserviceApi";

export const OwnPayrollsList: FC = () => {
	const { t } = useTranslation();

	const dataListColumns: IDataListColumn<ListOwnPayrollDto>[] = useMemo(() => {
		return [
			{
				heading: t("Name"),
				valueKey: "name",
				type: "value",
			},
			{
				heading: t("Month"),
				valueKey: "id",
				type: "value",
				render: ({ year, month }) => (
					<FormattedDate date={new Date(year, month - 1, 1)} format={{ year: "numeric", month: "short" }} />
				),
			},
			{
				heading: "",
				type: "actions",
				size: "max-content",
				action: ({ id }) => (
					<ContextMenu displayShortcutsAs="icon">
						<ContextMenuAnchorItem
							title={t("Download payroll")}
							label={t("Download payroll")}
							href={`/api/backend/payrolls/${id}/own`}
							icon="file_download"
							shortcut="primary"
						/>
					</ContextMenu>
				),
			},
		];
	}, [t]);

	return (
		<AutoDataList
			columns={dataListColumns}
			fetcher={(config) => webserviceApi.listOwnPayrolls(mapFetcherConfigToAxiosConfig(config))}
			queryKey={payrollKeys.ownLists()}
			heading={t("My payrolls")}
			empty={t("No payrolls available.")}
		/>
	);
};
