import { DrawerAutoDataList, IDrawerDataListColumn, useCrudEntity } from "@dgs/core";
import React, { FC } from "react";
import { useTranslation } from "react-i18next";
import { UserCreateDto, UserListDto, UserUpdateDto } from "~root/api_gen";
import { usersService } from "~shared/api/users";
import { IUserFormState, UserDrawer } from "./user_drawer";

const mapToRequest = ({ roles, ...employee }: IUserFormState): UserCreateDto | UserUpdateDto => ({
	...employee,
	roleIds: roles.map((x) => x.id),
});

export const UserListPage: FC = () => {
	const { t } = useTranslation();
	const crudOperations = useCrudEntity(usersService, {
		entityName: t("User"),
		messages: {
			create: t("User was saved."),
			update: t("User was saved."),
			delete: t("User was deleted."),
		},
	});
	const emptyUser: IUserFormState = {
		firstName: "",
		lastName: "",
		//position: null,
		//company: null,
		email: "",
		roles: [],
	};

	const dataListColumns: IDrawerDataListColumn<UserListDto>[] = [
		{
			heading: t("First name"),
			valueKey: "firstName",
			type: "value",
		},
		{
			heading: t("Last name"),
			valueKey: "lastName",
			type: "value",
		},
		{
			heading: t("Email"),
			valueKey: "email",
			type: "value",
		},
		{
			heading: t("Roles"),
			valueKey: "roles",
			render: ({ roles }) => roles.map((x) => x.name).join(", "),
			type: "value",
		},
	];

	return (
		<DrawerAutoDataList
			canCreate
			canUpdate={true}
			columns={dataListColumns}
			service={usersService}
			renderCreateDrawer={(drawerProps) => (
				<UserDrawer
					{...drawerProps}
					heading={t("Create user")}
					initialValues={emptyUser}
					onSubmit={(entityFormState) => crudOperations.createEntity(mapToRequest(entityFormState))}
				/>
			)}
			renderUpdateDrawer={(entityResource, drawerProps) => {
				return (
					<UserDrawer
						{...drawerProps}
						heading={t("Edit user")}
						initialValues={entityResource}
						onSubmit={(entityFormState) =>
							crudOperations.updateEntity(entityResource.id, mapToRequest(entityFormState))
						}
					/>
				);
			}}
			translations={{
				title: t("Users"),
				empty: t("No users available"),
			}}
		/>
	);
};
