import {
	ContextMenu,
	ContextMenuDeleteItem,
	ContextMenuItem,
	DrawerAutoDataList,
	IDrawerDataListColumn,
	Loading,
	useCrudEntity,
} from "@dgs/core";
import { useQuery } from "@tanstack/react-query";
import React, { useMemo } from "react";
import { useTranslation } from "react-i18next";
import { PermissionDto, RoleCreateDto, RoleListDto, RoleShowDto, RoleUpdateDto } from "~root/api_gen";
import { IRoleFormState, RoleDrawer } from "~root/roles/RoleDrawer";
import { permissionService, roleService } from "~shared/api/roles/roleService";
import { IPermission } from "~shared/types/permission";
import {
	payrollsVisibilityOptionNames,
	timesVisibilityOptionNames,
	vacationsVisibilityOptionNames,
} from "./permissions";

const mapToFormState = (entity: RoleShowDto, permissions: IPermission[]): IRoleFormState => {
	return {
		name: entity.name,
		vacationsVisibility: entity.vacationsVisibility,
		timesVisibility: entity.timesVisibility,
		payrollsVisibility: entity.payrollsVisibility,
		permissions: permissions.reduce(
			(acc, permission) => {
				if (
					[...vacationsVisibilityOptionNames, ...timesVisibilityOptionNames, ...payrollsVisibilityOptionNames].includes(
						permission.name,
					)
				)
					return acc;
				return {
					...acc,
					[permission.name]: {
						...permission,
						enabled: entity.permissions.some((x) => x.id === permission.id),
					},
				};
			},
			{} as Record<string, IPermission & { enabled: boolean }>,
		),
	};
};
const mapToRequest = (values: IRoleFormState): RoleCreateDto | RoleUpdateDto => {
	return {
		name: values.name,
		vacationsVisibilityId: values.vacationsVisibility.id,
		timesVisibilityId: values.timesVisibility.id,
		payrollsVisibilityId: (values.payrollsVisibility as PermissionDto).id,
		permissions: Object.keys(values.permissions)
			.filter((x) => {
				if ([...vacationsVisibilityOptionNames, ...timesVisibilityOptionNames].includes(x)) return false;
				return values.permissions[x].enabled;
			})
			.map((x) => values.permissions[x].id as number),
	};
};
export const RoleListPage = () => {
	const { t } = useTranslation();
	const crudOperations = useCrudEntity(roleService, {
		entityName: t("Role"),
		messages: {
			create: t("Role was saved."),
			update: t("Role was saved."),
			delete: t("Role was deleted."),
		},
	});
	const dataListColumns: IDrawerDataListColumn<RoleListDto>[] = useMemo(
		() => [
			{
				heading: t("Name"),
				valueKey: "name",
				type: "value",
			},
			{
				heading: t("Users"),
				valueKey: "users",
				render: ({ users }) => users.map((x) => x.email).join(", "),
				type: "value",
			},
			{
				heading: "",
				type: "actions",
				size: "max-content",
				action: ({ id }, openDrawer) => (
					<ContextMenu displayShortcutsAs="icon">
						<ContextMenuItem
							title={t("Edit role")}
							label={t("Edit role")}
							action={openDrawer}
							icon="edit"
							shortcut="primary"
						/>
						<ContextMenuDeleteItem
							action={() => crudOperations.deleteEntity(id)}
							title={t("Delete role")}
							label={t("Delete role")}
							heading={t("Delete")}
							labels={{ close: t("Close"), confirm: t("Confirm") }}
						>
							{t("Are you sure you want to delete this role?")}
						</ContextMenuDeleteItem>
					</ContextMenu>
				),
			},
		],
		[crudOperations, t],
	);
	const { data = [], isLoading } = useQuery({
		queryKey: permissionService.indexKey,
		queryFn: () => permissionService.index().then((x) => x.data.data),
	});

	if (isLoading) {
		return <Loading />;
	}

	const initialValues: IRoleFormState = {
		name: "",
		vacationsVisibility: data.find((x) => x.name === "Permissions.Vacations.Visibility.All") as IPermission,
		timesVisibility: data.find((x) => x.name === "Permissions.Times.Visibility.All") as IPermission,
		payrollsVisibility: data.find((x) => x.name === "Permissions.Payrolls.Visibility.All"),
		permissions: data.reduce(
			(acc, permission) => {
				return {
					...acc,
					[permission.name]: {
						...permission,
						enabled: false,
					},
				};
			},
			{} as Record<string, IPermission & { enabled: boolean }>,
		),
	};

	return (
		<DrawerAutoDataList
			canCreate
			canUpdate
			columns={dataListColumns}
			service={roleService}
			renderCreateDrawer={(drawerProps) => (
				<RoleDrawer
					{...drawerProps}
					permissions={data}
					heading={t("Add role")}
					initialValues={initialValues}
					onSubmit={(entityFormState) => crudOperations.createEntity(mapToRequest(entityFormState))}
				/>
			)}
			renderUpdateDrawer={(entityResource, drawerProps) => (
				<RoleDrawer
					{...drawerProps}
					permissions={data}
					heading={t("Edit role")}
					initialValues={mapToFormState(entityResource, data)}
					onSubmit={(entityFormState) => crudOperations.updateEntity(entityResource.id, mapToRequest(entityFormState))}
				/>
			)}
			translations={{
				title: t("Roles"),
				empty: t("No role available."),
			}}
		/>
	);
};
