import { CrudDrawer, EntityDrawerProps, FormControl, SingleSelectControl, TextField } from "@dgs/core";
import React, { FC, useCallback } from "react";
import { useTranslation } from "react-i18next";
import styled from "styled-components";
import { EmployeeWithBalanceDto, VacationShowDto } from "~root/api_gen";
import { IVacationStatus } from "~shared/types";

type IVacationStatusFormStateApproved = {
	status: "approved";
};
export type IVacationStatusFormStateDeclined = {
	status: "declined";
	declineReason: string;
};
type IVacationStatusFormStateCancelled = {
	status: "cancelled";
};

export type IVacationStatusFormState =
	| IVacationStatusFormStateApproved
	| IVacationStatusFormStateDeclined
	| IVacationStatusFormStateCancelled;

interface Props extends EntityDrawerProps<IVacationStatusFormState> {
	currentStatus: IVacationStatus;
	vacationDayCount: number;
	employee: EmployeeWithBalanceDto;
}

const FormControlContent = styled.div`
	padding: ${({ theme }) => theme.spacing(2)} ${({ theme }) => theme.spacing(3)};
`;

const Divider = styled.div`
	border-bottom: 1px solid ${({ theme }) => theme.colors.palette.grey[40]};
`;

export const getInitialFormStateByStatus = ({ status }: VacationShowDto): IVacationStatusFormState => {
	if (status === "declined") {
		return {
			status: "approved",
		};
	}
	if (status === "pending") {
		return {
			status: "approved",
		};
	}
	if (status === "approved") {
		return {
			status: "cancelled",
		};
	}
	return {
		status,
	};
};

export const VacationStatusDrawer: FC<Props> = (props) => {
	const { t } = useTranslation();

	const validate = useCallback(
		(values: IVacationStatusFormState) => {
			const errors: { [K in keyof IVacationStatusFormState]?: any } = {};

			if (!values.status) {
				errors.status = t("Field is required");
			} else if (values.status === "declined" && !values.declineReason) {
				(errors as { [K in keyof IVacationStatusFormStateDeclined]?: any }).declineReason = t("Field is required");
			}

			return errors;
		},
		[t],
	);

	return (
		<CrudDrawer
			{...props}
			validate={validate}
			body={({ values, setValues }) => (
				<>
					<SingleSelectControl
						name="status"
						label={t("Status")}
						options={getOptionsByStatus(props.currentStatus)}
						getLabel={t}
						description={`${t("Current status")}: ${t(props.currentStatus)}`}
						value={values.status}
						onClear={() => void 0}
						onChange={(e) => {
							if (!e.target.value) return;
							if (e.target.value === "declined") {
								setValues({
									status: e.target.value,
									declineReason: "",
								});
							} else {
								setValues({
									status: e.target.value,
								});
							}
						}}
					/>
					{values.status === "declined" && <TextField name="declineReason" label={t("Decline reason")} required />}
					<FormControl name="employee" label={t("Employee")}>
						<FormControlContent>{`${props.employee.firstName} ${props.employee.lastName}`}</FormControlContent>
					</FormControl>
					{values.status === "approved" && (
						<FormControl name="vacationDays" label={t("Vacation days")}>
							<FormControlContent>
								{t("{{count}} days remaining for the current year", { count: props.employee.currentBalance })}
							</FormControlContent>
							<Divider />
							<FormControlContent>
								{t("{{count}} days will be subtracted", { count: props.vacationDayCount })}
							</FormControlContent>
						</FormControl>
					)}
					{props.currentStatus === "approved" && (values.status === "cancelled" || values.status === "declined") && (
						<FormControl name="vacationDays" label={t("Vacation days")}>
							<FormControlContent>
								{t("{{count}} days remaining for the current year", { count: props.employee.currentBalance })}
							</FormControlContent>
							<Divider />
							<FormControlContent>
								{t("{{count}} days will be restored", { count: props.vacationDayCount })}
							</FormControlContent>
						</FormControl>
					)}
				</>
			)}
		/>
	);
};

const getOptionsByStatus = (status: IVacationStatus): IVacationStatus[] => {
	if (status === "pending") return ["approved", "declined", "cancelled"];
	if (status === "cancelled") return ["approved", "declined"];
	if (status === "approved") return ["declined", "cancelled"];
	if (status === "declined") return ["cancelled", "approved"];
	return [];
};
