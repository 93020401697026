export const vacationsVisibilityOptionNames = [
	"Permissions.Vacations.Visibility.All",
	"Permissions.Vacations.Visibility.Team",
	"Permissions.Vacations.Visibility.Own",
];
export const timesVisibilityOptionNames = [
	"Permissions.Times.Visibility.All",
	"Permissions.Times.Visibility.Team",
	"Permissions.Times.Visibility.Own",
];
export const payrollsVisibilityOptionNames = [
	"Permissions.Payrolls.Visibility.All",
	"Permissions.Payrolls.Visibility.Own",
];
