import { PagedSingleSelectField } from "@dgs/core";
import React, { FC } from "react";
import { webserviceApi } from "~shared/api/webserviceApi";

const fetcher = (search: string, page: number) => webserviceApi.listCountryOptions(search, page).then((x) => x.data);

interface Props {
	name: string;
	label: string;
	required?: boolean;
}

export const CountrySelectField: FC<Props> = (props) => {
	return (
		<PagedSingleSelectField
			{...props}
			fetcher={fetcher}
			queryKey={["countries", "options"]}
			getLabel={(x) => x.translations[0].name}
		/>
	);
};
export const StateSelectField: FC<Props> = (props) => {
	return (
		<PagedSingleSelectField
			{...props}
			fetcher={(search) => webserviceApi.listStateOptions(search).then((x) => x.data)}
			queryKey={["states", "options"]}
			getLabel={(x) => x}
		/>
	);
};
