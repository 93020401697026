import { PagedSingleSelectField } from "@dgs/core";
import React, { FC } from "react";
import { usersService } from "~shared/api/users";

const fetcher = (search: string, page: number) => usersService.options(search, page).then((x) => x.data);

interface Props {
	name: string;
	label: string;
}
export const UserSelectField: FC<Props> = (props) => {
	return (
		<PagedSingleSelectField {...props} fetcher={fetcher} queryKey={usersService.optionsKey} getLabel={(x) => x.email} />
	);
};
