import { AxiosConfig, useDynamicConfig } from "@dgs/core";
import React, { FC } from "react";
import { useApiInterceptor } from "~shared/api/webserviceApi";

export const PersonalManagerAxiosConfig: FC = () => {
	const { apiUrl } = useDynamicConfig();

	useApiInterceptor();

	return <AxiosConfig apiUrl={apiUrl} />;
};
