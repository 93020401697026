import {
	ContextMenu,
	ContextMenuDeleteItem,
	ContextMenuItem,
	DrawerAutoDataList,
	EntityFetcher,
	FormattedDate,
	IDataListColumn,
	Icon,
	Id,
	SingleSelectField,
	apiIsOK,
	mapFetcherConfigToAxiosConfig,
	useEditDrawer,
} from "@dgs/core";
import React, { FC, useCallback, useMemo } from "react";
import { useTranslation } from "react-i18next";
import styled from "styled-components";
import { EmployeeListVacationDto, EmployeeOptionDto, EmployeeShowDto } from "~root/api_gen";
import { VacationDrawer } from "~root/vacations/VacationDrawer";
import { VacationStatusDrawer, getInitialFormStateByStatus } from "~root/vacations/VacationStatusDrawer";
import { employeeKeys } from "~shared/api/employee/employeeKeys";
import { useInvalidation, useUpdateReset } from "~shared/api/useInvalidation";
import { useSuccessToast } from "~shared/api/useSuccessToast";
import { vacationService } from "~shared/api/vacation/vacation_service";
import { vacationKeys } from "~shared/api/vacation/vacationKeys";
import { webserviceApi } from "~shared/api/webserviceApi";
import { ALL_VACATION_STATUS, IVacationFormState, IVacationStatus } from "~shared/types";

const CheckIcon = styled(Icon)`
	width: 24px;
	height: 24px;
`;

export const EmployeeVacations: FC<{ employee: EmployeeShowDto }> = ({ employee }) => {
	const { t } = useTranslation();
	const editDrawerProps = useEditDrawer();
	const statusDrawerProps = useEditDrawer();
	const resetOnUpdateSuccess = useUpdateReset();
	const invalidateListOnSuccess = useInvalidation([[...vacationKeys.lists()]]);
	const showVacationDeletedToastOnSuccess = useSuccessToast(t("Success"), t("Vacation successfully deleted"));
	const showVacationSavedToastOnSuccess = useSuccessToast(t("Success"), t("Vacation successfully saved"));
	const updateVacation = useCallback(
		async (id: Id, values: IVacationFormState) => {
			return webserviceApi
				.updateVacation(+id, {
					...values,
					employeeId: (values.employee as EmployeeOptionDto).id,
					standInId: (values.standIn ? (values.standIn as EmployeeOptionDto) : null)?.id ?? null,
				})
				.then(apiIsOK)
				.then(invalidateListOnSuccess)
				.then((isApiOk) => resetOnUpdateSuccess(isApiOk, [...vacationKeys.detail(id)]))
				.then(showVacationSavedToastOnSuccess);
		},
		[invalidateListOnSuccess, resetOnUpdateSuccess, showVacationSavedToastOnSuccess],
	);
	const createVacation = useCallback(
		async (values: IVacationFormState) => {
			return webserviceApi
				.createVacation({
					...values,
					employeeId: (values.employee as EmployeeOptionDto).id,
					standInId: (values.standIn ? (values.standIn as EmployeeOptionDto) : null)?.id ?? null,
				})
				.then(apiIsOK)
				.then(invalidateListOnSuccess)
				.then(showVacationSavedToastOnSuccess);
		},
		[invalidateListOnSuccess, showVacationSavedToastOnSuccess],
	);
	const deleteVacation = useCallback(
		async (id: Id) => {
			return webserviceApi
				.deleteVacation(+id)
				.then(apiIsOK)
				.then(invalidateListOnSuccess)
				.then(showVacationDeletedToastOnSuccess);
		},
		[invalidateListOnSuccess, showVacationDeletedToastOnSuccess],
	);
	const dataListColumns: IDataListColumn<EmployeeListVacationDto>[] = useMemo(() => {
		return [
			{
				heading: t("Start"),
				valueKey: "start",
				type: "value",
				render: ({ start }) => <FormattedDate date={start} />,
			},
			{
				heading: t("End"),
				valueKey: "end",
				type: "value",
				render: ({ end }) => <FormattedDate date={end} />,
			},
			{
				heading: t("Remark"),
				valueKey: "remark",
				type: "value",
			},
			{
				heading: t("Decline reason"),
				valueKey: "declineReason",
				type: "value",
			},
			{
				heading: t("Status"),
				valueKey: "status",
				type: "value",
				render: ({ status }) => t(status),
				filter: {
					operators: ["="],
					renderValueField: ({ fieldName }) => (
						<SingleSelectField options={[...ALL_VACATION_STATUS]} label={t("Status")} name={fieldName} getLabel={t} />
					),
					renderActiveColumnFilterValue: (value: IVacationStatus) => <span>{t(value)}</span>,
				},
			},
			{
				heading: t("Half day"),
				valueKey: "halfDay",
				type: "value",
				render: ({ halfDay }) => (halfDay ? <CheckIcon icon="check" /> : ""),
			},
			{
				heading: t("Stand in"),
				valueKey: "standIn.id",
				render: ({ standIn }) => (standIn ? `${standIn.firstName} ${standIn.lastName}` : null),
				type: "value",
			},
			{
				heading: "",
				type: "actions",
				size: "max-content",
				action: ({ id, status }) => (
					<ContextMenu displayShortcutsAs="icon">
						{status === "pending" && (
							<ContextMenuItem
								title={t("Edit vacation")}
								label={t("Edit vacation")}
								action={() => editDrawerProps.handleOpen(id)}
								icon="edit"
								shortcut="primary"
							/>
						)}
						<ContextMenuItem
							title={t("Update status")}
							label={t("Update status")}
							action={() => {
								statusDrawerProps.handleOpen(id);
							}}
							icon="check"
							shortcut="primary"
						/>
						{status === "pending" && (
							<ContextMenuDeleteItem
								action={() => deleteVacation(id)}
								title={t("Delete vacation")}
								label={t("Delete vacation")}
								heading={t("Delete")}
								labels={{ close: t("Close"), confirm: t("Confirm") }}
							>
								{t("Are you sure you want to delete this vacation?")}
							</ContextMenuDeleteItem>
						)}
					</ContextMenu>
				),
			},
		];
	}, [deleteVacation, editDrawerProps, statusDrawerProps, t]);
	const emptyVacation: IVacationFormState = {
		start: new Date().toISOString(),
		end: new Date().toISOString(),
		employee,
		status: "pending",
		standIn: null,
	};

	employee.vacationAccount;
	return (
		<>
			<DrawerAutoDataList
				canCreate
				columns={dataListColumns}
				service={{
					index: (config) => webserviceApi.listEmployeeVacations(employee.id, mapFetcherConfigToAxiosConfig(config)),
					indexKey: [...employeeKeys.vacationList(employee.id)],
					listKey: [...employeeKeys.vacationList(employee.id), "lists"],
					show: (id) => webserviceApi.showVacation(+id),
					showKey: (id) => [...vacationKeys.detail(id)],
				}}
				onRowClick={({ id, status }) => {
					if (status === "pending") {
						return editDrawerProps.handleOpen(id);
					}
				}}
				translations={{
					title: t("Vacations"),
					empty: t("No vacation available."),
				}}
				renderCreateDrawer={(drawerProps) => (
					<VacationDrawer
						{...drawerProps}
						heading={t("Create vacation")}
						initialValues={emptyVacation}
						onSubmit={createVacation}
					/>
				)}
			/>
			{editDrawerProps.editEntityId && (
				<EntityFetcher
					id={editDrawerProps.editEntityId}
					service={vacationService}
					renderDrawer={(entityResource, isLoading, reset) =>
						entityResource &&
						!isLoading && (
							<VacationDrawer
								{...editDrawerProps}
								heading={t("Edit vacation")}
								initialValues={entityResource}
								onSubmit={(values) => updateVacation(entityResource.id, values)}
								onClosed={() => {
									editDrawerProps.onClosed();
									reset();
								}}
							/>
						)
					}
				/>
			)}
			{statusDrawerProps.editEntityId && (
				<EntityFetcher
					id={statusDrawerProps.editEntityId}
					service={vacationService}
					renderDrawer={(entityResource, isLoading) =>
						entityResource &&
						!isLoading && (
							<VacationStatusDrawer
								{...statusDrawerProps}
								heading={t("Update status")}
								initialValues={getInitialFormStateByStatus(entityResource)}
								currentStatus={entityResource.status}
								employee={entityResource.employee}
								vacationDayCount={entityResource.vacationDayCount}
								onSubmit={async (values) =>
									webserviceApi
										.updateVacationStatus(+entityResource.id, values)
										.then(apiIsOK)
										.then(invalidateListOnSuccess)
										.then((apiIsOk) =>
											resetOnUpdateSuccess(apiIsOk, [
												...vacationKeys.detail(entityResource.id),
												...employeeKeys.detail(entityResource.employee.id),
											]),
										)
										.then(showVacationSavedToastOnSuccess)
								}
							/>
						)
					}
				/>
			)}
		</>
	);
};
