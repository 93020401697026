import React, { FC } from "react";
import { Route, Routes } from "react-router-dom";
import { ActivityList } from "~root/activities/ActivityList";
import { DefaultContentLayout, DetailsPageContentLayout } from "~shared/layout";

export const ActivityPage: FC = () => {
	return (
		<Routes>
			<Route
				index
				element={
					<DefaultContentLayout>
						<ActivityList />
					</DefaultContentLayout>
				}
			/>
			<Route
				path=":id/*"
				element={
					<DetailsPageContentLayout>
						<div />
					</DetailsPageContentLayout>
				}
			/>
		</Routes>
	);
};
