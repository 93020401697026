import { CrudDrawer, CrudDrawerProps, DateTimeField, PagedSingleSelectField } from "@dgs/core";
import React, { FC } from "react";
import { useTranslation } from "react-i18next";
import { ActivityOptionDto, ProjectOptionDto } from "~root/api_gen";
import { activityKeys } from "~shared/api/activities/activityKeys";
import { projectKeys } from "~shared/api/projects/projectKeys";
import { webserviceApi } from "~shared/api/webserviceApi";

export interface TimeFormState {
	start: string;
	end?: string | null;
	activity: ActivityOptionDto | null;
	project: ProjectOptionDto | null;
}

export const OwnTimeDrawer: FC<Omit<CrudDrawerProps<TimeFormState>, "body" | "validate">> = (props) => {
	const { t } = useTranslation();

	return (
		<CrudDrawer
			{...props}
			validate={(values) => {
				const errors: { [K in keyof TimeFormState]?: any } = {};

				if (!values.activity) {
					errors.activity = t("Required field");
				}
				if (!values.project) {
					errors.project = t("Required field");
				}
				return errors;
			}}
			body={
				<>
					<DateTimeField name="start" label={t("Start")} />
					<DateTimeField name="end" label={t("End")} />
					<PagedSingleSelectField<ProjectOptionDto>
						name="project"
						label={t("Project")}
						fetcher={(search, page) => webserviceApi.listProjectOptions(search, page).then((x) => x.data)}
						queryKey={[...projectKeys.lists()]}
						getLabel={(x) => x.name}
					/>
					<PagedSingleSelectField<ActivityOptionDto>
						name="activity"
						label={t("Activity")}
						fetcher={(search, page) => webserviceApi.listActivityOptions(search, page).then((x) => x.data)}
						queryKey={[...activityKeys.options()]}
						getLabel={(x) => x.name}
					/>
				</>
			}
		/>
	);
};
