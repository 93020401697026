import { CheckboxField, CrudDrawer, DateField, EntityDrawerProps } from "@dgs/core";
import React, { FC, useCallback } from "react";
import { useTranslation } from "react-i18next";
import { BranchOptionDto } from "~root/api_gen";
import { BranchSelectField } from "~root/branches/branch_select_field";

export type IOffDayFormState = {
	date: string;
	halfDay: boolean;
	branch: BranchOptionDto | null;
};
export const OffDayDrawer: FC<EntityDrawerProps<IOffDayFormState>> = (props) => {
	const { t } = useTranslation();

	const validate = useCallback(
		(values: IOffDayFormState) => {
			const errors: { [K in keyof IOffDayFormState]?: any } = {};

			if (!values.date) {
				errors.date = t("Field is required");
			}
			if (!values.branch) {
				errors.branch = t("Field is required");
			}

			return errors;
		},
		[t],
	);

	return (
		<CrudDrawer
			{...props}
			validate={validate}
			body={
				<>
					<DateField name="date" label={t("Date")} required />
					<CheckboxField name="halfDay" label={t("Half day")} />
					<BranchSelectField name="branch" label={t("Branch")} />
				</>
			}
		/>
	);
};
