import {
	Button,
	Card,
	CardBody,
	CardFooter,
	CardHeader,
	CardHeaderActions,
	CardHeading,
	Icon,
	Loading,
	Section,
	TextLink,
	Trigger,
	apiIsOK,
	useFormatDate,
} from "@dgs/core";
import { useQuery, useQueryClient } from "@tanstack/react-query";
import React, { FC } from "react";
import { useTranslation } from "react-i18next";
import styled from "styled-components";
import { VacationList, VacationListItem, VacationListItemEmpty } from "~root/EmployeeDashboard/VacationList";
import { OwnVacationDrawer } from "~root/vacations/OwnVacationDrawer";
import { webserviceApi } from "~shared/api/webserviceApi";
import { BoxFooter, DaysLabel, RemainingVacationDays, WarningBox } from "./box";

const TAKE_N_VACATION_REQUESTS = 5;

const StyledCard = styled(Card)`
	display: grid;
	grid-template-rows: min-content max-content 1fr;
`;
const StyledCardFooter = styled(CardFooter)`
	align-self: end;
`;
const StyledSection = styled(CardBody)`
	display: grid;
	grid-template-columns: 1fr 1fr;
	gap: ${({ theme }) => theme.spacing(2)};
	align-items: stretch;
`;

const StyledBoxFooter = styled(BoxFooter)`
	padding: ${({ theme }) => theme.spacing(4)} ${({ theme }) => theme.spacing(4)};
`;

const Divider = styled.div`
	border-bottom: 1px solid ${({ theme }) => theme.colors.palette.grey[20]};
	margin-bottom: ${({ theme }) => theme.spacing(8)};
`;
export const EmployeeDashboardPage = () => {
	const { data, isLoading } = useQuery({
		queryKey: ["employee-dashboard"],
		staleTime: 0,
		queryFn: () => webserviceApi.showEmployeeDashboard().then((x) => x.data),
	});
	const formatDate = useFormatDate();
	const { t } = useTranslation();

	if (isLoading || !data) {
		return <Loading />;
	}

	const firstForRequests = data.nextVacations.slice(0, TAKE_N_VACATION_REQUESTS);
	const remainingRequests = data.nextVacations.slice(TAKE_N_VACATION_REQUESTS, data.nextVacations.length);

	return (
		<Section>
			<StyledSection>
				<StyledCard>
					<CardHeader>
						<CardHeading>{t("Current year")}</CardHeading>
						<CardHeaderActions>
							<TextLink to="../vacations/own">{t("View all")}</TextLink>
						</CardHeaderActions>
					</CardHeader>
					<CardBody>
						<RemainingVacationDays>
							{data.availableVacationDays}
							<DaysLabel>/ {t("{{count}} days available", { count: data.totalVacationDays })}</DaysLabel>
						</RemainingVacationDays>
						{data.previousYearVacation && (
							<WarningBox>
								{t("+{{count}} days previous year's leave up to and including {{date}}", {
									count: data.previousYearVacation.days,
									date: formatDate(data.previousYearVacation.expirationDate),
								})}
							</WarningBox>
						)}
						<Divider />
						<RemainingVacationDays>
							{data.pendingVacationDays}
							<DaysLabel>{t("days pending", { count: data.pendingVacationDays })}</DaysLabel>
						</RemainingVacationDays>
					</CardBody>
					<StyledCardFooter>
						<Trigger render={(props) => <DashboardCreateVacationRequestDrawer {...props} />}>
							{(props) => (
								<Button
									{...props}
									type="button"
									color="primary"
									prefix={<Icon icon={"plus"} />}
									title={t("Create vacation request")}
								>
									{t("Create vacation request")}
								</Button>
							)}
						</Trigger>
					</StyledCardFooter>
				</StyledCard>
				<Card>
					<CardHeader>
						<CardHeading>{t("Upcoming vacation requests")}</CardHeading>
					</CardHeader>
					{data.nextVacations.length > 0 ? (
						<VacationList>
							{firstForRequests.map((x) => (
								<VacationListItem key={x.id} vacation={x} />
							))}
						</VacationList>
					) : (
						<VacationListItemEmpty>{t("No vacation available.")}</VacationListItemEmpty>
					)}
					{!!remainingRequests.length && (
						<StyledBoxFooter>
							<TextLink to="vacations">
								{t("+ {{remainingRequests}} more to view", { remainingRequests: remainingRequests.length })}
							</TextLink>
						</StyledBoxFooter>
					)}
				</Card>
			</StyledSection>
		</Section>
	);
};

const DashboardCreateVacationRequestDrawer: FC<{ open: boolean; handleClose: () => void }> = ({
	open,
	handleClose,
}) => {
	const { t } = useTranslation();
	const queryClient = useQueryClient();

	return (
		<OwnVacationDrawer
			heading={t("Create vacation request")}
			open={open}
			onClose={handleClose}
			onClosed={() => {
				queryClient.invalidateQueries({
					queryKey: ["employee-dashboard"],
				});
			}}
			initialValues={{
				start: new Date().toISOString(),
				end: new Date().toISOString(),
				status: "pending",
				standIn: null,
			}}
			onSubmit={({ standIn, ...values }) =>
				webserviceApi
					.createOwnVacation({
						...values,
						standInId: standIn?.id ?? null,
					})
					.then(apiIsOK)
					.then((isApiOk) => {
						isApiOk && handleClose();
						return isApiOk;
					})
			}
		/>
	);
};
