import {
	AutoDataList,
	ContextMenu,
	ContextMenuDeleteItem,
	ContextMenuItem,
	EntityFetcher,
	IDataListColumn,
	IconButton,
	Id,
	Trigger,
	apiIsOK,
	mapFetcherConfigToAxiosConfig,
	useEditDrawer,
} from "@dgs/core";
import { useQueryClient } from "@tanstack/react-query";
import React, { useCallback, useMemo } from "react";
import { useTranslation } from "react-i18next";
import { ActivityOptionDto, EmployeeOptionDto, ProjectOptionDto, TimeShowDto } from "~root/api_gen";
import { TimeDrawer, TimeFormState } from "~root/times/TimeDrawer";
import { timeKeys } from "~shared/api/times/timeKeys";
import { webserviceApi } from "~shared/api/webserviceApi";

export const TimesList = () => {
	const { t } = useTranslation();
	const drawerProps = useEditDrawer();
	const queryClient = useQueryClient();
	const dataListColumns: IDataListColumn<TimeShowDto>[] = useMemo(() => {
		return [
			{
				heading: t("Start"),
				valueKey: "start",
				type: "value",
			},
			{
				heading: t("End"),
				valueKey: "end",
				type: "value",
			},
			{
				heading: t("Employee"),
				valueKey: "employee.firstName",
				render: ({ employee }) => `${employee.firstName} ${employee.lastName}`,
				type: "value",
			},
			{
				heading: t("Project"),
				valueKey: "project.name",
				type: "value",
			},
			{
				heading: t("Activity"),
				valueKey: "activity.name",
				type: "value",
			},
			{
				heading: "",
				type: "actions",
				size: "max-content",
				action: ({ id }) => (
					<ContextMenu displayShortcutsAs="icon">
						<ContextMenuItem
							title={t("Edit time")}
							label={t("Edit time")}
							action={async () => {
								drawerProps.handleOpen(id);
							}}
							icon="edit"
							shortcut="primary"
						/>
						<ContextMenuDeleteItem
							action={async () => {
								const res = await webserviceApi.deleteTime(+id);
								if (apiIsOK(res)) {
									void queryClient.invalidateQueries({
										queryKey: timeKeys.lists(),
									});
								}
							}}
							title={t("Delete time")}
							label={t("Delete time")}
							heading={t("Delete")}
							labels={{ close: t("Close"), confirm: t("Confirm") }}
						>
							{t("Are you sure you want to delete this time?")}
						</ContextMenuDeleteItem>
					</ContextMenu>
				),
			},
		];
	}, [drawerProps, queryClient, t]);
	const emptyTime: TimeFormState = {
		start: new Date().toISOString(),
		end: null,
		employee: null,
		activity: null,
		project: null,
	};
	const updateTime = useCallback(
		async (id: Id, values: TimeFormState) => {
			const res = await webserviceApi.updateTime(+id, {
				start: values.start,
				end: values.end,
				employeeId: (values.employee as EmployeeOptionDto).id,
				activityId: (values.activity as ActivityOptionDto).id,
				projectId: (values.project as ProjectOptionDto).id,
			});
			if (apiIsOK(res)) {
				void queryClient.invalidateQueries({
					queryKey: timeKeys.lists(),
				});
				return true;
			}
			return false;
		},
		[queryClient],
	);
	const createTime = useCallback(
		async (values: TimeFormState) => {
			if (!values.employee) throw "Missing validation: employee";
			const res = await webserviceApi.createTime({
				start: values.start,
				end: values.end,
				employeeId: (values.employee as EmployeeOptionDto).id,
				activityId: (values.activity as ActivityOptionDto).id,
				projectId: (values.project as ProjectOptionDto).id,
			});
			if (apiIsOK(res)) {
				void queryClient.invalidateQueries({
					queryKey: timeKeys.lists(),
				});
				return true;
			}
			return false;
		},
		[queryClient],
	);

	return (
		<>
			<AutoDataList
				name="allTimes"
				heading={t("Times")}
				empty={t("No times available.")}
				columns={dataListColumns}
				queryKey={timeKeys.lists()}
				fetcher={(config) => webserviceApi.listTimes(mapFetcherConfigToAxiosConfig(config))}
				headerActions={
					<>
						<Trigger
							render={(props) => (
								<TimeDrawer
									open={props.open}
									onClose={props.handleClose}
									heading={t("Create time")}
									initialValues={emptyTime}
									onSubmit={createTime}
								/>
							)}
						>
							{(props) => (
								<IconButton
									{...props}
									type="button"
									color="primary"
									size="small"
									title={t("Create time")}
									icon="plus"
								/>
							)}
						</Trigger>
					</>
				}
			/>
			{drawerProps.editEntityId != null && (
				<EntityFetcher
					id={drawerProps.editEntityId}
					fetcher={(id) => webserviceApi.showTime(+id).then((x) => x.data.data)}
					getQueryKey={(id) => timeKeys.detail(id)}
					renderDrawer={(entity, isLoading, reset) =>
						entity &&
						!isLoading && (
							<TimeDrawer
								{...drawerProps}
								initialValues={entity}
								heading={t("Edit time")}
								onClosed={reset}
								onSubmit={(values) => updateTime(entity.id, values)}
							/>
						)
					}
				/>
			)}
		</>
	);
};
