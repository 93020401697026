import { ButtonTab, ButtonTabs, Loading, Section } from "@dgs/core";
import { useQuery } from "@tanstack/react-query";
import React from "react";
import { useTranslation } from "react-i18next";
import { Navigate, Route, Routes, useParams } from "react-router-dom";
import styled from "styled-components";
import { EmployeeAccountCalculationCard } from "~root/employees/detailsV2/EmployeeAccountCalculationCard";
import { EmployeeAccounts } from "~root/employees/detailsV2/EmployeeAccounts";
import { EmployeeHighlightCard } from "~root/employees/detailsV2/EmployeeHighlightCard";
import { EmployeeOverview } from "~root/employees/detailsV2/EmployeeOverview";
import { EmployeeTimes } from "~root/employees/detailsV2/EmployeeTimes";
import { EmployeeVacations } from "~root/employees/detailsV2/EmployeeVacations";
import { employeeKeys } from "~shared/api/employee/employeeKeys";
import { webserviceApi } from "~shared/api/webserviceApi";

const Wrapper = styled.div`
	display: grid;
	grid-template-columns: minmax(350px, 1fr) 3fr;
	padding: 20px;
	gap: ${({ theme }) => theme.spacing(4)};
`;
const StyledSection = styled(Section)`
	max-width: 85rem;
`;
const Column = styled.div`
	display: grid;
	gap: ${({ theme }) => theme.spacing(4)};
`;
const EmployeeDetailsContent = styled.div`
	display: grid;
	grid-template-rows: min-content 1fr;
	gap: ${({ theme }) => theme.spacing(4)};
	align-items: start;
`;

export const EmployeeDetailsPageV2 = () => {
	const { id = "" } = useParams();
	const { t } = useTranslation();
	const { data: employee, isLoading } = useQuery({
		queryKey: employeeKeys.detail(id),
		//queryFn: () => webserviceApi.getEmployeeDetails(id),
		queryFn: () => webserviceApi.showEmployee(+id).then((x) => x.data.data),
	});

	if (isLoading || !employee) {
		return <Loading />;
	}

	return (
		<StyledSection>
			<Wrapper>
				<Column>
					<EmployeeHighlightCard employee={employee} />
					<EmployeeAccountCalculationCard employee={employee} />
				</Column>
				<EmployeeDetailsContent>
					<ButtonTabs>
						<ButtonTab to="overview">{t("Overview")}</ButtonTab>
						<ButtonTab to="times">{t("Times")}</ButtonTab>
						<ButtonTab to="vacations">{t("Vacations")}</ButtonTab>
						<ButtonTab to="accounts">{t("Accounts")}</ButtonTab>
					</ButtonTabs>
					<Routes>
						<Route index element={<Navigate replace to={"overview"} />} />
						<Route path="overview" element={<EmployeeOverview employee={employee} />} />
						<Route path="times" element={<EmployeeTimes employee={employee} />} />
						<Route path="vacations" element={<EmployeeVacations employee={employee} />} />
						<Route path="accounts" element={<EmployeeAccounts employee={employee} />} />
					</Routes>
				</EmployeeDetailsContent>
			</Wrapper>
		</StyledSection>
	);
};
