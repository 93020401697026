import React, { FC } from "react";
import styled from "styled-components";
import { EmployeeShowDto } from "~root/api_gen";

const Wrapper = styled.div`
	display: grid;
	justify-content: center;
	grid-template-columns: 1fr;
	margin: ${({ theme }) => theme.spacing(4)} 0;
`;
export const EmployeeHighlight: FC<{ employee: EmployeeShowDto }> = ({ employee }) => {
	return (
		<Wrapper>
			<EmployeeImageWithName employee={employee} />
			{/*<EmployeeStats employee={employee}/>*/}
		</Wrapper>
	);
};

const Row = styled.div`
	margin-bottom: ${({ theme }) => theme.spacing(6)};
	display: flex;
	flex-direction: column;
	gap: ${({ theme }) => theme.spacing(2)};
	align-items: center;
`;
const EmployeeImage = styled.div`
	width: 80px;
	height: 80px;
	border-radius: 24px;
	background-color: ${({ theme }) => theme.colors.palette.primary.main.base};
	margin-bottom: 8px;
`;
const EmployeeName = styled.div`
	font-size: 1.25rem;
	display: flex;
	gap: ${({ theme }) => theme.spacing(2)};
`;
const EmployeeImageWithName: FC<{ employee: EmployeeShowDto }> = ({ employee }) => {
	return (
		<Row>
			<EmployeeImage />
			<EmployeeName>{`${employee.firstName} ${employee.lastName}`}</EmployeeName>
		</Row>
	);
};
