import { LaravelResponse, createCrudService, keyToUrl } from "@dgs/core";
import axios from "axios";
import { RoleCreateDto, RoleListDto, RoleOptionDto, RoleShowDto, RoleUpdateDto } from "~root/api_gen";
import { IPermission } from "~shared/types/permission";

export const roleService = createCrudService<RoleListDto, RoleOptionDto, RoleShowDto, RoleCreateDto, RoleUpdateDto>([
	"roles",
]);

export const permissionService = {
	indexKey: ["permissions"],
	index: () => axios.get<LaravelResponse<IPermission[]>>(keyToUrl(["permissions"])),
};
