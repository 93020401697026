import {
	ContextMenu,
	ContextMenuDeleteItem,
	ContextMenuItem,
	DrawerAutoDataList,
	IDrawerDataListColumn,
	apiIsOK,
	mapFetcherConfigToAxiosConfig,
} from "@dgs/core";
import React, { useMemo } from "react";
import { useTranslation } from "react-i18next";
import { ActivityDrawer, IActivityFormState } from "~root/activities/ActivityDrawer";
import { ActivityListDto } from "~root/api_gen";
import { activityKeys } from "~shared/api/activities/activityKeys";
import { useInvalidation, useUpdateReset } from "~shared/api/useInvalidation";
import { useSuccessToast } from "~shared/api/useSuccessToast";
import { webserviceApi } from "~shared/api/webserviceApi";

export const ActivityList = () => {
	const { t } = useTranslation();
	const invalidateOnSuccess = useInvalidation([[...activityKeys.lists()]]);
	const resetOnUpdate = useUpdateReset();
	const showSavedToastOnSuccess = useSuccessToast(t("Activity saved"), t("Activity successfully saved"));
	const showDeletedToastOnSuccess = useSuccessToast(t("Activity deleted"), t("Activity successfully deleted"));
	const emptyActivity: IActivityFormState = {
		name: "",
		description: "",
	};
	const dataListColumns: IDrawerDataListColumn<ActivityListDto>[] = useMemo(
		() => [
			{
				heading: t("Name"),
				valueKey: "name",
				type: "value",
			},
			{
				heading: t("Description"),
				valueKey: "description",
				type: "value",
			},
			{
				heading: "",
				type: "actions",
				size: "max-content",
				action: ({ id }, openDrawer) => (
					<ContextMenu displayShortcutsAs="icon">
						<ContextMenuItem
							title={t("Edit activity")}
							label={t("Edit activity")}
							action={openDrawer}
							icon="edit"
							shortcut="primary"
						/>
						<ContextMenuDeleteItem
							action={() =>
								webserviceApi.deleteActivity(id).then(apiIsOK).then(invalidateOnSuccess).then(showDeletedToastOnSuccess)
							}
							title={t("Delete activity")}
							label={t("Delete activity")}
							heading={t("Delete")}
							labels={{ close: t("Close"), confirm: t("Confirm") }}
						>
							{t("Are you sure you want to delete this activity?")}
						</ContextMenuDeleteItem>
					</ContextMenu>
				),
			},
		],
		[invalidateOnSuccess, showDeletedToastOnSuccess, t],
	);

	return (
		<DrawerAutoDataList
			canCreate
			canUpdate
			columns={dataListColumns}
			service={{
				index: (config) => webserviceApi.listActivities(mapFetcherConfigToAxiosConfig(config)),
				indexKey: [...activityKeys.all],
				listKey: [...activityKeys.lists()],
				show: (id) => webserviceApi.showActivity(+id),
				showKey: (id) => [...activityKeys.detail(id)],
			}}
			renderCreateDrawer={(drawerProps) => (
				<ActivityDrawer
					{...drawerProps}
					heading={t("Add activity")}
					initialValues={emptyActivity}
					onSubmit={(entityFormState) =>
						webserviceApi
							.createActivity({
								name: entityFormState.name,
								description: entityFormState.description,
							})
							.then(apiIsOK)
							.then(invalidateOnSuccess)
							.then(showSavedToastOnSuccess)
					}
				/>
			)}
			renderUpdateDrawer={(entityResource, drawerProps) => (
				<ActivityDrawer
					{...drawerProps}
					heading={t("Edit activity")}
					initialValues={entityResource}
					onSubmit={(entityFormState) =>
						webserviceApi
							.updateActivity(entityResource.id, {
								name: entityFormState.name,
								description: entityFormState.description,
							})
							.then(apiIsOK)
							.then(invalidateOnSuccess)
							.then((isApiOk) => resetOnUpdate(isApiOk, activityKeys.detail(entityResource.id)))
							.then(showSavedToastOnSuccess)
					}
				/>
			)}
			translations={{
				title: t("Activities"),
				empty: t("No activity available."),
			}}
		/>
	);
};
