import { createCrudService } from "@dgs/core";
import { OffDayCreateDto, OffDayListDto, OffDayShowDto, OffDayUpdateDto } from "~root/api_gen";

export const offDayService = createCrudService<
	OffDayListDto,
	OffDayListDto,
	OffDayShowDto,
	OffDayCreateDto,
	OffDayUpdateDto
>(["off-days"]);
