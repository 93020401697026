import { CrudDrawer, EntityDrawerProps } from "@dgs/core";
import React, { FC, useCallback } from "react";
import { useTranslation } from "react-i18next";
import { FileInputField } from "~root/payrolls/FileInputField";

export interface IPayrollCreateFormState {
	files: File[];
}

export const PayrollCreateDrawer: FC<EntityDrawerProps<IPayrollCreateFormState>> = (props) => {
	const { t } = useTranslation();
	const validate = useCallback(
		(values: IPayrollCreateFormState) => {
			const errors: { [K in keyof IPayrollCreateFormState]?: any } = {};

			if (!values.files.length) {
				errors.files = t("Field is required");
			}
			return errors;
		},
		[t],
	);

	return (
		<CrudDrawer
			{...props}
			validate={validate}
			body={
				<>
					<FileInputField name="files" label={t("Files")} multiple />
				</>
			}
		/>
	);
};
