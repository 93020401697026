import {
	ContextMenu,
	ContextMenuDeleteItem,
	ContextMenuItem,
	DrawerAutoDataList,
	EntityFetcher,
	FormattedDate,
	IBaseEntityResource,
	IDataListColumn,
	Icon,
	Id,
	SingleSelectField,
	apiIsOK,
	useCrudEntity,
	useEditDrawer,
} from "@dgs/core";
import React, { useCallback, useMemo } from "react";
import { useTranslation } from "react-i18next";
import styled from "styled-components";
import { EmployeeOptionDto, OwnVacationDto, VacationUpdateOwnDto } from "~root/api_gen";
import { VacationDrawer } from "~root/vacations/VacationDrawer";
import { useInvalidation, useUpdateReset } from "~shared/api/useInvalidation";
import { useSuccessToast } from "~shared/api/useSuccessToast";
import { vacationOwnService, vacationService } from "~shared/api/vacation/vacation_service";
import { vacationKeys } from "~shared/api/vacation/vacationKeys";
import { webserviceApi } from "~shared/api/webserviceApi";
import { ALL_VACATION_STATUS, IVacationStatus } from "~shared/types";
import { IVacationFormState, OwnVacationDrawer } from "./OwnVacationDrawer";

const CheckIcon = styled(Icon)`
	width: 24px;
	height: 24px;
`;

export const OwnVacationList = () => {
	const { t } = useTranslation();
	const crudOperations = useCrudEntity<VacationUpdateOwnDto, IBaseEntityResource>(vacationOwnService, {
		entityName: t("Vacation"),
		messages: {
			create: t("Vacation was saved."),
			update: t("Vacation was saved."),
			delete: t("Vacation was deleted."),
		},
	});
	const emptyVacation: IVacationFormState = {
		start: "",
		end: "",
		remark: "",
		declineReason: null,
		status: "pending",
		halfDay: false,
		standIn: null,
	};
	const editDrawerProps = useEditDrawer();
	const invalidateListOnSuccess = useInvalidation([[...vacationKeys.lists()]]);
	const resetOnUpdateSuccess = useUpdateReset();
	const showVacationSavedToastOnSuccess = useSuccessToast(t("Success"), t("Vacation successfully saved"));
	const updateVacation = useCallback(
		async (id: Id, values: IVacationFormState) => {
			return webserviceApi
				.updateOwnVacation(+id, {
					...values,
					standInId: (values.standIn ? (values.standIn as EmployeeOptionDto) : null)?.id ?? null,
				})
				.then(apiIsOK)
				.then(invalidateListOnSuccess)
				.then((isApiOk) => resetOnUpdateSuccess(isApiOk, [...vacationKeys.detail(id)]))
				.then(showVacationSavedToastOnSuccess);
		},
		[invalidateListOnSuccess, resetOnUpdateSuccess, showVacationSavedToastOnSuccess],
	);
	const dataListColumns: IDataListColumn<OwnVacationDto>[] = useMemo(
		() => [
			{
				heading: t("Start"),
				valueKey: "start",
				type: "value",
				render: ({ start }) => <FormattedDate date={start} />,
			},
			{
				heading: t("End"),
				valueKey: "end",
				type: "value",
				render: ({ end }) => <FormattedDate date={end} />,
			},
			{
				heading: t("Remark"),
				valueKey: "remark",
				type: "value",
			},
			{
				heading: t("Decline reason"),
				valueKey: "declineReason",
				type: "value",
			},
			{
				heading: t("Status"),
				valueKey: "status",
				type: "value",
				render: ({ status }) => t(status),
				filter: {
					operators: ["="],
					renderValueField: ({ fieldName }) => (
						<SingleSelectField options={[...ALL_VACATION_STATUS]} label={t("Status")} name={fieldName} getLabel={t} />
					),
					renderActiveColumnFilterValue: (value: IVacationStatus) => <span>{t(value)}</span>,
				},
			},
			{
				heading: t("Half day"),
				valueKey: "halfDay",
				type: "value",
				render: ({ halfDay }) => (halfDay ? <CheckIcon icon="check" /> : ""),
			},
			{
				heading: t("Stand in"),
				valueKey: "standIn.id",
				render: ({ standIn }) => (standIn ? `${standIn.firstName} ${standIn.lastName}` : null),
				type: "value",
			},
			{
				heading: "",
				type: "actions",
				size: "max-content",
				action: ({ id, status }) =>
					status === "pending" ? (
						<ContextMenu displayShortcutsAs="icon">
							<ContextMenuItem
								title={t("Edit vacation")}
								label={t("Edit vacation")}
								action={() => editDrawerProps.handleOpen(id)}
								icon="edit"
								shortcut="primary"
							/>
							<ContextMenuDeleteItem
								action={() => crudOperations.deleteEntity(id)}
								title={t("Delete vacation")}
								label={t("Delete vacation")}
								heading={t("Delete")}
								labels={{ close: t("Close"), confirm: t("Confirm") }}
							>
								{t("Are you sure you want to delete this vacation?")}
							</ContextMenuDeleteItem>
						</ContextMenu>
					) : null,
			},
		],
		[crudOperations, editDrawerProps, t],
	);

	return (
		<>
			<DrawerAutoDataList
				canCreate
				canUpdate
				onRowClick={({ id, status }) => {
					if (status === "pending") {
						return editDrawerProps.handleOpen(id);
					}
				}}
				columns={dataListColumns}
				service={vacationOwnService}
				renderCreateDrawer={(drawerProps) => (
					<OwnVacationDrawer
						{...drawerProps}
						heading={t("Add vacation")}
						initialValues={emptyVacation}
						onSubmit={({ standIn, ...formState }) => {
							return crudOperations.createEntity({
								...formState,
								standInId: standIn?.id ?? null,
							});
						}}
					/>
				)}
				renderUpdateDrawer={(entityResource, drawerProps) => (
					<OwnVacationDrawer
						{...drawerProps}
						heading={t("Edit vacation")}
						initialValues={entityResource}
						onSubmit={({ standIn, ...formState }) => {
							return crudOperations.updateEntity(entityResource.id, {
								...formState,
								standInId: standIn?.id ?? null,
							});
						}}
					/>
				)}
				translations={{
					title: t("My vacations"),
					empty: t("No vacation available."),
				}}
			/>
			{editDrawerProps.editEntityId && (
				<EntityFetcher
					id={editDrawerProps.editEntityId}
					service={vacationService}
					renderDrawer={(entityResource, isLoading, reset) =>
						entityResource &&
						!isLoading && (
							<VacationDrawer
								{...editDrawerProps}
								heading={t("Edit vacation")}
								initialValues={entityResource}
								onSubmit={(values) => updateVacation(entityResource.id, values)}
								onClosed={() => {
									editDrawerProps.onClosed();
									reset();
								}}
							/>
						)
					}
				/>
			)}
		</>
	);
};
