import { FCC } from "@dgs/core";
import React from "react";
import { ContentContainer, FullScreenContainer, ScrollContainer } from "./containers";

interface Props {
	className?: string;
}

export const DetailsPageContentLayout: FCC<Props> = ({ children, className }) => {
	return (
		<FullScreenContainer className={className}>
			<ScrollContainer>{children}</ScrollContainer>
		</FullScreenContainer>
	);
};

export const DefaultContentLayout: FCC<Props> = ({ children, className }) => {
	return <ContentContainer className={className}>{children}</ContentContainer>;
};
