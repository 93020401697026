import { AxiosConfig, FCC, UserPermissionProvider, useDynamicConfig } from "@dgs/core";
import React from "react";
import { PermissionName } from "~shared/types";

export const ContentProviders: FCC = ({ children }) => {
	const { apiUrl } = useDynamicConfig();

	return (
		<UserPermissionProvider<PermissionName>>
			{(isInitialized) =>
				isInitialized && (
					<>
						<AxiosConfig apiUrl={apiUrl} />
						{children}
					</>
				)
			}
		</UserPermissionProvider>
	);
};
