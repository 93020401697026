import { PagedSingleSelectField } from "@dgs/core";
import React, { FC } from "react";
import { activityKeys } from "~shared/api/activities/activityKeys";
import { webserviceApi } from "~shared/api/webserviceApi";

export const activitySelectFetcher = (search: string, page: number) =>
	webserviceApi.listActivityOptions(search, page).then((x) => x.data);

interface Props {
	name: string;
	label: string;
	required?: boolean;
	className?: string;
}

export const ActivitySelectField: FC<Props> = (props) => {
	return <PagedSingleSelectField {...props} fetcher={activitySelectFetcher} queryKey={[...activityKeys.lists()]} />;
};
