import { FCC, Icon } from "@dgs/core";
import React from "react";
import styled from "styled-components";

export const Box = styled.div`
	border: 1px solid ${({ theme }) => theme.colors.layout.background.separator};
	${({ theme }) => theme.borderRadius.large};
`;
export const BoxHeader = styled.div`
	display: flex;
	justify-content: space-between;
	padding: ${({ theme }) => theme.spacing(4)};
	//border-bottom: 1px solid ${({ theme }) => theme.colors.layout.background.separator};
`;
export const BoxTitle = styled.div`
	font-size: 1.25rem;
`;
export const BoxBody = styled.div`
	padding: ${({ theme }) => theme.spacing(4)} ${({ theme }) => theme.spacing(4)};
`;

export const BoxFooter = styled.div`
	display: grid;
	padding: 0 ${({ theme }) => theme.spacing(4)} ${({ theme }) => theme.spacing(4)};
`;

export const RemainingVacationDays = styled.div`
	font-size: 3.5rem;
	line-height: 2.5rem;
	margin: ${({ theme }) => theme.spacing(4)} 0;
`;
export const Disclaimer = styled.div`
	font-size: 0.75rem;
	line-height: 1rem;
	color: ${({ theme }) => theme.colors.palette.grey[70]};
`;

export const DaysLabel = styled.span`
	font-size: 1rem;
	margin-left: 1rem;
`;

export const SectionTitle = styled.div`
	grid-column: 1/3;
	font-size: 1.25rem;
`;

const WarningBoxWrapper = styled.div`
	width: fit-content;
	margin: ${({ theme }) => theme.spacing(2)} 0;
	background-color: rgba(255, 208, 0, 0.904);
	border-radius: 4rem;
	display: flex;
	align-items: center;
	justify-content: space-between;
	padding: ${({ theme }) => theme.spacing(2)} ${({ theme }) => theme.spacing(5)};
	gap: ${({ theme }) => theme.spacing(2)};
`;

export const WarningBox: FCC = ({ children }) => {
	return (
		<WarningBoxWrapper>
			<Icon icon="warning" style={{ width: 24, height: 24 }} />
			<div>{children}</div>
		</WarningBoxWrapper>
	);
};
