import { IToast, useToasts } from "@dgs/core";
import axios from "axios";
import { AxiosError, AxiosResponse } from "openapi-client-axios";
import { useEffect } from "react";
import { useTranslation } from "react-i18next";
import { APIApi } from "~root/api_gen";

const axiosInstance = axios.create();
export const webserviceApi = new APIApi(undefined, "", axiosInstance);
const noop = (response: AxiosResponse) => response;

export const useApiInterceptor = () => {
	const { t } = useTranslation();
	const { showToast } = useToasts();

	useEffect(() => {
		const refreshInterceptor = async (error: AxiosError) => {
			const status = error.response ? error.response.status : null;
			const errorMessages = (
				Object.values(error.response ? (error.response.data as any).errors || {} : {})
					.flat()
					.filter((value, index, self) => {
						return self.indexOf(value) === index;
					}) as string[]
			).map((v) => t(v as any)) as string[];

			const toast: IToast = {
				type: "error",
				title: t("Error"),
				body: [t("An error has occurred.")],
			};

			if (errorMessages.length > 0) {
				showToast({ ...toast, body: errorMessages });
			} else if (status !== 404) {
				showToast(toast);
			}

			return Promise.resolve(error);
		};

		const interceptor = axiosInstance.interceptors.response.use(noop, refreshInterceptor);

		return () => axiosInstance.interceptors.response.eject(interceptor);
	}, [showToast, t]);

	return webserviceApi;
};
