import {
	ContextMenu,
	ContextMenuDeleteItem,
	ContextMenuItem,
	DrawerAutoDataList,
	IDrawerDataListColumn,
	apiIsOK,
	mapFetcherConfigToAxiosConfig,
} from "@dgs/core";
import React, { useMemo } from "react";
import { useTranslation } from "react-i18next";
import { ProjectListDto } from "~root/api_gen";
import { IProjectFormState, ProjectDrawer } from "~root/projects/ProjectDrawer";
import { activityKeys } from "~shared/api/activities/activityKeys";
import { projectKeys } from "~shared/api/projects/projectKeys";
import { useInvalidation, useUpdateReset } from "~shared/api/useInvalidation";
import { useSuccessToast } from "~shared/api/useSuccessToast";
import { webserviceApi } from "~shared/api/webserviceApi";

export const ProjectList = () => {
	const { t } = useTranslation();
	const invalidateOnSuccess = useInvalidation([[...projectKeys.lists()], [...activityKeys.lists()]]);
	const resetOnUpdate = useUpdateReset();
	const showSavedToastOnSuccess = useSuccessToast(t("Project saved"), t("Project successfully saved"));
	const showDeletedToastOnSuccess = useSuccessToast(t("Project deleted"), t("Project successfully deleted"));
	const emptyProject: IProjectFormState = {
		name: "",
		description: "",
		activities: [],
	};
	const dataListColumns: IDrawerDataListColumn<ProjectListDto>[] = useMemo(
		() => [
			{
				heading: t("Name"),
				valueKey: "name",
				type: "value",
			},
			{
				heading: t("Description"),
				valueKey: "description",
				type: "value",
			},
			{
				heading: "",
				type: "actions",
				size: "max-content",
				action: ({ id }, openDrawer) => (
					<ContextMenu displayShortcutsAs="icon">
						<ContextMenuItem
							title={t("Edit project")}
							label={t("Edit project")}
							action={openDrawer}
							icon="edit"
							shortcut="primary"
						/>
						<ContextMenuDeleteItem
							action={() =>
								webserviceApi.deleteProject(id).then(apiIsOK).then(invalidateOnSuccess).then(showDeletedToastOnSuccess)
							}
							title={t("Delete project")}
							label={t("Delete project")}
							heading={t("Delete")}
							labels={{ close: t("Close"), confirm: t("Confirm") }}
						>
							{t("Are you sure you want to delete this project?")}
						</ContextMenuDeleteItem>
					</ContextMenu>
				),
			},
		],
		[invalidateOnSuccess, showDeletedToastOnSuccess, t],
	);

	return (
		<DrawerAutoDataList
			canCreate
			canUpdate
			columns={dataListColumns}
			service={{
				index: (config) => webserviceApi.listProjects(mapFetcherConfigToAxiosConfig(config)),
				indexKey: [...projectKeys.all],
				listKey: [...projectKeys.lists()],
				show: (id) => webserviceApi.showProject(+id),
				showKey: (id) => [...projectKeys.detail(id)],
			}}
			renderCreateDrawer={(drawerProps) => (
				<ProjectDrawer
					{...drawerProps}
					heading={t("Add project")}
					initialValues={emptyProject}
					onSubmit={(entityFormState) =>
						webserviceApi
							.createProject({
								name: entityFormState.name,
								description: entityFormState.description,
								activities: entityFormState.activities.map((x) => {
									if (x.id === -1) {
										return { kind: "create", name: x.name };
									}
									return { kind: "attach", id: x.id };
								}),
							})
							.then(apiIsOK)
							.then(invalidateOnSuccess)
							.then(showSavedToastOnSuccess)
					}
				/>
			)}
			renderUpdateDrawer={(entityResource, drawerProps) => (
				<ProjectDrawer
					{...drawerProps}
					heading={t("Edit project")}
					initialValues={entityResource}
					onSubmit={(entityFormState) =>
						webserviceApi
							.updateProject(entityResource.id, {
								name: entityFormState.name,
								description: entityFormState.description,
								activities: entityFormState.activities.map((x) => {
									if (x.id === -1) {
										return { kind: "create", name: x.name };
									}
									return { kind: "attach", id: x.id };
								}),
							})
							.then(apiIsOK)
							.then(invalidateOnSuccess)
							.then((isApiOk) => resetOnUpdate(isApiOk, projectKeys.detail(entityResource.id)))
							.then(showSavedToastOnSuccess)
					}
				/>
			)}
			translations={{
				title: t("Project"),
				empty: t("No project available."),
			}}
		/>
	);
};
