import { CheckboxField } from "@dgs/core";
import { isSameDay } from "date-fns";
import { useField, useFormikContext } from "formik";
import React, { FC, useEffect, useMemo } from "react";
import { IVacationFormState } from "~shared/types";

interface Props {
	name: string;
	label: string;
}

export const HalfDayField: FC<Props> = ({ name, label }) => {
	const { values } = useFormikContext<IVacationFormState>();
	const [, , { setValue }] = useField<boolean>(name);
	const sameDay = useMemo(() => isSameDay(new Date(values.start), new Date(values.end)), [values]);

	useEffect(() => {
		if (!sameDay) {
			setValue(false);
		}
	}, [sameDay, setValue]);

	return sameDay ? <CheckboxField name={name} label={label} /> : null;
};
