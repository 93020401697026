import { useToasts } from "@dgs/core";

export const useSuccessToast = (title: string, body: string) => {
	const { showToast } = useToasts();

	return async (isApiOk: boolean) => {
		if (isApiOk) {
			showToast({
				type: "success",
				title,
				body,
			});
			return isApiOk;
		}
		return isApiOk;
	};
};
