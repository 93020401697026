import { IShowEndpoint, Id, LaravelResponse, mapFetcherConfigToAxiosConfig } from "@dgs/core";
import { IDestroyEndpoint } from "@dgs/core/src/api/withDestroyEndpoint";
import { IIndexEndpoint } from "@dgs/core/src/api/withIndexEndpoint";
import { IStoreEndpoint } from "@dgs/core/src/api/withStoreEndpoint";
import { IUpdateEndpoint } from "@dgs/core/src/api/withUpdateEndpoint";
import { AxiosResponse } from "openapi-client-axios";
import {
	Int32CreatedResponse,
	ListVacationDto,
	OwnVacationDto,
	VacationCreateDto,
	VacationCreateOwnDto,
	VacationShowDto,
	VacationStatusUpdateDto,
	VacationUpdateDto,
	VacationUpdateOwnDto,
} from "~root/api_gen";
import { vacationKeys } from "~shared/api/vacation/vacationKeys";
import { webserviceApi } from "~shared/api/webserviceApi";

type VacationService = IStoreEndpoint<VacationCreateDto, Int32CreatedResponse> &
	IUpdateEndpoint<VacationUpdateDto> &
	IShowEndpoint<VacationShowDto> &
	IIndexEndpoint<ListVacationDto> &
	IDestroyEndpoint & {
		updateStatus(id: Id, values: VacationStatusUpdateDto): Promise<AxiosResponse>;
	};

export const vacationService: VacationService = {
	index: (config) =>
		webserviceApi.listVacations({ filterItems: config.filterParams }, mapFetcherConfigToAxiosConfig(config)),
	indexKey: [...vacationKeys.all],
	listKey: [...vacationKeys.lists()],
	show: (id) => webserviceApi.showVacation(+id),
	showKey: (id) => [...vacationKeys.detail(id)],
	store: (vacation: VacationCreateDto) =>
		webserviceApi.createVacation(vacation) as unknown as Promise<AxiosResponse<LaravelResponse<Int32CreatedResponse>>>,
	update: (id, entity) => webserviceApi.updateVacation(+id, entity) as unknown as Promise<AxiosResponse<void>>,
	destroy: (id) => webserviceApi.deleteVacation(+id) as unknown as Promise<AxiosResponse<void>>,
	updateStatus: (id: Id, values: VacationStatusUpdateDto) => webserviceApi.updateVacationStatus(+id, values),
};

type VacationOwnService = IStoreEndpoint<VacationCreateOwnDto, Int32CreatedResponse> &
	IUpdateEndpoint<VacationUpdateOwnDto> &
	IShowEndpoint<VacationShowDto> &
	IIndexEndpoint<OwnVacationDto> &
	IDestroyEndpoint;

export const vacationOwnService: VacationOwnService = {
	update(id, entity) {
		return webserviceApi.updateOwnVacation(+id, entity);
	},
	show: (id) => webserviceApi.showVacation(+id),
	showKey: (id) => [...vacationKeys.detail(id)],
	listKey: [...vacationKeys.ownLists()],
	indexKey: [...vacationKeys.all],
	index: (config) => webserviceApi.listOwnVacations(mapFetcherConfigToAxiosConfig(config)),
	store: (entity) =>
		webserviceApi.createOwnVacation(entity) as unknown as Promise<AxiosResponse<LaravelResponse<Int32CreatedResponse>>>,
	destroy: (id) => webserviceApi.deleteVacation(+id),
};
