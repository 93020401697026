import { PagedMultiSelectField, roleService } from "@dgs/core";
import React, { FC } from "react";

interface Props {
	name: string;
	label: string;
	disabled?: boolean;
}

const fetcher = (search: string, page: number) => roleService.options(search, page).then((r) => r.data);

export const RoleMultiSelect: FC<Props> = ({ name, label, disabled }) => {
	return (
		<PagedMultiSelectField
			name={name}
			label={label}
			disabled={disabled}
			queryKey={roleService.optionsKey}
			fetcher={fetcher}
		/>
	);
};
