import { createCrudService } from "@dgs/core";
import { BranchCreateDto, BranchListDto, BranchOptionDto, BranchShowDto, BranchUpdateDto } from "~root/api_gen";

export const branchService = createCrudService<
	BranchListDto,
	BranchOptionDto,
	BranchShowDto,
	BranchCreateDto,
	BranchUpdateDto
>(["branches"]);
