import { Button, Card, CardBody, CardFooter, EntityFetcher, FormattedDate, Icon, Trigger, apiIsOK } from "@dgs/core";
import { useQueryClient } from "@tanstack/react-query";
import React, { FC } from "react";
import { useTranslation } from "react-i18next";
import styled from "styled-components";
import { EmployeeShowDto } from "~root/api_gen";
import { DetailsSection, SectionItemLabel, SectionItemValue } from "~root/employees/detailsV2/common/DetailsSection";
import { EmployeeHighlight } from "~root/employees/detailsV2/EmployeeHighlight";
import { EmployeeUpdateDrawer } from "~root/employees/employee_update_drawer";
import { employeeKeys } from "~shared/api/employee/employeeKeys";
import { useInvalidation } from "~shared/api/useInvalidation";
import { useSuccessToast } from "~shared/api/useSuccessToast";
import { webserviceApi } from "~shared/api/webserviceApi";

const StyledCardFooter = styled(CardFooter)`
	justify-content: center;
`;
export const EmployeeHighlightCard: FC<{ employee: EmployeeShowDto }> = ({ employee }) => {
	const { t } = useTranslation();
	const queryClient = useQueryClient();
	const invalidateOnSuccess = useInvalidation([[...employeeKeys.lists()]]);
	const showSavedToastOnSuccess = useSuccessToast(t("Employee saved"), t("Employee successfully saved"));

	return (
		<Card>
			<CardBody>
				<EmployeeHighlight employee={employee} />
				<DetailsSection title={t("Details")}>
					<SectionItemLabel>{t("Street")}:</SectionItemLabel>
					<SectionItemValue>{employee.street}</SectionItemValue>
					<SectionItemLabel>{t("House number")}:</SectionItemLabel>
					<SectionItemValue>{employee.houseNumber}</SectionItemValue>
					<SectionItemLabel>{t("Zip")}:</SectionItemLabel>
					<SectionItemValue>{employee.postalCode}</SectionItemValue>
					<SectionItemLabel>{t("City")}:</SectionItemLabel>
					<SectionItemValue>{employee.city}</SectionItemValue>
					<SectionItemLabel>{t("Country")}:</SectionItemLabel>
					<SectionItemValue>{employee.country?.translations[0].name}</SectionItemValue>
					<SectionItemLabel>{t("Branch")}:</SectionItemLabel>
					<SectionItemValue>{employee.branch?.name}</SectionItemValue>
					<SectionItemLabel>{t("Date of birth")}:</SectionItemLabel>
					<SectionItemValue>
						<FormattedDate date={employee.dateOfBirth} />
					</SectionItemValue>
					<SectionItemLabel>{t("Place of birth")}:</SectionItemLabel>
					<SectionItemValue>{employee.placeOfBirth}</SectionItemValue>
					<SectionItemLabel>{t("IBAN")}:</SectionItemLabel>
					<SectionItemValue>{employee.iban}</SectionItemValue>
					<SectionItemLabel>{t("User")}:</SectionItemLabel>
					{employee.user ? (
						<SectionItemValue>
							{employee.user.firstName} {employee.user.lastName} {employee.user.email}
						</SectionItemValue>
					) : (
						<SectionItemValue>-</SectionItemValue>
					)}
				</DetailsSection>
			</CardBody>
			<StyledCardFooter>
				<Trigger
					render={(props) => (
						<EntityFetcher
							id={employee.id}
							fetcher={(id) => webserviceApi.showEmployee(+id).then((x) => x.data.data)}
							getQueryKey={employeeKeys.detail}
							renderDrawer={(entity, isLoading) =>
								entity &&
								!isLoading && (
									<EmployeeUpdateDrawer
										open={props.open}
										onClose={props.handleClose}
										heading={t("Edit employee")}
										initialValues={entity}
										onClosed={() => {
											void queryClient.invalidateQueries({
												queryKey: employeeKeys.detail(employee.id),
											});
										}}
										onSubmit={async ({ user, ...values }) => {
											return webserviceApi
												.updateEmployee(employee.id, {
													...values,
													userId: user?.id,
													branchId: values.branch?.id ?? null,
													countryId: values.country?.id ?? null,
												})
												.then(apiIsOK)
												.then(invalidateOnSuccess)
												.then(showSavedToastOnSuccess);
										}}
									/>
								)
							}
						/>
					)}
				>
					{(props) => (
						<Button {...props} prefix={<Icon icon={"edit"} />} color={"primary"}>
							{t("Edit")}
						</Button>
					)}
				</Trigger>
				<Button prefix={<Icon icon={"delete"} />}>{t("Löschen")}</Button>
			</StyledCardFooter>
		</Card>
	);
};
