import { FCC, LocaleProvider } from "@dgs/core";
import React from "react";
import { I18nextProvider } from "react-i18next";
import i18n from "~root/i18n";

interface Props {
	locale?: string;
	timeZone?: string;
}

const onChange = (language: string) => i18n.changeLanguage(language);

export const PermaLocaleProvider: FCC<Props> = ({
	children,
	timeZone = Intl.DateTimeFormat().resolvedOptions().timeZone,
}) => {
	return (
		<I18nextProvider i18n={i18n}>
			<LocaleProvider initialTimeZone={timeZone} onChange={onChange}>
				{children}
			</LocaleProvider>
		</I18nextProvider>
	);
};
