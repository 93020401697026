import {
	ContextMenu,
	ContextMenuDeleteItem,
	ContextMenuItem,
	DrawerAutoDataList,
	IDrawerDataListColumn,
	useCrudEntity,
} from "@dgs/core";
import React, { useMemo } from "react";
import { useTranslation } from "react-i18next";
import { BranchCreateDto, BranchListDto, BranchUpdateDto } from "~root/api_gen";
import { branchService } from "~shared/api/branch/branch_service";
import { BranchDrawer, IBranchFormState } from "./BranchDrawer";

const mapToRequest = ({ country, ...employee }: IBranchFormState): BranchCreateDto | BranchUpdateDto => {
	if (!country) throw "Missing valication for field: country";

	return {
		...employee,
		countryId: (country?.id as number) ?? null,
	};
};
export const BranchList = () => {
	const { t } = useTranslation();
	const crudOperations = useCrudEntity(branchService, {
		entityName: t("Branch"),
		messages: {
			create: t("Branch was saved."),
			update: t("Branch was saved."),
			delete: t("Branch was deleted."),
		},
	});
	const emptyBranch: IBranchFormState = {
		name: "",
		street: "",
		houseNumber: "",
		zip: "",
		city: "",
		state: null,
		country: null,
	};
	const dataListColumns: IDrawerDataListColumn<BranchListDto>[] = useMemo(
		() => [
			{
				heading: t("Name"),
				valueKey: "name",
				type: "value",
			},
			{
				heading: t("Street"),
				valueKey: "street",
				type: "value",
			},
			{
				heading: t("House number"),
				valueKey: "houseNumber",
				type: "value",
			},
			{
				heading: t("Zip"),
				valueKey: "zip",
				type: "value",
			},
			{
				heading: t("City"),
				valueKey: "city",
				type: "value",
			},
			{
				heading: t("State"),
				valueKey: "state",
				type: "value",
			},
			{
				heading: t("Country"),
				valueKey: "country",
				render: ({ country }) => country.translations[0].name,
				type: "value",
			},
			{
				heading: "",
				type: "actions",
				size: "max-content",
				action: ({ id }, openDrawer) => (
					<ContextMenu displayShortcutsAs="icon">
						<ContextMenuItem
							title={t("Edit branch")}
							label={t("Edit branch")}
							action={openDrawer}
							icon="edit"
							shortcut="primary"
						/>
						<ContextMenuDeleteItem
							action={() => crudOperations.deleteEntity(id)}
							title={t("Delete branch")}
							label={t("Delete branch")}
							heading={t("Delete")}
							labels={{ close: t("Close"), confirm: t("Confirm") }}
						>
							{t("Are you sure you want to delete this branch?")}
						</ContextMenuDeleteItem>
					</ContextMenu>
				),
			},
		],
		[crudOperations, t],
	);

	return (
		<DrawerAutoDataList
			canCreate
			canUpdate
			columns={dataListColumns}
			service={branchService}
			renderCreateDrawer={(drawerProps) => (
				<BranchDrawer
					{...drawerProps}
					heading={t("Add branch")}
					initialValues={emptyBranch}
					onSubmit={(entityFormState) => crudOperations.createEntity(mapToRequest(entityFormState))}
				/>
			)}
			renderUpdateDrawer={(entityResource, drawerProps) => (
				<BranchDrawer
					{...drawerProps}
					heading={t("Edit branch")}
					initialValues={entityResource}
					onSubmit={(entityFormState) => crudOperations.updateEntity(entityResource.id, mapToRequest(entityFormState))}
				/>
			)}
			translations={{
				title: t("Branches"),
				empty: t("No branch available."),
			}}
		/>
	);
};
