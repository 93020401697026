import { CrudDrawer, DateField, EntityDrawerProps, SingleSelectField, TextField } from "@dgs/core";
import React, { FC, useCallback } from "react";
import { useTranslation } from "react-i18next";
import { BranchSelectField } from "~root/branches/branch_select_field";
import { CountrySelectField, StateSelectField } from "~root/country/country_select_field";
import { UserSelectField } from "~root/users/user_select_field";
import { ALL_GENDERS, IEmployeeUpdateFormState } from "~shared/types";

export const EmployeeUpdateDrawer: FC<EntityDrawerProps<IEmployeeUpdateFormState>> = (props) => {
	const { t } = useTranslation();

	const validate = useCallback(
		(values: IEmployeeUpdateFormState) => {
			const errors: { [K in keyof IEmployeeUpdateFormState]?: any } = {};

			if (!values.firstName) {
				errors.firstName = t("Field is required");
			}
			if (!values.lastName) {
				errors.lastName = t("Field is required");
			}
			if (!values.dateOfBirth) {
				errors.dateOfBirth = t("Field is required");
			}
			if (!values.employeeNumber) {
				errors.employeeNumber = t("Field is required");
			}
			return errors;
		},
		[t],
	);

	return (
		<CrudDrawer
			{...props}
			validate={validate}
			body={
				<>
					<TextField name="firstName" label={t("First name")} required />
					<TextField name="lastName" label={t("Last name")} required />
					<TextField name="employeeNumber" label={t("Employee number")} required />
					<TextField name="street" label={t("Street")} />
					<TextField name="houseNumber" label={t("House number")} />
					<TextField name="postalCode" label={t("Postal code")} />
					<TextField name="city" label={t("City")} />
					<CountrySelectField name="country" label={t("Country")} />
					<StateSelectField name="state" label={t("State")} />
					<TextField name="placeOfBirth" label={t("Place of birth")} />
					<TextField name="countryOfBirth" label={t("Country of birth")} />
					<DateField name="dateOfBirth" label={t("Date of birth")} required />
					<SingleSelectField name="gender" label={t("Gender")} options={[...ALL_GENDERS]} getLabel={t} />
					<BranchSelectField name="branch" label={t("Branch")} />
					<TextField name="iban" label={t("IBAN")} />
					<UserSelectField name="user" label={t("User")} />
				</>
			}
		/>
	);
};
