import { Id } from "@dgs/core";
import { BranchOptionDto, CountryOptionDto, EmployeeShowDto } from "~root/api_gen";
import { IUserOptionResource } from "./user";

export const ALL_GENDERS = ["male", "female"] as const;

type IGender = (typeof ALL_GENDERS)[number];

export type IVacationEntitlementUpdateResource = {
	yearlyVacationDays: EmployeeShowDto["yearlyVacationDays"];
	hoursPerDay: EmployeeShowDto["hoursPerDay"];
	entryDate: EmployeeShowDto["entryDate"];
	leaveDate?: EmployeeShowDto["leaveDate"];
	employeeNumber: EmployeeShowDto["employeeNumber"];
	//branch: IBranchOptionResource | null;
};

export type IVacationEntitlementFormState = IVacationEntitlementUpdateResource & {
	//changeDate: string;
};

export type IVacationEntitlementUpdateRequest = Omit<IVacationEntitlementUpdateResource, "branch"> & {
	branchId: Id | null;
	changeDate: string;
};
type IEmployeeBaseResource = {
	id: Id;
	firstName: string;
	lastName: string;
	street: string | null;
	houseNumber: string | null;
	postalCode: string | null;
	city: string | null;
	country: CountryOptionDto | null;
	placeOfBirth: string | null;
	countryOfBirth: string | null;
	dateOfBirth: string | null;
	gender: IGender | null;
	iban: string | null;
	user: IUserOptionResource | null;
	branch: BranchOptionDto | null;
};

export type IEmployeeIndexResource = IEmployeeBaseResource & IVacationEntitlementUpdateResource;

export type IEmployeeOptionResource = IEmployeeIndexResource;

export type IEmployeeCreateFormState = Omit<IEmployeeBaseResource & IVacationEntitlementUpdateResource, "id">;

export type IEmployeeUpdateFormState = Omit<EmployeeShowDto, "id">;

type IEmployeeBaseRequest = {
	firstName: string;
	lastName: string;
	street: string | null;
	houseNumber: string | null;
	postalCode: string | null;
	city: string | null;
	country: string | null;
	placeOfBirth: string | null;
	countryOfBirth: string | null;
	dateOfBirth: string | null;
	gender: IGender | null;
	iban: string | null;
	userId: Id | null;
};
export type IEmployeeCreateRequest = IEmployeeBaseRequest &
	Omit<IVacationEntitlementUpdateResource, "branch"> & {
		branchId: Id | null;
	};

export type IEmployeeUpdateRequest = IEmployeeBaseRequest;
