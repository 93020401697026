import { CrudDrawer, EntityDrawerProps, Id, MultiSelectField, TextField } from "@dgs/core";
import React, { FC, useCallback } from "react";
import { useTranslation } from "react-i18next";
import { TeamShowDto } from "~root/api_gen";
import { EmployeeMultiSelectField } from "~root/employees/employee_select_field";

export type ITeamFormState = Omit<TeamShowDto, "id">;
export const TeamDrawer: FC<EntityDrawerProps<ITeamFormState>> = (props) => {
	const { t } = useTranslation();

	const validate = useCallback(
		(values: ITeamFormState) => {
			const errors: { [K in keyof ITeamFormState]?: any } = {};

			if (!values.name) {
				errors.name = t("Field is required");
			}
			if (!values.employees.length) {
				errors.employees = t("Field is required");
			}
			return errors;
		},
		[t],
	);

	return (
		<CrudDrawer
			{...props}
			validate={validate}
			body={({ values, setFieldValue }) => (
				<>
					<TextField name="name" label={t("Name")} />
					<EmployeeMultiSelectField
						name="employees"
						label={t("Employees")}
						onChange={(e) => {
							const idsToRemove = values.leaders.reduce((idsToRemove, leader) => {
								if (!e.target.value.find((x) => leader.id === x.id)) {
									return [...idsToRemove, leader.id];
								}
								return idsToRemove;
							}, [] as Id[]);
							setFieldValue(
								"leaders",
								values.leaders.filter((leader) => !idsToRemove.includes(leader.id)),
							);
						}}
					/>
					<MultiSelectField
						name="leaders"
						label={t("Team leaders")}
						options={values.employees}
						getLabel={(x) => `${x.firstName} ${x.lastName}`}
					/>
				</>
			)}
		/>
	);
};
