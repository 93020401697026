import {
	ContextMenu,
	ContextMenuDeleteItem,
	ContextMenuItem,
	DrawerAutoDataList,
	IDrawerDataListColumn,
	useCrudEntity,
} from "@dgs/core";
import React, { useMemo } from "react";
import { useTranslation } from "react-i18next";
import { TeamCreateDto, TeamListDto, TeamUpdateDto } from "~root/api_gen";
import { teamService } from "~shared/api/team/team_service";
import { ITeamFormState, TeamDrawer } from "./team_drawer";

const mapToRequest = ({ employees, leaders, ...team }: ITeamFormState): TeamCreateDto | TeamUpdateDto => ({
	...team,
	employeeIds: employees.map((x) => x.id),
	leaderIds: leaders.map((x) => x.id),
});

export const TeamsPage = () => {
	const { t } = useTranslation();
	const crudOperations = useCrudEntity(teamService, {
		entityName: t("Team"),
		messages: {
			create: t("Team was saved."),
			update: t("Team was saved."),
			delete: t("Team was deleted."),
		},
	});
	const emptyTeam: ITeamFormState = {
		name: "",
		employees: [],
		leaders: [],
	};
	const dataListColumns: IDrawerDataListColumn<TeamListDto>[] = useMemo(
		() => [
			{
				heading: t("Name"),
				valueKey: "name",
				type: "value",
			},
			{
				heading: t("Employees"),
				valueKey: "employees",
				render: ({ employees }) => employees.map((x) => `${x.firstName} ${x.lastName}`).join(", "),
				type: "value",
			},
			{
				heading: t("Leaders"),
				valueKey: "leaders",
				render: ({ leaders }) => leaders.map((x) => `${x.firstName} ${x.lastName}`).join(", "),
				type: "value",
			},
			{
				heading: "",
				type: "actions",
				size: "max-content",
				action: ({ id }, openDrawer) => (
					<ContextMenu displayShortcutsAs="icon">
						<ContextMenuItem
							title={t("Edit team")}
							label={t("Edit team")}
							action={openDrawer}
							icon="edit"
							shortcut="primary"
						/>
						<ContextMenuDeleteItem
							action={() => crudOperations.deleteEntity(id)}
							title={t("Delete team")}
							label={t("Delete team")}
							heading={t("Delete")}
							labels={{ close: t("Close"), confirm: t("Confirm") }}
						>
							{t("Are you sure you want to delete this team?")}
						</ContextMenuDeleteItem>
					</ContextMenu>
				),
			},
		],
		[crudOperations, t],
	);

	return (
		<DrawerAutoDataList
			canCreate
			canUpdate
			columns={dataListColumns}
			service={teamService}
			renderCreateDrawer={(drawerProps) => (
				<TeamDrawer
					{...drawerProps}
					heading={t("Add team")}
					initialValues={emptyTeam}
					onSubmit={(entityFormState) => crudOperations.createEntity(mapToRequest(entityFormState))}
				/>
			)}
			renderUpdateDrawer={(entityResource, drawerProps) => (
				<TeamDrawer
					{...drawerProps}
					heading={t("Edit team")}
					initialValues={entityResource}
					onSubmit={(entityFormState) => crudOperations.updateEntity(entityResource.id, mapToRequest(entityFormState))}
				/>
			)}
			translations={{
				title: t("Teams"),
				empty: t("No team available."),
			}}
		/>
	);
};
