import { CheckboxField, CrudDrawer, EntityDrawerProps, SingleSelectField, TextField } from "@dgs/core";
import React, { FC, useCallback } from "react";
import { useTranslation } from "react-i18next";
import styled from "styled-components";
import { RoleShowDto } from "~root/api_gen";
import { IPermission } from "~shared/types/permission";
import {
	payrollsVisibilityOptionNames,
	timesVisibilityOptionNames as timesVisibilityOptionNames,
	vacationsVisibilityOptionNames as vacationsVisibilityOptionNames,
} from "./permissions";

const Seperator = styled.div`
	height: 20px;
`;

export type IRoleFormState = Omit<RoleShowDto, "id" | "permissions" | "users"> & {
	permissions: Record<string, IPermission & { enabled: boolean }>;
};

export const RoleDrawer: FC<EntityDrawerProps<IRoleFormState> & { permissions: IPermission[] }> = (props) => {
	const { t } = useTranslation();

	const validate = useCallback(
		(values: IRoleFormState) => {
			const errors: { [K in keyof IRoleFormState]?: any } = {};

			if (!values.name) {
				errors.name = t("Field is required");
			}
			if (!values.permissions) {
				errors.permissions = t("Field is required");
			}
			if (!values.payrollsVisibility) {
				errors.payrollsVisibility = t("Field is required");
			}
			return errors;
		},
		[t],
	);
	const vacationsVisibilityOptions = props.permissions.filter((x) => vacationsVisibilityOptionNames.includes(x.name));
	const timesVisibilityOptions = props.permissions.filter((x) => timesVisibilityOptionNames.includes(x.name));
	const payrollsVisibilityOptions = props.permissions.filter((x) => payrollsVisibilityOptionNames.includes(x.name));
	const getLabel = useCallback(
		(x: IPermission) => {
			const parts = x.name.split(".");
			return t(parts[parts.length - 1]);
		},
		[t],
	);

	return (
		<CrudDrawer
			{...props}
			validate={validate}
			body={
				<>
					<TextField name="name" label={t("Name")} required />
					<HeaderRow>
						<NameCell>{t("Name")}</NameCell>
						<div>{t("Read")}</div>
						<div>{t("Create")}</div>
						<div>{t("Edit")}</div>
						<div>{t("Delete")}</div>
					</HeaderRow>
					<CrudRow name={"Permissions.Employees"} label={t("Employees")} />
					<CrudRow name={"Permissions.Accounts"} label={t("Accounts")} />
					<CrudRow name={"Permissions.Vacations"} label={t("Vacations")} />
					<CrudRow name={"Permissions.Projects"} label={t("Projects")} />
					<CrudRow name={"Permissions.Activities"} label={t("Activities")} />
					<CrudRow name={"Permissions.Roles"} label={t("Roles")} />
					<CrudRow name={"Permissions.Users"} label={t("Users")} />
					<CrudRow name={"Permissions.OffDays"} label={t("Off days")} />
					<CrudRow name={"Permissions.Branches"} label={t("Branches")} />
					<CrudRow name={"Permissions.Teams"} label={t("Teams")} />
					<CrudRow name={"Permissions.Times"} label={t("Times")} />
					<CrudRow name={"Permissions.Payrolls"} label={t("Payrolls")} />
					<Seperator />
					<SingleSelectField
						name="vacationsVisibility"
						options={vacationsVisibilityOptions}
						label={t("Vacations visibility")}
						getLabel={getLabel}
					/>
					<SingleSelectField
						name="timesVisibility"
						options={timesVisibilityOptions}
						label={t("Times visibility")}
						getLabel={getLabel}
					/>
					<SingleSelectField
						name="payrollsVisibility"
						options={payrollsVisibilityOptions}
						label={t("Payrolls visibility")}
						getLabel={getLabel}
					/>
				</>
			}
		/>
	);
};

const NameCell = styled.div`
	justify-self: start;
`;

const Row = styled.div`
	display: grid;
	grid-template-columns: 100px 1fr 1fr 1fr 1fr;
	gap: ${({ theme }) => theme.spacing(1)};
	border-bottom: 1px solid #ccc;

	> * {
		display: flex;
		justify-content: center;
		align-items: center;
	}
`;

const HeaderRow = styled(Row)`
	padding-bottom: ${({ theme }) => theme.spacing(3)};
`;
const StyledCheckboxField = styled(CheckboxField)`
	margin: 12px;
`;
const CrudRow: FC<{ name: string; label: string }> = ({ name, label }) => {
	return (
		<Row>
			<NameCell>{label}</NameCell>
			<div>
				<StyledCheckboxField label="" name={`permissions.['${name}.Read'].enabled`} />
			</div>
			<div>
				<StyledCheckboxField label="" name={`permissions.['${name}.Create'].enabled`} />
			</div>
			<div>
				<StyledCheckboxField label="" name={`permissions.['${name}.Update'].enabled`} />
			</div>
			<div>
				<StyledCheckboxField label="" name={`permissions.['${name}.Delete'].enabled`} />
			</div>
		</Row>
	);
};
