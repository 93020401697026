import { Button, EntityFetcher, FormattedDate, Id, apiIsOK, useEditDrawer } from "@dgs/core";
import { isAfter, parseISO } from "date-fns";
import React, { FC, useCallback } from "react";
import { useTranslation } from "react-i18next";
import styled from "styled-components";
import { EmployeeDashboardVacationDto, EmployeeOptionDto } from "~root/api_gen";
import { IVacationFormState, OwnVacationDrawer } from "~root/vacations/OwnVacationDrawer";
import { useInvalidation, useUpdateReset } from "~shared/api/useInvalidation";
import { useSuccessToast } from "~shared/api/useSuccessToast";
import { vacationKeys } from "~shared/api/vacation/vacationKeys";
import { webserviceApi } from "~shared/api/webserviceApi";
import { BoxBody, Disclaimer } from "./box";

export const VacationList = styled(BoxBody)`
	display: grid;
	padding: 0;
	gap: ${({ theme }) => theme.spacing(2)};
`;

export const VacationListItemEmpty = styled.div`
	align-items: center;
	text-align: center;
	padding: ${({ theme }) => theme.spacing(4)};
	${({ theme }) => theme.borderRadius.large};
`;
const VacationListItemWrapper = styled.button`
	${({ theme }) => theme.buttons.text.base};
	${({ theme }) => theme.buttons.text.colors.default.base};
	text-transform: none;
	font-weight: normal;
	display: grid;
	grid-template-columns: 1fr 1fr 1fr;
	text-decoration: none;
	align-items: center;
	padding: ${({ theme }) => theme.spacing(2)} ${({ theme }) => theme.spacing(4)};
	${({ theme }) => theme.borderRadius.large};

	*:first-child {
		justify-self: start;
	}

	*:last-child {
		justify-self: end;
	}

	&:active,
	&:focus {
		outline: none;
	}

	&:hover {
		background-color: ${({ theme }) => theme.colors.palette.grey[10]};
		color: #000;
	}
`;

const VacationRange = styled.div`
	display: flex;
	flex-direction: column;
	justify-content: space-between;
	font-size: 1.25rem;
`;

export const VacationListItem: FC<{ vacation: EmployeeDashboardVacationDto }> = ({ vacation }) => {
	const drawerProps = useEditDrawer();
	const { t } = useTranslation();
	const resetOnUpdateSuccess = useUpdateReset();
	const invalidateListOnSuccess = useInvalidation([[...vacationKeys.lists()], ["employee-dashboard"]]);
	const showVacationSavedToastOnSuccess = useSuccessToast(t("Success"), t("Vacation successfully saved"));
	const cancelVacation = useCallback(
		async (id: Id) =>
			webserviceApi
				.cancelOwnVacation(+id)
				.then(apiIsOK)
				.then(invalidateListOnSuccess)
				.then((isApiOk) => resetOnUpdateSuccess(isApiOk, [...vacationKeys.detail(id)]))
				.then(showVacationSavedToastOnSuccess),
		[invalidateListOnSuccess, resetOnUpdateSuccess, showVacationSavedToastOnSuccess],
	);
	const updateVacation = useCallback(
		async (id: Id, values: IVacationFormState) => {
			return webserviceApi
				.updateOwnVacation(+id, {
					...values,
					standInId: (values.standIn ? (values.standIn as EmployeeOptionDto) : null)?.id ?? null,
				})
				.then(apiIsOK)
				.then(invalidateListOnSuccess)
				.then((isApiOk) => resetOnUpdateSuccess(isApiOk, [...vacationKeys.detail(id)]))
				.then(showVacationSavedToastOnSuccess);
		},
		[invalidateListOnSuccess, resetOnUpdateSuccess, showVacationSavedToastOnSuccess],
	);

	return (
		<>
			<VacationListItemWrapper
				onClick={() => {
					drawerProps.handleOpen(vacation.id);
				}}
				title={vacation.status === "declined" ? vacation.declineReason ?? "" : undefined}
			>
				<VacationRange>
					<FormattedDate date={vacation.start} as="span" />
					<Disclaimer>
						- <FormattedDate date={vacation.end} />
					</Disclaimer>
				</VacationRange>
				<span>{t("{{count}} days", { count: vacation.vacationDaysCount })}</span>
				<span>{t(vacation.status)}</span>
			</VacationListItemWrapper>
			{(vacation.status === "pending" ||
				(vacation.status === "approved" &&
					vacation.status === "approved" &&
					isAfter(parseISO(vacation.start), new Date()))) &&
				drawerProps.editEntityId && (
					<EntityFetcher
						id={drawerProps.editEntityId}
						fetcher={(id) => webserviceApi.showVacation(+id).then((x) => x.data.data)}
						getQueryKey={(id) => [...vacationKeys.detail(id)]}
						renderDrawer={(entity, isLoading, reset) =>
							entity &&
							!isLoading && (
								<OwnVacationDrawer
									{...drawerProps}
									updateDisabled={entity.status === "approved"}
									heading={t("Edit vacation request")}
									initialValues={entity}
									onClosed={reset}
									onSubmit={async (values) => updateVacation(entity.id, values)}
									footer={
										<Button
											type="button"
											color="danger"
											onClick={() => cancelVacation(entity.id).then((isOk) => isOk && drawerProps.onClose())}
										>
											{t("Cancel")}
										</Button>
									}
								/>
							)
						}
					/>
				)}
		</>
	);
};
