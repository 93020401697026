import {
	CrudDrawer,
	DrawerBody,
	DrawerFooter,
	DrawerHeader,
	DrawerHeading,
	EntityDrawerProps,
	PagedMultiSelectField,
	SubmitButton,
	TextField,
} from "@dgs/core";
import { Form, Formik } from "formik";
import React, { FC, useCallback } from "react";
import { useTranslation } from "react-i18next";
import { activitySelectFetcher } from "~root/activities/ActivitySelectField";
import { ActivityOptionDto, ProjectShowDto } from "~root/api_gen";
import { activityKeys } from "~shared/api/activities/activityKeys";

export interface IProjectFormState {
	name: ProjectShowDto["name"];
	description: ProjectShowDto["description"];
	activities: ActivityOptionDto[];
}

export const ProjectDrawer: FC<EntityDrawerProps<IProjectFormState>> = (props) => {
	const { t } = useTranslation();

	const validate = useCallback(
		(values: IProjectFormState) => {
			const errors: { [K in keyof IProjectFormState]?: any } = {};

			if (!values.name) {
				errors.name = t("Field is required");
			}
			return errors;
		},
		[t],
	);

	return (
		<CrudDrawer
			{...props}
			validate={validate}
			body={
				<>
					<TextField name="name" label={t("Name")} required />
					<TextField name="description" label={t("Description")} />
					<PagedMultiSelectField
						name="activities"
						label={t("Activities")}
						fetcher={activitySelectFetcher}
						queryKey={[...activityKeys.options()]}
						renderCreateForm={(selectNew, onClose) => {
							return (
								<Formik
									initialValues={{ name: "" }}
									onSubmit={(values) => {
										selectNew({
											id: -1,
											name: values.name,
										});
										onClose();
									}}
								>
									<Form>
										<DrawerHeader onClose={onClose}>
											<DrawerHeading>{t("Create Activity")}</DrawerHeading>
										</DrawerHeader>
										<DrawerBody>
											<TextField name="name" label={t("Name")} />
										</DrawerBody>
										<DrawerFooter>
											<SubmitButton>{t("Submit")}</SubmitButton>
										</DrawerFooter>
									</Form>
								</Formik>
							);
						}}
					/>
				</>
			}
		/>
	);
};
