import { CrudDrawer, DateField, EntityDrawerProps, TextField } from "@dgs/core";
import React, { FC, ReactNode, useCallback } from "react";
import { useTranslation } from "react-i18next";
import { EmployeeOptionDto, VacationShowDto } from "~root/api_gen";
import { EmployeeSingleSelectField } from "~root/employees/employee_select_field";
import { HalfDayField } from "~root/vacations/HalfDayField";

export type IVacationFormState = Omit<VacationShowDto, "id" | "employee" | "standIn" | "vacationDayCount"> & {
	standIn?: EmployeeOptionDto | null;
};
export const OwnVacationDrawer: FC<
	EntityDrawerProps<IVacationFormState> & { footer?: ReactNode; updateDisabled?: boolean }
> = (props) => {
	const { t } = useTranslation();

	const validate = useCallback(
		(values: IVacationFormState) => {
			const errors: { [K in keyof IVacationFormState]?: any } = {};

			if (!values.start) {
				errors.start = t("Field is required");
			}
			if (!values.end) {
				errors.end = t("Field is required");
			}

			return errors;
		},
		[t],
	);

	return (
		<CrudDrawer
			{...props}
			validate={validate}
			body={
				<>
					<DateField name="start" label={t("Start")} required disabled={props.updateDisabled} />
					<DateField name="end" label={t("End")} required disabled={props.updateDisabled} />
					<HalfDayField name="halfDay" label={t("Half day")} />
					<TextField name="remark" label={t("Remark")} />
					<EmployeeSingleSelectField name="standIn" label={t("Stand in")} />
				</>
			}
		/>
	);
};
