import { ApplicationBar, Button, H2, useAuth } from "@dgs/core";
import React, { FC } from "react";
import { useTranslation } from "react-i18next";
import styled from "styled-components";

const Actions = styled.div`
	margin-left: auto;
	display: grid;
	grid-auto-flow: column;
	grid-auto-columns: max-content;
	gap: 0.5rem;
	height: 100%;
	align-items: center;
`;

const AppName = styled(H2)`
	font-size: 1.25rem;
	margin-bottom: 0;
	color: ${({ theme }) => theme.colors.applicationBar.text};
`;

export const Header: FC = () => {
	const { logout } = useAuth();
	const { t } = useTranslation();

	return (
		<ApplicationBar>
			<AppName>PERSONAL MANAGER</AppName>
			<Actions>
				<Button type="button" onClick={logout}>
					{t("Logout")}
				</Button>
			</Actions>
		</ApplicationBar>
	);
};
