import {FCC} from "@dgs/core";
import React from "react";
import styled from "styled-components";

const Wrapper = styled.div`
    display: grid;
    gap: ${({theme}) => theme.spacing(2)};
`
const SectionContent = styled.div`
    display: grid;
    grid-template-columns: max-content 1fr;
    column-gap: ${({theme}) => theme.spacing(3)};
    row-gap: ${({theme}) => theme.spacing(1)};
    align-items: end;
    overflow: hidden;
    word-break: break-all;
`
const SectionTitle = styled.span`
    font-size: 1.25rem;
`
const Divider = styled.div`
    border-bottom: 1px solid ${({theme}) => theme.colors.card.content.border};
`
export const SectionItemLabel = styled.span`
    font-weight: 600;
`
export const SectionItemValue = styled.span`

`

export const DetailsSection: FCC<{ title: string }> = ({title, children}) => {
    return <Wrapper>
        <SectionTitle>{title}</SectionTitle>
        <Divider/>
        <SectionContent>
            {children}
        </SectionContent>
    </Wrapper>
}