import { Button, Card, CardBody, CardFooter, FormattedDate, Icon, Trigger, apiIsOK } from "@dgs/core";
import { useQueryClient } from "@tanstack/react-query";
import React, { FC } from "react";
import { useTranslation } from "react-i18next";
import styled from "styled-components";
import { EmployeeShowDto } from "~root/api_gen";
import { VacationEntitlementUpdateDrawer } from "~root/employees/details/vacation_entitlement_update_drawer";
import { DetailsSection, SectionItemLabel, SectionItemValue } from "~root/employees/detailsV2/common/DetailsSection";
import { employeeKeys } from "~shared/api/employee/employeeKeys";
import { webserviceApi } from "~shared/api/webserviceApi";

const StyledCardFooter = styled(CardFooter)`
	justify-content: center;
`;
export const EmployeeAccountCalculationCard: FC<{ employee: EmployeeShowDto }> = ({ employee }) => {
	const { t } = useTranslation();
	const queryClient = useQueryClient();
	return (
		<Card>
			<CardBody>
				<DetailsSection title={t("Account calculation")}>
					<SectionItemLabel>{t("Hours per day")}:</SectionItemLabel>
					<SectionItemValue>{employee.hoursPerDay}</SectionItemValue>
					<SectionItemLabel>{t("Yearly vacation days")}:</SectionItemLabel>
					<SectionItemValue>{employee.yearlyVacationDays}</SectionItemValue>
					<SectionItemLabel>{t("Entry date")}:</SectionItemLabel>
					<SectionItemValue>
						<FormattedDate date={employee.entryDate} />
					</SectionItemValue>
					<SectionItemLabel>{t("Leave date")}:</SectionItemLabel>
					<SectionItemValue>
						<FormattedDate date={employee.leaveDate} />
					</SectionItemValue>
				</DetailsSection>
			</CardBody>
			<StyledCardFooter>
				<Trigger
					render={(props) => (
						<VacationEntitlementUpdateDrawer
							open={props.open}
							onClose={props.handleClose}
							heading={t("Edit vacation entitlement")}
							initialValues={employee}
							onClosed={() => {
								void queryClient.invalidateQueries({
									queryKey: employeeKeys.detail(employee.id),
								});
							}}
							onSubmit={async (values) => {
								const res = await webserviceApi.updateEmployeeVacationEntitlement(employee.id, values);
								return apiIsOK(res);
							}}
						/>
					)}
				>
					{(props) => (
						<Button {...props} prefix={<Icon icon={"edit"} />} color={"primary"}>
							{t("Edit")}
						</Button>
					)}
				</Trigger>
			</StyledCardFooter>
		</Card>
	);
};
