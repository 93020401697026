import React, {FC} from "react";
import {Route, Routes} from "react-router-dom";
import {EmployeeDetailsPageV2} from "~root/employees/detailsV2/EmployeeDetailsPage";
import {DefaultContentLayout, DetailsPageContentLayout} from "~shared/layout";
import {EmployeeList} from "./employee_list";

export const EmployeePage: FC = () => {
    return (
        <Routes>
            <Route
                index
                element={
                    <DefaultContentLayout>
                        <EmployeeList/>
                    </DefaultContentLayout>
                }
            />
            <Route
                path=":id/*"
                element={
                    <DetailsPageContentLayout>
                        <EmployeeDetailsPageV2/>
                    </DetailsPageContentLayout>
                }
            />
        </Routes>
    );
};
