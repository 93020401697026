import { BrandPage, SidebarLayout, ToastList } from "@dgs/core";
import React, { FC } from "react";
import { Navigate, Outlet, Route, Routes } from "react-router-dom";
import { ActivityPage } from "~root/activities/ActivityPage";
import { EmployeeDashboardPage } from "~root/EmployeeDashboard/EmployeeDashboardPage";
import { Header } from "~root/header";
import { OwnPayrollsList } from "~root/payrolls/OwnPayrollsList";
import { PayrollsList } from "~root/payrolls/PayrollsList";
import { ProjectPage } from "~root/projects/ProjectPage";
import { RoleListPage } from "~root/roles/RoleListPage";
import { Sidebar } from "~root/sidebar";
import { TimesList } from "~root/times/TimesList";
import { DefaultContentLayout } from "~shared/layout";
import { BranchList } from "./branches/BranchList";
import { EmployeePage } from "./employees/employee_page";
import { OffDaysPage } from "./off_days/off_days_page";
import { ContentProviders } from "./providers/content_providers";
import { TeamsPage } from "./teams/teams_page";
import { OwnTimesList } from "./times/OwnTimesList";
import { UserListPage } from "./users/user_list_page";
import { OwnVacationList } from "./vacations/OwnVacationList";
import { VacationList } from "./vacations/VacationList";

export const Content: FC = () => {
	return (
		<ContentProviders>
			<ToastList />
			<SidebarLayout
				header={<Header />}
				sideBar={<Sidebar />}
				content={
					<Routes>
						<Route
							element={
								<DefaultContentLayout>
									<Outlet />
								</DefaultContentLayout>
							}
						>
							<Route index element={<Navigate to="vacations" />} />
							<Route path="users-roles/*" element={<UsersRolesPage />} />
							<Route path="branches" element={<BranchList />} />
							<Route path="teams" element={<TeamsPage />} />
							<Route path="off-days" element={<OffDaysPage />} />
							<Route path="brand" element={<BrandPage frontendThemeName="dgs" />} />
							<Route path="times">
								<Route index element={<Navigate replace to="own" />} />
								<Route path="all" element={<TimesList />} />
								<Route path="own" element={<OwnTimesList />} />
							</Route>
							<Route path="vacations">
								<Route index element={<Navigate replace to="own" />} />
								<Route path="all" element={<VacationList />} />
								<Route path="own" element={<OwnVacationList />} />
							</Route>
							<Route path="payrolls">
								<Route index element={<Navigate replace to="own" />} />
								<Route path="all" element={<PayrollsList />} />
								<Route path="own" element={<OwnPayrollsList />} />
							</Route>
							{/*<Route path="profile" element={<ProfilePage/>}/>*/}
							<Route path="dashboard" element={<EmployeeDashboardPage />} />
						</Route>
						<Route path="employees/*" element={<EmployeePage />} />
						<Route path="projects/*" element={<ProjectPage />} />
						<Route path="activities/*" element={<ActivityPage />} />
					</Routes>
				}
			/>
		</ContentProviders>
	);
};

const UsersRolesPage = () => {
	return (
		<Routes>
			<Route path="users" element={<UserListPage />} />
			<Route path="roles" element={<RoleListPage />} />
			<Route path="*" element={<Navigate to="users" />} />
		</Routes>
	);
};
