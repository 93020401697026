import { CrudDrawer, EntityDrawerProps, TextField } from "@dgs/core";
import React, { FC, useCallback } from "react";
import { useTranslation } from "react-i18next";
import { BranchShowDto, CountryOptionDto } from "~root/api_gen";
import { CountrySelectField, StateSelectField } from "~root/country/country_select_field";

export type IBranchFormState = Omit<BranchShowDto, "id" | "country"> & {
	country: CountryOptionDto | null;
};

export const BranchDrawer: FC<EntityDrawerProps<IBranchFormState>> = (props) => {
	const { t } = useTranslation();

	const validate = useCallback(
		(values: IBranchFormState) => {
			const errors: { [K in keyof IBranchFormState]?: any } = {};

			if (!values.name) {
				errors.name = t("Field is required");
			}
			if (!values.street) {
				errors.street = t("Field is required");
			}
			if (!values.houseNumber) {
				errors.houseNumber = t("Field is required");
			}
			if (!values.zip) {
				errors.zip = t("Field is required");
			}
			if (!values.city) {
				errors.city = t("Field is required");
			}
			if (!values.country) {
				errors.country = t("Field is required");
			}
			return errors;
		},
		[t],
	);

	return (
		<CrudDrawer
			{...props}
			validate={validate}
			body={
				<>
					<TextField name="name" label={t("Name")} required />
					<TextField name="street" label={t("Street")} required />
					<TextField name="houseNumber" label={t("House number")} required />
					<TextField name="zip" label={t("Zip")} required />
					<TextField name="city" label={t("City")} required />
					<StateSelectField name="state" label={t("State")} />
					<CountrySelectField name="country" label={t("Country")} required />
				</>
			}
		/>
	);
};
