import { AutoDataList, FCC, IDataListColumn, IconButton, Id, Trigger, mapFetcherConfigToAxiosConfig } from "@dgs/core";
import React, { FC, useMemo } from "react";
import { useTranslation } from "react-i18next";
import styled from "styled-components";
import { AccountOptionDto, AccountTransactionDto, EmployeeShowDto } from "~root/api_gen";
import { AccountTransactionDrawer } from "~root/employees/details/account_transaction_drawer";
import { employeeKeys } from "~shared/api/employee/employeeKeys";
import { webserviceApi } from "~shared/api/webserviceApi";

const Wrapper = styled.div`
	display: grid;
	gap: ${({ theme }) => theme.spacing(4)};
`;

const Total = styled.span`
	font-size: 14px;
`;
export const EmployeeAccounts: FC<{ employee: EmployeeShowDto }> = ({ employee }) => {
	const { t } = useTranslation();

	return (
		<Wrapper>
			<TransactionList account={employee.vacationAccount} employeeId={employee.id}>
				<Total>{t("{{count}} days", { count: employee.vacationAccount.balance })}</Total>
			</TransactionList>
		</Wrapper>
	);
};

const TransactionList: FCC<{ account: AccountOptionDto; employeeId: Id }> = ({ account, employeeId, children }) => {
	const { t } = useTranslation();
	const dataListColumns: IDataListColumn<AccountTransactionDto>[] = useMemo(() => {
		return [
			{
				heading: t("Days"),
				valueKey: "value",
				type: "value",
			},
			{
				heading: t("Type"),
				valueKey: "type",
				type: "value",
			},
		];
	}, [t]);

	return (
		<AutoDataList
			heading={account.name}
			empty={t("No transactions available")}
			columns={dataListColumns}
			queryKey={employeeKeys.transactionList(employeeId, account.id)}
			fetcher={(config) => webserviceApi.getAccountTransactions(account.id, mapFetcherConfigToAxiosConfig(config))}
			headerChildren={children}
			headerActions={
				<>
					<Trigger
						render={(props) => <AccountTransactionDrawer {...props} employeeId={employeeId} accountId={account.id} />}
					>
						{(props) => (
							<IconButton
								{...props}
								type="button"
								color="primary"
								size="small"
								title={t("Create transaction")}
								icon="plus"
							/>
						)}
					</Trigger>
				</>
			}
		/>
	);
};
