import {
	ContextMenu,
	ContextMenuDeleteItem,
	ContextMenuItem,
	DrawerAutoDataList,
	FormattedDate,
	IDrawerDataListColumn,
	Icon,
	useCrudEntity,
} from "@dgs/core";
import React, { useMemo } from "react";
import { useTranslation } from "react-i18next";
import styled from "styled-components";
import { OffDayCreateDto, OffDayListDto, OffDayUpdateDto } from "~root/api_gen";
import { offDayService } from "~shared/api/off_day/off_day_service";
import { IOffDayFormState, OffDayDrawer } from "./off_day_drawer";

const CheckIcon = styled(Icon)`
	width: 24px;
	height: 24px;
`;

const mapToRequest = ({ branch, ...offDay }: IOffDayFormState): OffDayCreateDto | OffDayUpdateDto => {
	if (!branch) throw "Missing validation for field: branch";

	return {
		...offDay,
		branchId: branch.id,
	};
};

export const OffDaysPage = () => {
	const { t } = useTranslation();
	const crudOperations = useCrudEntity(offDayService, {
		entityName: t("Off day"),
		messages: {
			create: t("Off day was saved."),
			update: t("Off day was saved."),
			delete: t("Off day was deleted."),
		},
	});
	const emptyOffDay: IOffDayFormState = {
		date: "",
		branch: null,
		halfDay: false,
	};
	const dataListColumns: IDrawerDataListColumn<OffDayListDto>[] = useMemo(
		() => [
			{
				heading: t("Date"),
				valueKey: "date",
				type: "value",
				render: ({ date }) => <FormattedDate date={date} />,
			},
			{
				heading: t("Branch"),
				valueKey: "branch",
				render: ({ branch }) => branch.name,
				type: "value",
			},
			{
				heading: t("Half day"),
				valueKey: "halfDay",
				type: "value",
				render: ({ halfDay }) => (halfDay ? <CheckIcon icon="check" /> : ""),
			},
			{
				heading: "",
				type: "actions",
				size: "max-content",
				action: ({ id }, openDrawer) => (
					<ContextMenu displayShortcutsAs="icon">
						<ContextMenuItem
							title={t("Edit off day")}
							label={t("Edit off day")}
							action={openDrawer}
							icon="edit"
							shortcut="primary"
						/>
						<ContextMenuDeleteItem
							action={() => crudOperations.deleteEntity(id)}
							title={t("Delete off day")}
							label={t("Delete off day")}
							heading={t("Delete")}
							labels={{ close: t("Close"), confirm: t("Confirm") }}
						>
							{t("Are you sure you want to delete this off day?")}
						</ContextMenuDeleteItem>
					</ContextMenu>
				),
			},
		],
		[crudOperations, t],
	);

	return (
		<DrawerAutoDataList
			canCreate
			canUpdate
			columns={dataListColumns}
			service={offDayService}
			renderCreateDrawer={(drawerProps) => (
				<OffDayDrawer
					{...drawerProps}
					heading={t("Add off day")}
					initialValues={emptyOffDay}
					onSubmit={(entityFormState) => crudOperations.createEntity(mapToRequest(entityFormState))}
				/>
			)}
			renderUpdateDrawer={(entityResource, drawerProps) => (
				<OffDayDrawer
					{...drawerProps}
					heading={t("Edit off day")}
					initialValues={entityResource}
					onSubmit={(entityFormState) => crudOperations.updateEntity(entityResource.id, mapToRequest(entityFormState))}
				/>
			)}
			translations={{
				title: t("Off days"),
				empty: t("No off day available."),
			}}
		/>
	);
};
