import { FileInputControl } from "@dgs/core";
import { useField } from "formik";
import React, { ChangeEvent, FC, ReactNode, useCallback } from "react";

export interface FileInputFieldProps {
	prefix?: ReactNode;
	suffix?: ReactNode;
	label?: ReactNode;
	name: string;
	description?: string;
	disabled?: boolean;
	autoFocus?: boolean;
	accept?: string;
	required?: boolean;
	multiple?: boolean;
}

const divideProps = ({
	autoFocus = false,
	disabled = false,
	label,
	name,
	description,
	prefix,
	suffix,
	accept,
	required,
	multiple,
}: FileInputFieldProps) => {
	return {
		controlProps: {
			label,
			description,
			disabled,
			autoFocus,
			prefix,
			suffix,
			accept,
			required,
			multiple,
		},
		useFieldProps: {
			name,
		},
	};
};

export const FileInputField: FC<FileInputFieldProps> = (props) => {
	const { controlProps, useFieldProps } = divideProps(props);
	const [field, meta, helper] = useField(useFieldProps);

	const handleInput = useCallback(
		async (event: ChangeEvent<HTMLInputElement>) => {
			if (event.target.files) {
				helper.setValue(event.target.files);
				helper.setTouched(true, false);
			}
		},
		[helper],
	);

	return (
		<FileInputControl
			{...controlProps}
			onChange={field.onChange}
			checked={field.checked}
			onBlur={field.onBlur}
			name={field.name}
			error={meta.touched && meta.error ? meta.error : undefined}
			handleInput={handleInput}
		/>
	);
};
