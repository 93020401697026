import { CrudDrawer, Id, SingleSelectField, TextField, apiIsOK, useToasts } from "@dgs/core";
import { useQueryClient } from "@tanstack/react-query";
import React, { FC, useCallback } from "react";
import { useTranslation } from "react-i18next";
import { TransactionType as TransactionTypes } from "~root/api_gen";
import { employeeKeys } from "~shared/api/employee/employeeKeys";
import { webserviceApi } from "~shared/api/webserviceApi";
import { ITransactionFormState } from "~shared/types/transaction";

const emptyTransaction: ITransactionFormState = {
	value: 0,
	type: "specialvacation",
	calculation: "add",
};

interface Props {
	accountId: Id;
	employeeId: Id;
	open: boolean;
	handleClose: () => void;
}

export const AccountTransactionDrawer: FC<Props> = ({ open, handleClose, accountId, employeeId }) => {
	const { t } = useTranslation();
	const queryClient = useQueryClient();
	const { showToast } = useToasts();

	const onSubmit = useCallback(
		async (formState: ITransactionFormState) => {
			const res =
				formState.calculation === "add"
					? await webserviceApi.addAccountTransaction(+accountId, formState)
					: await webserviceApi.subtractAccountTransaction(+accountId, formState);

			if (apiIsOK(res)) {
				showToast({
					type: "success",
					title: t("Transactions"),
					body: t("Transaction was saved."),
				});
				await queryClient.invalidateQueries({
					queryKey: employeeKeys.detail(employeeId),
				});
				return true;
			}
			return false;
		},
		[accountId, employeeId, queryClient, showToast, t],
	);

	const validate = useCallback(
		(values: ITransactionFormState) => {
			const errors: { [K in keyof ITransactionFormState]?: any } = {};

			if (!values.calculation) {
				errors.calculation = t("Field is required");
			}
			if (!values.value || values.value < 0) {
				errors.value = t("Value must be positive");
			}
			if (!values.type) {
				errors.type = t("Field is required");
			}

			return errors;
		},
		[t],
	);

	return (
		<CrudDrawer
			heading={t("Create transaction")}
			open={open}
			initialValues={emptyTransaction}
			onSubmit={onSubmit}
			onClose={handleClose}
			validate={validate}
			body={
				<>
					<SingleSelectField options={["add", "subtract"]} label={t("Calculation")} name="calculation" getLabel={t} />
					<TextField name="value" type="number" label={t("Value")} />
					<SingleSelectField
						options={Object.keys(TransactionTypes) as string[]}
						label={t("Transaction type")}
						name="type"
						getLabel={(x) => x}
					/>
				</>
			}
		/>
	);
};
