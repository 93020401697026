import styled from "styled-components";

export const ScrollContainer = styled.div`
	max-height: 100%;
`;

export const ContentContainer = styled.div`
	flex: 1;
	overflow: auto;
	max-height: 100%;
	padding: 1.5rem;
`;

export const FullScreenContainer = styled.div`
	flex: 1;
	overflow: auto;
	max-height: 100%;
	height: 100%;
	//background-color: #fff;
`;
