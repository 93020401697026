import { PagedSingleSelectField } from "@dgs/core";
import React, { FC } from "react";
import { branchService } from "~shared/api/branch/branch_service";

const fetcher = (search: string, page: number) => branchService.options(search, page).then((x) => x.data);

interface Props {
	name: string;
	label: string;
	required?: boolean;
}
export const BranchSelectField: FC<Props> = (props) => {
	return <PagedSingleSelectField {...props} fetcher={fetcher} queryKey={branchService.optionsKey} />;
};
